import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import {  AdMob, BannerAdOptions, BannerAdSize, BannerAdPosition, BannerAdPluginEvents, AdMobBannerSize } from '@capacitor-community/admob';
import { useEffect } from 'react';
import {languageActions } from '../../_redux/actions';
import { useDispatch } from 'react-redux';
import { Line, Circle } from 'rc-progress';
import linq from 'linq';
import {stockData} from '../../data/stockData'



const Test: React.FC = ()  => {
  const dispatch = useDispatch();
  useEffect(() => { 
      dispatch(languageActions.getLanguage());
  },[]);

  
  AdMob.initialize({
    requestTrackingAuthorization: true,
    testingDevices: ['2077ef9a63d2b398840261c8221a0c9b'],
    initializeForTesting: true,
  });

  AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
    // Subscribe Banner Event Listener
  });

  AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size: AdMobBannerSize) => {
    // Subscribe Change Banner Size
  });

  const options: BannerAdOptions = {
    adId: 'ca-app-pub-3940256099942544/6300978111',
    adSize: BannerAdSize.BANNER,
    position: BannerAdPosition.BOTTOM_CENTER,
    margin: 0,
    // isTesting: true
    // npa: true
  };
  AdMob.showBanner(options);





  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Blank</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Blank</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Line percent={34}  strokeWidth={5} strokeColor={"blue"} />
        <Line percent={5} strokeWidth={10} strokeLinecap="round" strokeColor={"blue"} />
        <Circle percent={20} strokeWidth={20} strokeLinecap="round" strokeColor={"red"} />
        
        <div className="stock-container">

     
        {stockData.Ciudad.map((data, key) => {
          return (
            
            <div key={key}>
              {data.N +
                " , " +
                data.W}
            </div>
          );
        })}
      </div>



      </IonContent>
    </IonPage>
  );
};

export default Test;
