import React from "react";
import PropTypes from "prop-types";
import { IonButton, IonIcon } from '@ionic/react';
import { arrowBackCircleOutline, arrowForwardCircleOutline } from "ionicons/icons";
import { ArrowBackFabButtonProps } from '../../../../../Libs/ui-ionic-kit/src/components_v0/atoms/Fab/ArrowBackFabButton/ArrowBackFabButton';
import { useTranslation } from "react-i18next";

interface Props {
  currentPage: number;
  totalPages: number;
  handleNextPage: (page: number) => void;
  handlePrevPage: (page: number) => void;
}
const Pagination: React.FC<Props> = ({
  currentPage,
  totalPages,
  handlePrevPage,
  handleNextPage
}) => {
    const { t } = useTranslation();
  return (
    <div className="pagination-button-wrapper">
      <IonButton
       color="primary"
       fill="outline"
        className="pagination-button"
        onClick={() => handlePrevPage(currentPage)}
        disabled={currentPage === 1}
      >
         {t("previous")}
        <IonIcon slot="start" icon={arrowBackCircleOutline} />
      </IonButton>

      <span className="pagination-page-info">
      {t("page")}  {currentPage}   {t("of")}  {totalPages}
      </span>

      <IonButton
       color="primary"
       fill="outline"
        className="pagination-button"
        onClick={() => handleNextPage(currentPage)}
        disabled={currentPage === totalPages}
      >
         {t("next")}<IonIcon slot="start" icon={arrowForwardCircleOutline} /></IonButton>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handlePrevPage: PropTypes.func.isRequired,
  handleNextPage: PropTypes.func.isRequired
};

export default Pagination;
