export enum Producto {
    'MAS_TIEMPO',
    'AYUDA_50',
    'Ayuda_Contestar_2Veces',
    'Ayuda_Pregunta_Correcta',
    'Recuperar_Puntos_Perdido',
    'Recuperar_Racha',
    'Recuperar_Vida',
    'Moneda_Publicidad',
    'Cafe',
    'QUITAR_PUBLICIDAD',
    'COMPRAR_VIDA',
    'VIDAS_5_GRATIS'
  }