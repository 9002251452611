import { AppAction, AppState } from "../models/app.model";
import { appTypes } from "../types";
import BannerLocal from '../../components/Ads/BannerLocal';
import BannerModalLocal from '../../components/Ads/BannerModalLocal';

const initialState: AppState = {
  theme: "",
  localSettings: "en",
  cookiePreferences: true,
  isPrepareBanner: false,
  isPrepareReward: false,
  isPrepareInterstitial: false,
  isPrepareInterstitialPublicidad: false,
  bannerLocal: false,
  yaMostroValorar: false,
  toast: undefined,
  bannerModalLocal: false,
  logro: -1,
  banner1: [],
  banner2: [],
  banner3: [],
  banner4: [],

};

export const appReducer = (
  state: AppState = initialState,
  action: AppAction
): any => {
  switch (action.type) {
    case appTypes.REQUEST:
      return {
        ...state,
        ...{ error: null, isLoading: true },
      };
    case appTypes.TOAST:
      if (action.toast?.duration === null) {
        action.toast.duration = 10;
      }
      return {
        ...state,
        toast: action.toast,
      };
    case appTypes.REMOVE_TOAST:
      return {
        ...state,
        toast: undefined,
      };
    case appTypes.SET_THEME:
      return {
        ...state,
        ...{ theme: action.theme },
      };
    case appTypes.BANNER_MOSTRAR:
      return {
        ...state,
        ...{ isPrepareBanner: action.isPrepareBanner },
      };
    case appTypes.BANNER_OCULTAR:
      return {
        ...state,
        ...{ isPrepareBanner: false },
      };
    case appTypes.BANNER_MOSTRAR_INTERTINAL_PUBLICIDAD:
      return {
        ...state,
        ...{
          isPrepareInterstitialPublicidad:
            action.isPrepareInterstitialPublicidad, bannerModalLocal: true
        },
      };
    case appTypes.BANNER_OCULTAR_INTERTINAL_PUBLICIDAD:
      return {
        ...state,
        ...{
          isPrepareInterstitialPublicidad:
            action.isPrepareInterstitialPublicidad, bannerModalLocal: false
        },
      };

    case appTypes.BANNER_LOCAL:
      return {
        ...state,
        ...{ bannerLocal: action.bannerLocal },
      };

    case appTypes.MOSTRAR_EVALUAR_APP:
      return {
        ...state,
        ...{ yaMostroValorar: true },
      };

    case appTypes.BANNER_LOCAL_MOSTRAR:
      return {
        ...state,
        ...{ bannerLocal: true },
      };

    case appTypes.BANNER_LOCAL_OCULTAR:
      return {
        ...state,
        ...{ bannerLocal: false },
      };

      case appTypes.LOGRO_MOSTRAR:
        return {
          ...state,
          ...{ logro: action.logro },
        };

        case appTypes.LOGRO_OCULTAR:
          return {
            ...state,
            ...{ logro: action.logro },
          };


    case appTypes.FAILURE_REQUEST:
      return {
        ...state,
        ...{ isLoading: false, error: action.error },
      };


        case appTypes.BANNER1_SAVE:
          return {
            ...state,
            ...{ banner1: action.banner1 },
          };

          case appTypes.BANNER2_SAVE:
            return {
              ...state,
              ...{ banner2: action.banner2 },
            };

            case appTypes.BANNER3_SAVE:
              return {
                ...state,
                ...{ banner3: action.banner3 },
              };

              case appTypes.BANNER4_SAVE:
                return {
                  ...state,
                  ...{ banner4: action.banner4 },
                };


    default:
      return state;
  }
};
