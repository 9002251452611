export enum gameTypesEnum {
    LOAD_QUESTION = 'QUESTION_LOAD_GAME',
    NEXT_QUESTION = 'QUESTION_NEXT_GAME',
    REQUEST = 'REQUEST_GAME',
    ERROR = 'ERROR_GAME',
    PROVINCE = 'PROVINCE_GAME_LOADER',
    CITIES_OTHER = 'CITIES_OTHER',
    START_GAME = "START_GAME",
    LOAD_IMAGE = 'LOAD_IMAGE',
    TIME_GAME_RUN = "TIME_GAME_RUN",
    FINE_QUESTION = "FINE_QUESTION",
    BAD_QUESTION = "BAD_QUESTION",
    GET_RANKING = "GET_RANKING",
    SET_LOCAL_RANKING = "SET_LOCAL_RANKING",
    LIST_CITY = "LIST_CITY",
    PAUSAR_JUEGO = "PAUSAR_JUEGO",
    QUITAR_ISBusy = "QUITAR_LOADER",
    IS_BUSY = 'IS_BUSY',
    RESET = 'RESET'

  }