import React, { useState, useEffect } from "react";
import { AppState, AppStore } from "../../_redux/models/app.model";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../_redux/actions/app.actions";
import { Ads } from "../../_redux/models/ads.model";

const BannerLocal240: React.FC = () => {
  const [currentAd, setCurrentAd] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const appConfig: AppState = useSelector((store: AppStore) => store.app);
  const dispatch = useDispatch();
  const inicioBanner: Ads[] = [
    {
      title: "",
      text: "",
      image: "https://www.pekegames.app/Img/App/Banner/3/250_250_pekegames.jpg",
      url: "https://www.buymeacoffee.com/pekegames",
    },
    {
      title: "A little coffee",
      text: "It is a way of collaborating with us. Your help is very important.",
      image: "",
      url: "https://www.buymeacoffee.com/pekegames",
    },
    {
      title: "",
      text: "",
      image: "https://www.pekegames.app/Img/App/Banner/240_brasi_lenceria.png",
      url: "https://brasi.eu",
    },
    {
      title: "Lencería",
      text: "¡Descubre las mejores ofertas en lencería de las marcas más destacadas!",
      image: "",
      url: "https://brasi.eu",
    }
  ];
  const [ads, setAds] = useState(inicioBanner);

  useEffect(() => {
    setIsMounted(true);
    const fetchData = async () => {
      try {
        console.log("banner", appConfig);
        // Llamada al servicio para obtener los datos de ads
        if (appConfig.banner2.length > 0) {
          console.log("banner2 entro",appConfig.banner2);
          setAds(appConfig.banner2);
          console.log("banner2 entro ads es",ads);
          setIsLoading(false);
          return;
        }
        const response = await fetch(
          process.env.REACT_APP_SERVICE +
            "/api/pekegames/banner?formatoId=4&appId=" +
            process.env.REACT_APP_MIAPPID +
            "&language=" +
            localStorage.getItem("i18nextLng")
        );

        const data = await response.json();
        if (data.length > 0) {
          setAds(data);
          dispatch(appActions.setBanner2(data));
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error al obtener los datos de ads:", error);
        setIsLoading(false);
      }
    };

    fetchData(); return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  useEffect(() => {
    if (!isLoading) {
      const interval = setInterval(() => {
        if (isMounted) {
        setCurrentAd((prevAd) => (prevAd + 1) % ads.length);
        }
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [ads, isLoading,isMounted]);

  const abrirBannerWeb = async (item: any) => {
    if (isMounted && item.url.length > 1) {
      window.open(item.url, "_blank");
    }
  };

  if (!isMounted) {
    return null;
  }

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="bannerlocal240" onClick={() => abrirBannerWeb(ads[currentAd])}>
      {ads[currentAd].image.length > 1 ? (
        <img src={ads[currentAd].image} alt={ads[currentAd].title} />
      ) : (
        <>
          {ads[currentAd].title.length > 1 && <h1>{ads[currentAd].title}</h1>}
          {ads[currentAd].text}
        </>
      )}
    </div>
  );
};

export default BannerLocal240;
