import { gameActions, partidaActions } from "../_redux/actions";
import { GameState, RankingOnlineRequest } from "../_redux/models/game.model";
import { PartidaState } from "../_redux/models/partida.model";
import { filtrarMeuDatos, OperacionSaveR } from "./functions";
import { history } from "../_helpers/history";
import { AuthState } from "../_redux/models/auth.model";
import { gameService } from "../_redux/services";
import { Dispatch } from "redux";


export const gestionarGuardarPartida = async (
  partida: PartidaState,
  eljuego: GameState,
  authUser: AuthState,
  finalizar: boolean,
  dispatch : Dispatch<any>
) => {
  try {
    console.log("dispatch",dispatch);
    console.log("partida", partida);
    console.log("eljuego", eljuego);

    dispatch(gameActions.IsBusy());
    dispatch(gameActions.PARAR_JUEGO());
    partida.numberOfQuestions = eljuego.numberOfQuestions;
    await posicionRanking(partida,dispatch);
    partida.finalizarJuego = finalizar;
    if (authUser.isAuth && finalizar) {
      //Si esta logueado y finaliza el juego
      dataRanking.Finalizado = true;
      partida.finalizarJuego = true;
      dataRanking.Punto = parseInt(partida.point.toFixed(0));
      dataRanking = OperacionSaveR(dataRanking, partida, authUser);
      const guardarRanking = await gameService.SetRanking(dataRanking);
      partida.id = guardarRanking;
      //sonidoGameOver();
      history.replace("gameOver");
    } else if (finalizar) {
      partida.finalizarJuego = true;
      GuardarPartida(partida);
      //el usuario no esta logado, tenemos que guardar la partida en la modal si el mismo crea la cuenta
      //sonidoGameOver();
      history.replace("gameOver");
    } else if (authUser.isAuth && !finalizar) {
      //Guardamos la partida para luego, solo si el usuario está autentificado
      dataRanking.Finalizado = false;
      partida.finalizarJuego = false;
      dataRanking.Punto = parseInt(partida.point.toFixed(0));
      dataRanking = OperacionSaveR(dataRanking, partida, authUser);
      const guardarRanking = await gameService.SetRanking(dataRanking);
      partida.id = guardarRanking;
      GuardarPartida(partida);
      history.replace("dashboard");
    } else {
      //Guardamos la partida de invitado para luego
      partida.finalizarJuego = false;
      GuardarPartida(partida);
      history.replace("dashboard");
    }
    dispatch(gameActions.QuitarIsBusy());
  } catch (error) {
    dispatch(gameActions.QuitarIsBusy());
  }
};

const posicionRanking = async (partida: PartidaState,dispatch : Dispatch<any>) => {
  try {
    dataRanking.Punto = parseInt(partida.point.toFixed(0));
    const positicionActual = await gameService.PosicionRanking(dataRanking);
    if (positicionActual != null) {
      dispatch(partidaActions.actualizar_posicion(positicionActual));
    }
  } catch (error) {}
};

const GuardarPartida = (partida: PartidaState) => {
  console.log("guardamos la partida con puntos?", partida);
  localStorage.setItem("jogo", filtrarMeuDatos(JSON.stringify(partida)));
};

let dataRanking: RankingOnlineRequest = {
  MiAppId: 0,
  TipoRanking: 0,
  Finalizado: true,
};


