import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import animationData from "../../assets/images/cargandolotties.json";
 
class LoaderLottie extends Component {
  state = { isStopped: false, isPaused: false, isMounted: false };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }
 
  render() {
    const { isMounted } = this.state;
    const buttonStyle = {
      display: "inline-block",
      margin: "10px auto",
      marginRight: "10px",
      border: "none",
      color: "white",
      backgroundColor: "#647DFF",
      borderRadius: "2px",
      fontSize: "15px",
    };

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="controlled">
         {isMounted && ( <Lottie options={defaultOptions} height={300} width={300} /> )}
        <h1 className="cargando_titulo">  
        Loading...
        </h1>
      </div>
    );
  }
}

export default LoaderLottie;
