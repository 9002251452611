import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewWillLeave,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { AuthStore, AuthAction } from "../../_redux/models/auth.model";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { gameService } from "../../_redux/services";
import ReactHtmlParser from "react-html-parser";
import ReadMoreReact from "read-more-react";
import {
  apps,
  bus,
  chevronBack,
  closeCircle,
  ellipsisHorizontal,
  ellipsisVertical,
  images,
  information,
  map,
  settings,
  star,
} from "ionicons/icons";
import { history } from "../../_helpers/history";
import MenuLateral from "../../components/Menu/MenuLateral";
import { GameState } from "../../_redux/models/game.model";
import { TiempoResponse } from "../../_redux/models/tiempo.model";
import TuTiempo from "../../components/TuTiempo/TuTiempo";
import styles from "./cityDetail.module.scss";
import Star from "../../components/Star/Star";
import { Result } from "../../_redux/models/place.model";
import { PlaceReviewResponse } from "../../_redux/models/reviewplace.model";
import { MenuApp } from "../../utils/functions";
//import { RateApp } from "capacitor-rate-app";
import { Capacitor } from "@capacitor/core";
import { gameActions } from "../../_redux/actions";
import Enumerable from "linq";
import { appActions } from "../../_redux/actions/app.actions";
import { LogroState } from "../../_redux/models/logro.models";
const CityDetail: React.FC = () => {
  const state = history.location.state as any;
  let buscar = "";
  try {
    if (state?.item.N == null) {
      buscar = decodeURI(window.location.href)
        ?.split("/")[4]
        ?.replaceAll("_", " ");
    } else {
      buscar = state.item.N;
    }
  } catch (error) {
    console.log(error);
  }

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const myUser = useSelector((store: AuthStore) => store.auth.user);
  const logros: LogroState = useSelector((state: any) => state.logro);
  const game: GameState = useSelector((state: any) => state.game);
  const [showModal, setShowModal] = useState(false);
  const [showWikiModal, setShowWikiModal] = useState(false);

  const [showModalRestaurante, setShowModalRestaurante] = useState(false);
  const [showModalHotel, setShowModalHotel] = useState(false);
  const [showModalTurismo, setShowModalTurismo] = useState(false);
  const [dataTurismoYelp, setDataTurismoYelp] = useState(null);
  const [dataHotelYelp, setDataHotelYelp] = useState(null);
  const [dataRestauranteYelp, seDataRestauranteYelp] = useState(null);
  const [descripcionCiudad, setDescripcionCiudad] =
    useState<string>(" loading...");
  const [itemSelecionado, SetItemSelecionado] = useState<Result>();
  const [review, setReview] = useState<PlaceReviewResponse>();

  const [present, dismiss] = useIonActionSheet();

  const [urlModal, setUrlModal] = useState("");

  useEffect(() => {
    initService();
  }, []);

  useIonViewWillLeave(() => {
    try {
      let d1 = new Date();

      if (localStorage.getItem("RateApp") == null) {
        if (Capacitor.isNativePlatform()) {
          //RateApp.requestReview();
          //localStorage.setItem("RateApp", d1.getTime().toString());
        }
      }
    } catch (error) {
      alert("error RateApp");
    }
  });
  const initService = async () => {
    try {
      dispatch(gameActions.IsBusy());
      getTextoSobreLaCiudad(buscar);
      getMyTurismo(buscar);
      getMyHotel(buscar);
      getMyRestaurante(buscar);
      //logScreenView(buscar + " detalle de la ciudad");
      //setScreen(buscar + " detalle de la ciudad");

      /*
      const auxHDataYelp = await gameService.GetHotel(buscar);
      if (auxHDataYelp !== null && auxHDataYelp.businesses !== null) {
        setDataHotelYelp(auxHDataYelp);
        setShowModalHotel(true);
      }
      const auxTDataYelp = await gameService.GetPuntosTuristicos(buscar);
      if (auxTDataYelp !== null && auxTDataYelp.businesses !== null) {
        setDataTurismoYelp(auxTDataYelp);
        setShowModalTurismo(true);
      }

      const auxRDataYelp = await gameService.GetRestaurante(buscar);
      if (auxRDataYelp !== null && auxRDataYelp.businesses !== null) {
        seDataRestauranteYelp(auxRDataYelp);
        setShowModalRestaurante(true);
      }

      const auxWikiBusca = await gameService.GetWikiBusca(buscar);
      if (auxWikiBusca.query.searchinfo.totalhits > 0) {
        setDescripcionCiudad(auxWikiBusca.query.search[0].snippet);
      }

   
      pintaTiempo(auxtiempo);
      */

      if (
        !logros?.logro?.find((x) => x.LogroId === 23)?.TieneLogro
      ) {
        dispatch(appActions.showLogro(23));
      }
    } catch (error) {
      dispatch(gameActions.QuitarIsBusy());
    }
  };

  const BotonVolver = () => {
    history.goBack();
  };

  const getTextoSobreLaCiudad = async (buscar: any) => {
    try {
      if (game != null || game.provinceCities !== undefined) {
        var questionGPT = Enumerable.from(game.provinceCities)
          .where((p) => p.N === buscar && p.T === "gpt")
          .firstOrDefault();
        console.log("gpt", questionGPT);
        if (
          questionGPT !== null &&
          questionGPT !== undefined &&
          questionGPT.Z.length > 10
        ) {
          setDescripcionCiudad(ReactHtmlParser(questionGPT?.Z));
          return;
        }
      }
      const auxWikiBusca = await gameService.GetWikiBusca(buscar);
      if (auxWikiBusca.query.searchinfo.totalhits > 0) {
        const textocompleto = await gameService.GetWikiDetalle(
          auxWikiBusca.query.search[0].pageid
        );
        setDescripcionCiudad(
          ReactHtmlParser(
            textocompleto.query.pages[auxWikiBusca.query.search[0].pageid]
              .extract
          )
        );
      }
    } catch (error) {
      console.error(error);
      setDescripcionCiudad("");
    }
  };

  const getMyTurismo = async (buscar: any) => {
    try {
      const dataServicio = await gameService.GetLugares(buscar, t("tourism"));
      if (dataServicio !== null && dataServicio.results !== null) {
        setDataTurismoYelp(dataServicio.results);
        setShowModalTurismo(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMyHotel = async (buscar: any) => {
    try {
      const dataServicio = await gameService.GetLugares(buscar, t("hotel"));
      if (dataServicio !== null && dataServicio.results !== null) {
        setDataHotelYelp(dataServicio.results);
        setShowModalHotel(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMyRestaurante = async (buscar: any) => {
    try {
      const dataServicio = await gameService.GetLugares(
        buscar,
        t("restaurants")
      );
      if (dataServicio !== null && dataServicio.results !== null) {
        seDataRestauranteYelp(dataServicio.results);
        setShowModalRestaurante(true);
        dispatch(gameActions.QuitarIsBusy());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const abrirModal = async (data: any) => {
    if (data == null) {
      let wiki = state?.item?.W;
      wiki.replace("http://", "https://");
      setUrlModal(wiki);
    } else {
      data.W = data.W.replace("http://", "https://");
      setUrlModal(data.W);
    }

    setShowWikiModal(true);
  };

  const abrirModalInfo = async (data: Result) => {
    SetItemSelecionado(data);
    const dataServicio = await gameService.GetLugaresReview(data.place_id);
    if (dataServicio != null) {
      setReview(dataServicio);
      console.log(dataServicio);
    }
    setShowModal(true);
  };

  return (
    <IonPage id="page-dashboard" className="page-dashboard">
      <IonHeader>
        <IonToolbar className="menu_movil_home_2">
          <IonTitle>{buscar}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="cities" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="bg_app_content">
        <IonRow>
          <IonCol size="12" size-md="3" className="lateral hide_pc">
            <MenuLateral></MenuLateral>
          </IonCol>
          <IonCol size="12" size-md="9" className="centro_pagina_photo">
            <div className="opacidad_fondo_sin_estacios">
              <h2>
                <div id="WidgetTutiempo"></div>
              </h2>
              {descripcionCiudad}
              
              {/* {descripcionCiudad && (
                <ReadMoreReact
                  key={descripcionCiudad.toString()}
                  text={descripcionCiudad.toString()}
                  min={80}
                  ideal={200}
                  max={20000}
                  readMoreText={t("more_text")}
                />
              )} */}
              
              <div className={styles.ContenedorWikipedia}>
                <IonButton
                  color="success"
                  shape="round"
                  size="large"
                  className={styles.botonContinuar}
                  onClick={() => abrirModal(state?.item)}
                >
                  <IonIcon slot="start" icon={information} />
                  {t("more_info")}
                </IonButton>
              </div>

              <div className={showModalRestaurante ? "show" : "hide"}>
                <hr className={styles.separador}></hr>
                <h1 className={styles.titulo}>{t("tourist_points")}</h1>

                <div className="horizontal-scroll-wrapper squares">
                  {Array.isArray(dataTurismoYelp) &&
                    dataTurismoYelp.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={styles.card_city}
                          onClick={() => abrirModalInfo(item)}
                        >
                          <div>
                            <h3>{item.name}</h3>
                            <Star
                              rating={item.rating}
                              cantidad={item.user_ratings_total}
                              key={index}
                            ></Star>
                          </div>
                          <IonCard
                            className="ionCard cardContent squares"
                            key={index}
                          >
                            <img
                              className={styles.pointImg}
                              referrerPolicy="no-referrer"
                              alt={
                                item?.photos === null
                                  ? "No imagen"
                                  : item?.photos[0]?.html_attributions
                              }
                              src={
                                item?.photos == null
                                  ? "/assets/images/noimage.jpg"
                                  : process.env.REACT_APP_SERVICE +
                                    "/api/lugares/img?codigo=" +
                                    item?.photos[0]?.photo_reference
                              }
                            ></img>
                          </IonCard>
                        </div>
                      );
                    })}
                </div>

                <hr className={styles.separador}></hr>
              </div>

              <div className={showModalRestaurante ? "show" : "hide"}>
                <h1 className={styles.titulo}>{t("restaurants")}</h1>
                <div className="horizontal-scroll-wrapper squares">
                  {Array.isArray(dataRestauranteYelp) &&
                    dataRestauranteYelp.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={styles.card_city}
                          onClick={() => abrirModalInfo(item)}
                        >
                          <div>
                            <h3>{item.name}</h3>
                            <Star
                              rating={item.rating}
                              cantidad={item.user_ratings_total}
                              key={index}
                            ></Star>
                          </div>
                          <IonCard
                            className="ionCard cardContent squares"
                            key={index}
                          >
                            <img
                              className={styles.pointImg}
                              alt={
                                item?.photos === null
                                  ? "No imagen"
                                  : item?.photos[0]?.html_attributions
                              }
                              src={
                                item?.photos == null
                                  ? "/assets/images/noimage.jpg"
                                  : process.env.REACT_APP_SERVICE +
                                    "/api/lugares/img?codigo=" +
                                    item?.photos[0]?.photo_reference
                              }
                            ></img>
                          </IonCard>
                        </div>
                      );
                    })}
                </div>
                <hr className={styles.separador}></hr>
              </div>

              <div className={showModalHotel ? "show" : "hide"}>
                <h1 className={styles.titulo}>{t("hotel")}</h1>
                <div className="horizontal-scroll-wrapper squares">
                  {Array.isArray(dataHotelYelp) &&
                    dataHotelYelp.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={styles.card_city}
                          onClick={() => abrirModalInfo(item)}
                        >
                          <div>
                            <h3>{item.name}</h3>
                            <Star
                              rating={item.rating}
                              cantidad={item.user_ratings_total}
                              key={index}
                            ></Star>
                          </div>
                          <IonCard
                            className="ionCard cardContent squares"
                            key={index}
                          >
                            <img
                              className={styles.pointImg}
                              alt={
                                item?.photos === null
                                  ? "No imagen"
                                  : item?.photos[0]?.html_attributions
                              }
                              src={
                                item?.photos == null
                                  ? "/assets/images/noimage.jpg"
                                  : process.env.REACT_APP_SERVICE +
                                    "/api/lugares/img?codigo=" +
                                    item?.photos[0]?.photo_reference
                              }
                            ></img>
                          </IonCard>
                        </div>
                      );
                    })}
                </div>
              </div>
              <TuTiempo ciudad={buscar}></TuTiempo>
            </div>
          </IonCol>
        </IonRow>

        <IonModal
          isOpen={showModal}
          backdropDismiss={true}
          onDidDismiss={() => setShowModal(false)}
          cssClass="fullscreen"
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle>
                {" "}
                {itemSelecionado?.name} - {itemSelecionado?.rating}{" "}
                <IonIcon icon={star}></IonIcon> (
                {itemSelecionado?.user_ratings_total})
              </IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <div className="contenido_scroll">
              <IonGrid>
                <IonRow>
                  <IonCol sizeLg="6" sizeXs="12" sizeSm="12">
                    <img
                      alt="Empresa"
                      className={styles.imagen_modal}
                      src={
                        itemSelecionado?.photos == null
                          ? "/assets/images/noimage.jpg"
                          : process.env.REACT_APP_SERVICE +
                            "/api/lugares/img?codigo=" +
                            itemSelecionado?.photos[0]?.photo_reference
                      }
                    ></img>
                    <IonRow>
                      <IonCol>
                        <a
                          target="_blank"
                          href={
                            "https://www.google.es/maps/place/" +
                            itemSelecionado?.formatted_address
                          }
                          rel="noreferrer"
                        >
                          {itemSelecionado?.formatted_address}
                        </a>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  <IonCol>
                    {Array.isArray(review?.result.reviews) &&
                      review?.result?.reviews.map((itemReview, index) => {
                        return (
                          <IonCard key={index}>
                            <IonCardHeader>
                              <IonCardTitle>
                                {itemReview.author_name}
                              </IonCardTitle>
                              <IonCardSubtitle>
                                <Star
                                  key={index}
                                  rating={itemReview.rating}
                                ></Star>
                                {itemReview.relative_time_description}
                              </IonCardSubtitle>
                            </IonCardHeader>

                            <IonCardContent
                              className={styles.contenedor_comentario}
                            >
                              <IonAvatar className={styles.avatar_contenedor}>
                                <img
                                  className={styles.avatar}
                                  alt={itemReview.author_name}
                                  src={itemReview.profile_photo_url}
                                ></img>
                              </IonAvatar>
                              {itemReview.text}
                            </IonCardContent>
                          </IonCard>
                        );
                      })}
                  </IonCol>{" "}
                </IonRow>
              </IonGrid>
            </div>
          </IonContent>
        </IonModal>

        <IonModal
          isOpen={showWikiModal}
          backdropDismiss={true}
          onDidDismiss={() => setShowWikiModal(false)}
          cssClass="fullscreen"
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowWikiModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle>Wikipedia</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <iframe
              id="mymodal"
              src={urlModal}
              className={styles.iframeStyle}
            ></iframe>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default CityDetail;
