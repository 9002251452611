import { Share } from "@capacitor/share";
import {
  IonModal,
  IonContent,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonCol,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from "@ionic/react";
import { text, closeOutline, closeCircle } from "ionicons/icons";
import Enumerable from "linq";
import React, { Component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { configData } from "../../data/configData";
import { authActions, logroActions } from "../../_redux/actions";
import { LogroState, LogrosUsuario } from "../../_redux/models/logro.models";
import { gameService } from "../../_redux/services";
export const GanaLogro: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const logros: LogroState = useSelector((state: any) => state.logro);
  const { t } = useTranslation();
  const [miLogro, setMiLogro] = useState<LogrosUsuario>(null);
 let logroAnterior = -1;
  useEffect(() => {
    let yatengoEsteLogro = true;
  
    if(props?.logroId===-1){
      return;
    }
    if(props?.logroId===logroAnterior){
      return;
    }
    logroAnterior = props?.logroId;
    logros.logro.forEach((logro) => {
      logro.Description = t(logro.Description);
      logro.DescriptionGanoLogro = t(logro.DescriptionGanoLogro);
      logro.Name = t(logro.Name);
      if(props?.logroId === logro.LogroId){
        yatengoEsteLogro = logro.TieneLogro;
      }
      if (props?.logroId !== null && props?.logroId === logro.LogroId) {
        logro.TieneLogro = true;
        setMiLogro(logro);
      }
    });
  
    dispatch(logroActions.setLogro(logros.logro));
  
    const addLogro = async (yatengoEsteLogro) => {
      if (!yatengoEsteLogro) {
        
        const logroAdd = await gameService.Logro({
          LogroId: props?.logroId,
        });
        
        console.log("LOGRO ADD",logroAdd);
        if(logroAdd!==null && logroAdd?.Id?.length>5){
            dispatch(authActions.cargarDatosSinNavegacion(logroAdd));
        }
        
      }
    };
 // props.logroId = -1;
    addLogro(yatengoEsteLogro);
  }, [props.logroId]);
  
  const shareHander = async () => {
    try {
      let titleApp = miLogro.Name;
      titleApp += " : " + t("share_title");
      await Share.share({
        title: miLogro.Name,
        text: miLogro.DescriptionGanoLogro,
        url: configData.web,
        dialogTitle: t("share_title"),
      });
    } catch (error) {}
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    <IonModal
      isOpen={props?.estadoModal}
      onDidDismiss={() => {
        props?.eventoCerrar();
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={() => props?.eventoCerrar()}>
              {" "}
              {t("close")}
            </IonButton>
          </IonButtons>
          <IonTitle>{miLogro?.Name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard className="ionCard minimo_card_logro squares" key={1}>
          <IonCardHeader>
            <IonCardTitle className="titulo_principal">
              {miLogro?.Name}
            </IonCardTitle>
          </IonCardHeader>
          <img
            key={1}
            alt="logro"
            className={"imgLogoActivo"}
            src={"https://www.pekegames.app/img/logro/" + miLogro?.Image}
          ></img>
          <IonCardContent>{miLogro?.DescriptionGanoLogro}</IonCardContent>

          <hr className="separador"></hr>
          <h2 className="titulo_premio_logro">{t("title_win_logro")}</h2>
          <IonRow>
            {miLogro?.ayuda_vida > 0 && (
              <IonCol className="text_center">
                <img
                  alt={t("life_50")}
                  src="/assets/icon/vida.png"
                  className="icon_ayuda_perfil"
                ></img>
              </IonCol>
            )}

            {miLogro?.ayuda_50 > 0 && (
              <IonCol className="text_center">
                <img
                  alt={t("help_50")}
                  src="/assets/icon/ayuda50.png"
                  className="icon_ayuda_perfil"
                ></img>
              </IonCol>
            )}

            {miLogro?.ayuda_reloj > 0 && (
              <IonCol className="text_center">
                <img
                  alt={t("help_longer")}
                  src="/assets/icon/ayudaMasTiempo.png"
                  className="icon_ayuda_perfil"
                ></img>
              </IonCol>
            )}

            {miLogro?.ayuda_recuperar_puntos > 0 && (
              <IonCol className="text_center">
                <img
                  alt={t("help_not_to_lose_points")}
                  src="/assets/icon/ayudaRecuperarPuntos.png"
                  className="icon_ayuda_perfil"
                ></img>
              </IonCol>
            )}

            {miLogro?.ayuda_respuesta_correcta > 0 && (
              <IonCol className="text_center">
                <img
                  alt={t("help_correct_answer")}
                  src="/assets/icon/ayudaRespuestaCorrecta.png"
                  className="icon_ayuda_perfil"
                ></img>
              </IonCol>
            )}

            {miLogro?.ayuda_doble_oportunidad > 0 && (
              <IonCol className="text_center">
                <img
                  alt={t("help_doble")}
                  src="/assets/icon/oportunidadDoble.png"
                  className="icon_ayuda_perfil"
                ></img>
              </IonCol>
            )}

            {miLogro?.ayuda_moneda > 0 && (
              <IonCol className="text_center">
                <img
                  alt={t("coin")}
                  src="/assets/icon/moneda.png"
                  className="icon_ayuda_perfil"
                ></img>
              </IonCol>
            )}
          </IonRow>
          <IonRow>
            {miLogro?.ayuda_vida > 0 && (
              <IonCol className="color_premio">{miLogro?.ayuda_vida}</IonCol>
            )}
            {miLogro?.ayuda_50 > 0 && (
              <IonCol className="color_premio">{miLogro?.ayuda_50}</IonCol>
            )}
            {miLogro?.ayuda_reloj > 0 && (
              <IonCol className="color_premio">{miLogro?.ayuda_reloj}</IonCol>
            )}
            {miLogro?.ayuda_recuperar_puntos > 0 && (
              <IonCol className="color_premio">
                {miLogro?.ayuda_recuperar_puntos}
              </IonCol>
            )}
            {miLogro?.ayuda_respuesta_correcta > 0 && (
              <IonCol className="color_premio">
                {miLogro?.ayuda_respuesta_correcta}
              </IonCol>
            )}
            {miLogro?.ayuda_doble_oportunidad > 0 && (
              <IonCol className="color_premio">
                {miLogro?.ayuda_doble_oportunidad}
              </IonCol>
            )}
            {miLogro?.ayuda_moneda > 0 && (
              <IonCol className="color_premio">{miLogro?.ayuda_moneda}</IonCol>
            )}
          </IonRow>
          <IonButton color="primary" shape="round" onClick={shareHander}>
            {t("share")}
          </IonButton>
        </IonCard>
      </IonContent>
    </IonModal>
  );
};

export default GanaLogro;
