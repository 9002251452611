import { Keyboard } from "@capacitor/keyboard";
import {
  IonModal,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonItem,
  IonInput,
  IonButton,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
} from "@ionic/react";
import { text, closeOutline, closeCircle } from "ionicons/icons";
import React, { Component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OperacionSaveR, validarCorreoElectronico } from "../../utils/functions";
import { authActions, gameActions, partidaActions } from "../../_redux/actions";
import { appActions } from "../../_redux/actions/app.actions";
import { AuthState, MyUser } from "../../_redux/models/auth.model";
import { RankingOnlineRequest } from "../../_redux/models/game.model";
import { PartidaState } from "../../_redux/models/partida.model";
import { gameService } from "../../_redux/services";
import styles from "./crearcuenta.module.scss";
export const CrearCuenta: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loginModal, setLoginModal] = useState(false);
  const [text, setText] = useState<string>();
  const [contrasena, setContrasena] = useState<string>();
  const [contrasenaConfirmar, setContrasenaConfirmar] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [loginActive, setLoginActive] = useState<boolean>(true);
  const partida: PartidaState = useSelector((state: any) => state.partida);
  const authUser: AuthState = useSelector((state: any) => state.auth);
  const [cuentaCreada, setCuentaCreada] = useState(false);
  const userData: MyUser = {
    Id: "-1",
    Avatar: "a0.svg",
    Nick: t("guest"),
    NuevoUsuarioEnElJuego: false,
    PaisId: 0,
    Token: "",
    DatosExtendido: null,
    Username: t("guest"),
    Password: "",
    Auth: false,
    LogrosUsuario: null
  };

  let dataRanking: RankingOnlineRequest = {
    MiAppId: 0,
    TipoRanking: 0,
    Finalizado: true,
  };

  const onKeyPressCode = (e) => {
    e.preventDefault();
    if (loginActive) {
      if (e.key === "Enter") {
        Keyboard.hide();
        acceder();
      }
    }
  };
  const onKeyPressCodeCrearCuenta = (e) => {
    e.preventDefault();
    if (!loginActive) {
      if(e.key === "Enter"){
         Keyboard.hide();
         crearCuenta();
      }
    }
  };

  const onKeyPressCodeNewPassword = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      const inputElement = document.querySelector(
        "input[name='pass1']"
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
    }
  };

  const onKeyPressCodeNewPassword2 = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      const inputElement = document.querySelector(
        "input[name='pass2']"
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
    }
  };

  const acceder = async () => {
    try {
      dispatch(authActions.biometricoEstado(true));
      if (
        contrasena === null ||
        contrasena?.length <= 4 ||
        contrasena === undefined
      ) {
       

        dispatch(
          appActions.showToast({
            message: t("error_you_must_enter_your_password"),
            type: "danger",
            position: "top",
            icon: "globe",
            duration: 1500,
          })
        );
        return;
      }
      dispatch(gameActions.IsBusy());
      userData.Username = text;
      userData.Password = contrasena;
      dispatch(authActions.loginPekeSinNavegacion(userData));
      props?.eventoCerrar();
    } catch (error) {
      dispatch(gameActions.QuitarIsBusy());
    }
  };

  const crearCuenta = () => {
    try {

      if(!validarCorreoElectronico(text)){
        dispatch(
          appActions.showToast({
            message: t("confirm_email_error"),
            type: "danger",
            position: "top",
            icon: "globe",
            duration: 5000,
          })
        );
        return;
      }
      
      if(contrasena !== contrasenaConfirmar){
        dispatch(
          appActions.showToast({
            message: t("confirm_password_error"),
            type: "danger",
            position: "top",
            icon: "globe",
            duration: 5000,
          })
        );
        return;
      }



      dispatch(gameActions.IsBusy());
      userData.Username = text;
      userData.Password = contrasena;
      dispatch(authActions.crearCuentaPekeSinNavegar(userData));
      dispatch(gameActions.QuitarIsBusy());
      setCuentaCreada(true);
      props?.eventoCerrar();
    } catch (error) {
      dispatch(gameActions.QuitarIsBusy());
      props?.eventoCerrar();
    }
  };

  useEffect(() => {
    try {
      if (authUser?.user?.Auth && props?.navegar == true && cuentaCreada) {
        dispatch(gameActions.IsBusy());
        dataRanking.Finalizado = true;
        partida.finalizarJuego = true;
        dataRanking.Punto = parseInt(partida.point.toFixed(0));
        dataRanking = OperacionSaveR(dataRanking, partida, authUser);
        dataRanking.Punto = parseInt(partida.point.toFixed(0));
        dataRanking = OperacionSaveR(dataRanking, partida, authUser);
        partida.gameStart = false;
        if (props?.navegar == true) {
          gameService.SetRanking(dataRanking);
          localStorage.removeItem("jogo");
        }
        props?.eventoCerrar();
      }
    } catch (error) {
      dispatch(gameActions.QuitarIsBusy());
    }
    dispatch(gameActions.QuitarIsBusy());
  }, [authUser]);
  return (
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    <IonModal
      isOpen={props?.estadoModal}
      onDidDismiss={() => {
        props?.eventoCerrar();
      }}
      cssClass={styles.mini}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={() => props?.eventoCerrar()}>
              {" "}
              {t("close")}
            </IonButton>
          </IonButtons>
          <IonTitle>{t("login")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <img
          alt="Logo Peke Games"
          className={styles.login_logo}
          src="/assets/icon/logo_pk.png"
        ></img>
        <IonSegment
          value={loginActive ? "Login" : "Register"}
          onIonChange={(e) => {
            e.detail.value === "Login"
              ? setLoginActive(true)
              : setLoginActive(false);
          }}
        >
          <IonSegmentButton value="Login">
            <IonLabel> {t("login")}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="Register">
            <IonLabel> {t("register")}</IonLabel>
          </IonSegmentButton>
        </IonSegment>

        <IonItem>
          <IonLabel color="secondary" position="floating">
            {t("enter_your_email_here")}
          </IonLabel>
          <IonInput
            value={text}
            onIonChange={(e) => setText(e.detail.value!)}
            clearInput
            name="campologin"
            enterkeyhint={"next"}
            onKeyUp={onKeyPressCodeNewPassword}
            autofocus
          ></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel color="secondary" position="floating">
            {t("enter_your_password_here")}
          </IonLabel>
          <IonInput
            type="password"
            name="pass1"
            value={contrasena}
            placeholder="********"
            onIonChange={(e) => setContrasena(e.detail.value!)}
            //onClick={(i) => setContrasena(i.detail.toString())}
            clearInput
            enterkeyhint={loginActive ? "done" : "next"}
            onKeyUp={
              loginActive ? onKeyPressCode : onKeyPressCodeNewPassword2
            }
          ></IonInput>
        </IonItem>

        {loginActive === true ? (
          ""
        ) : (
          <IonItem>
            <IonLabel color="secondary" position="floating">
              {t("confirm_password")}
            </IonLabel>
            <IonInput
              type="password"
              name="pass2"
              value={contrasenaConfirmar}
              placeholder="********"
              onIonChange={(e) => setContrasenaConfirmar(e.detail.value!)}
              clearInput
              enterkeyhint="done"
              onKeyUp={onKeyPressCodeCrearCuenta}
            ></IonInput>
          </IonItem>
        )}

        {loginActive === true ? (
          <div
            onClick={(p) => setShowModal(true)}
            className={styles.recuperar_contrasenia}
          >
            {t("recover_password")}
          </div>
        ) : (
          <div className={styles.salto_linea}></div>
        )}

        {loginActive === true ? (
          <IonButton
            shape="round"
            className="btn_sty"
            color="primary"
            onClick={acceder}
          >
            {t("enter")}
          </IonButton>
        ) : (
          <IonButton
            shape="round"
            className="btn_sty"
            color="primary"
            onClick={crearCuenta}
          >
            {t("create_account_button")}
          </IonButton>
        )}
      </IonContent>
    </IonModal>
  );
};

export default CrearCuenta;
