import { IonContent, IonLoading, IonPage, isPlatform } from '@ionic/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { history } from "../../_helpers/history";

const Callback: React.FC<any> = () => {
  const query = new URLSearchParams(useLocation().search);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isPlatform('capacitor')) {
      if (query.get('city')) {
        const city = query.get('city');
        history.push(
            "cityDetail/" + city.replaceAll(" ", "_"),
            {
              item: city,
            }
          )
        }
      }
  }, []);
  return (
    <IonPage>
      <IonContent>
        <IonLoading isOpen={true} message={t('msg_loading')} duration={100} />
      </IonContent>
    </IonPage>
  );
};

export default Callback;
