import React, { useEffect, useState } from "react";
import { TiempoResponse } from "../../_redux/models/tiempo.model";
import { gameService } from "../../_redux/services";
import DatoTuTiempo from "./DatoTuTiempo";
import Vaciotiempo from "./VacioTiempo";

export const TuTiempo: React.FC<any> = (props) => {
  const [eltiempo, setElTiempo] = useState<TiempoResponse | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const dataServicio = await gameService.GetElTiempo(props.ciudad);

        if (isMounted && dataServicio !== null && dataServicio !== undefined) {
          setElTiempo(dataServicio);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (props !== null && props.ciudad !== null) {
      fetchData();
    }

    return () => {
      // Se ejecuta al desmontar el componente
      isMounted = false;
    };
  }, [props.ciudad]);

  return (
    <div className="margin_buttom_banner">
      {eltiempo == null ? (
        <Vaciotiempo ciudad={props.ciudad} />
      ) : (
        <DatoTuTiempo data={eltiempo} />
      )}
    </div>
  );
};

export default TuTiempo;
