import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import reducers from '../reducers';
import { Middleware } from 'redux';
//TODO: remove for production (redux DevTools: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd)
export const composeEnhancers = compose;
  //(window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const middlewares: Middleware[] = [
  thunk
];

const store = createStore(
  reducers,
  /* preloadedState, */ composeEnhancers(applyMiddleware(...middlewares)),
);

export default store; 