import { authTypes } from "../types";
import {
  MyUser,
  AuthState,
  AuthAction
} from "../models/auth.model";

const initialState = {
  error: null,
  isLoading: false,
  isAuth: false,
  user: null,
  biometrico:false
};

export const authReducer = (
  state: AuthState = initialState,
  action: AuthAction
): any => {
  switch (action.type) {
    case authTypes.REQUEST:
      return {
        ...state,
        ...{ error: null, isLoading: true },
      };
    case authTypes.AUTH_INVITATION:
      return {
        ...state,
        ...{ error: null, isLoading: false, isAuth: false, user: action.user },
      };
    case authTypes.AUTH_SUCCESS:
      return {
        ...state,
        ...{ error: null, isLoading: false, isAuth: true, user: action.user },
      };
    case authTypes.AYUDA_ACTUALIZAR:
      return {
        ...state,
        ...{ user: action.user },
      };
    case authTypes.FAILURE:
      return {
        ...state,
        ...{ error: action.error, isLoading: false, isAuth: false, user: action.user },
      };
    case authTypes.ERROR:
      return {
        ...state,
        ...{ error: action.error },
      };
      case authTypes.BIOMETRICO:
      return {
        ...state,
        ...{ biometrico: action.biometrico },
      };
    case authTypes.REFRESH_TOKEN:
      return {
        ...state,
        ...{ error: null, isLoading: false, isAuth: true, user: action.user },
      };
    case authTypes.LOGOUT:
      return {
        ...state,
        ...{ error: null, isLoading: false, isAuth: false, user: null },
      };
      case authTypes.VIDA_INICIAL:
        state.user.DatosExtendido.Vida = 1;
        return {
          ...state
        };
    default:
      return state;
  }
};


