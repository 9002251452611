import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState, AppStore } from "../../_redux/models/app.model";
import { appActions } from "../../_redux/actions/app.actions";

const BannerLocalLateralGrande: React.FC = () => {
  const [currentAd, setCurrentAd] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const appConfig: AppState = useSelector((store: AppStore) => store.app);
  const dispatch = useDispatch();
  const inicioBanner = [
    {
      title: "",
      text: "",
      image: "https://www.pekegames.app/Img/App/Banner/3/160_600_cafe.gif",
      url: "https://www.buymeacoffee.com/pekegames",
    },
    {
      title: "A little coffee",
      text: "It is a way of collaborating with us. Your help is very important.",
      image: "",
      url: "https://www.buymeacoffee.com/pekegames",
    },
    {
      title: "",
      text: "",
      image: "https://www.pekegames.app/Img/App/Banner/60_600_brasi_lenceria.png",
      url: "https://brasi.eu",
    },
    {
      title: "Lencería",
      text: "¡Descubre las mejores ofertas en lencería de las marcas más destacadas!",
      image: "",
      url: "https://brasi.eu",
    }
  ];
  const [ads, setAds] = useState(inicioBanner);

  useEffect(() => {
     setIsMounted(true);
    const fetchData = async () => {
      try {
        // Llamada al servicio para obtener los datos de ads
        if (appConfig.banner3.length > 0) {
          console.log("banner3 entro",appConfig.banner3);
          setAds(appConfig.banner3);
          return;
        }
        const response = await fetch(process.env.REACT_APP_SERVICE + "/api/pekegames/banner?formatoId=5&appId="+process.env.REACT_APP_MIAPPID+"&language="+localStorage.getItem('i18nextLng'));

        /* estructura de datos de ejemplo
        const data2 = [
          {
            title: "Lenceria Brasileña",
            text: "Moderna y sexy al mejor precio",
            image: "",
            url: "https://www.pekegames.app",
          },
          {
            title: "Descuentos especiales",
            text: "en ropa de baño",
            image: "",
            url: "https://www.pekegames.app",
          },
          {
            title: "Nuevas colecciones",
            text: "de moda para mujer",
            image: "https://www.shutterstock.com/image-vector/honey-banner-copy-space-468x60-260nw-2111413184.jpg",
            url: "https://www.pekegames.app",
          },
          {
            title: "",
            text: "Solo por hoy las mejores ofertas de todos los tiempos. No pierda la oportunidad y compre ahora mismo.",
            image: "",
            url: "https://www.pekegames.app",
          },
          {
            title: "Lenceria titulo",
            text: "",
            image: "",
            url: "https://www.pekegames.app",
          },
        ];
        */
        const data = await response.json();
        if(data.length>0) {
          setAds(data);
          dispatch(appActions.setBanner3(data));
        }

      } catch (error) {
        console.error("Error al obtener los datos de ads:", error);
      }
    };

    fetchData();  return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  useEffect(() => {
    const interval = setInterval(() => {
       if (isMounted) {
      setCurrentAd((prevAd) => (prevAd + 1) % ads.length);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [ads,isMounted]);

 const abrirBannerWeb = async (item: any) => {
    if (isMounted && item.url.length > 1) {
      window.open(item.url, "_blank");
    }
  };

  if (!isMounted) {
    return null;
  }

  return (
    <div className="bannerlocalLateral" onClick={() => abrirBannerWeb(ads[currentAd])}>
      {ads[currentAd].image.length > 1 ? (
        <img src={ads[currentAd].image} alt={ads[currentAd].title} />
      ) : (
        <>
          {ads[currentAd].title.length > 1 && <h1>{ads[currentAd].title}</h1>}

          {ads[currentAd].text}
        </>
      )}
    </div>
  );
};

export default BannerLocalLateralGrande;
