import { languageTypesEnum  } from '../types';
import { gameService } from '../services';
import {LanguageState , LanguageAction , Language} from '../models/language.model'


const languageRequest = (): LanguageAction => {
    return {
      type: languageTypesEnum.REQUEST,
    };
};

const fetchlanguageFailure = (error: string): LanguageAction => {
    return {
      type: languageTypesEnum.FAILURE,
      error: error,
    };
};

const setLanguage = (language:any): LanguageAction => ({
    type: languageTypesEnum.SUCCESS,
    languages : language
    
  }); 

const getLanguage = () => {
    return async (dispatch: any): Promise<any> => {
      dispatch(languageRequest());
      try {
        const response: any = await gameService.getLanguageForApp();
        dispatch(setLanguage(response));
      } catch (e) {
        console.log('Error en getLanguage');
        dispatch(fetchlanguageFailure(e.message));
      }
    };
  };

  export const languageActions = {
    getLanguage
  };