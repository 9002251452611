import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { AuthStore } from "../../_redux/models/auth.model";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { gameActions } from "../../_redux/actions";
import { useEffect, useState } from "react";
import { MenuApp } from "../../utils/functions";
import { gameService } from "../../_redux/services";
import { chevronForward } from "ionicons/icons";
import { history } from "../../_helpers/history";
import MenuLateral from "../../components/Menu/MenuLateral";
import { DataGame, GameState } from "../../_redux/models/game.model";
import Enumerable from "linq";
import { configData } from "../../data/configData";
import AdsLateralPropio from "../../components/Ads/AdsLateralPropio";
import { gameTypesEnum } from '../../_redux/types/game.types';
import { questionTypesEnum } from "../../_redux/types";
import BannerLocalLateralGrande from "../../components/Ads/BannerLocalLateralGrande";
import Media from "react-media";

const City: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const myUser = useSelector((store: AuthStore) => store.auth.user);
  const [showModal, setShowModal] = useState(false);
  const [showModalRestaurante, setShowModalRestaurante] = useState(false);
  const [showModalHotel, setShowModalHotel] = useState(false);
  const [showModalTurismo, setShowModalTurismo] = useState(false);
  const [dataYelp, seDataYelp] = useState(null);

  const gameGlobal: GameState = useSelector((state: any) => state.game);
  const [urlWiki, setUrlWiki] = useState("");
  const [searchText, setSearchText] = useState("");
  const [listcity, setListcity] = useState<DataGame[]>(null);
  const [present, dismiss] = useIonActionSheet();

  const abrirModal = async (data: DataGame) => {
    data.W = data.W.replace("http://", "https://");
    console.log(data.W);
    setUrlWiki(data.W);
    setShowModal(true);
  };

  const BotonVolver = () => {
    history.goBack();
  };

  const abrirModalRestaurante = async (data: DataGame) => {
    const auxDataYelp = await gameService.GetRestaurante(data.N);
    if (auxDataYelp !== null && auxDataYelp.businesses !== null) {
      console.log("entro en el IF", auxDataYelp.businesses);
      seDataYelp(auxDataYelp);
      setShowModalRestaurante(true);
    } else {
      alert(auxDataYelp.total);
    }
  };

  const abrirModalTurismo = async (data: DataGame) => {
    const auxDataYelp = await gameService.GetPuntosTuristicos(data.N);
    if (auxDataYelp !== null && auxDataYelp.businesses !== null) {
      console.log("entro en el IF", auxDataYelp.businesses);
      seDataYelp(auxDataYelp);
      setShowModalTurismo(true);
    } else {
      alert(auxDataYelp.total);
    }
  };

  const abrirModalHotel = async (data: DataGame) => {
    const auxDataYelp = await gameService.GetHotel(data.N);
    if (auxDataYelp !== null && auxDataYelp.businesses !== null) {
      seDataYelp(auxDataYelp);
      setShowModalHotel(true);
    } else {
      alert(auxDataYelp.total);
    }
  };

  const buscador = async (data: string) => {
    try {
      setSearchText(data);
      setListcity(
        Enumerable.from(gameGlobal.provinceCities)
          .where((p) => p.N.toLowerCase().includes(data.toLowerCase()))
          .toArray()
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      dispatch(gameActions.ListCity);
      setListcity(gameGlobal.provinceCities);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <IonPage id="page-dashboard" className="page-dashboard">
      <IonHeader>
        <IonToolbar className="menu_movil_home_2">
          {MenuApp(present, t)}
          <IonTitle>{t("cities")}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="bg_app_content">
        <IonRow>
          <IonCol size="12" size-md="3" className="lateral hide_pc">
            <MenuLateral></MenuLateral>
          </IonCol>
          <IonCol size="12" size-md="7" className="centro_pagina">
            <IonSearchbar
              placeholder={t("search_cities")}
              value={searchText}
              onIonChange={(e) => buscador(e.detail.value!)}
            ></IonSearchbar>
            <IonList className="scroll_lista">
              {Array.isArray(listcity) &&
                listcity.length > 0 &&
                listcity.map((item, index) => {
                  if (item.N.includes("https") || item.T === questionTypesEnum.GPT ) {
                    return null;
                  }
                  return (
                    <IonList key={index} className="item_lista_city">
                      <IonItem
                        onClick={() =>
                          history.push(
                            "cityDetail/" + item.N.replaceAll(" ", "_"),
                            {
                              item: item,
                            }
                          )
                        }
                      >
                        <IonLabel> {item?.N}</IonLabel>
                        <IonIcon icon={chevronForward} />
                      </IonItem>
                    </IonList>
                  );
                })}

              {listcity?.length === 0 && (
                <div className="noDataContainer">
                  <h3>
                    {" "}
                    {t("no_data_city", {
                      search: searchText,
                      estado: configData.provincia_o_estado,
                    })}
                  </h3>
                </div>
              )}
            </IonList>
            <div className="margin_buttom_banner"></div>
            <div className="margin_buttom_banner"></div>
          </IonCol>
          <IonCol size="0" size-md="2" className="hide_pc">
          <Media
              query="(min-width: 600px)"
              render={() => (
                <BannerLocalLateralGrande></BannerLocalLateralGrande>
              )}
            />
          </IonCol>
        </IonRow>
        <div className="margin_buttom_banner"></div>
      </IonContent>
    </IonPage>
  );
};

export default City;
