import { Share } from "@capacitor/share";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Enumerable from "linq";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { configData } from "../../data/configData";
import { LogroState } from "../../_redux/models/logro.models";

export const VerLogro: React.FC<any> = (props) => {
  const logros: LogroState = useSelector((state: any) => state.logro);
  const { t } = useTranslation();

  const miLogro = Enumerable.from(logros.logro)
    .where((p) => p.LogroId === Number(props?.logro?.CodigoLogro))
    .firstOrDefault();

  const shareHander = async () => {
    try {
      let titleApp = miLogro.Name;
      titleApp += " : " + t("share_title");
      await Share.share({
        title: miLogro.Name,
        text: miLogro.DescriptionGanoLogro,
        url: configData.web,
        dialogTitle: t("share_title"),
      });
    } catch (error) {}
  };

  return (
    <IonModal
      isOpen={props?.estadoModal}
      onDidDismiss={() => {
        props?.eventoCerrar();
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={() => props?.eventoCerrar()}>
              {" "}
              {t("close")}
            </IonButton>
          </IonButtons>
          <IonTitle>{t("logro")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard className="ionCard minimo_card_logro squares" key={1}>
          <IonCardHeader>
            <IonCardTitle className="titulo_principal">
              {miLogro?.Name}
            </IonCardTitle>
          </IonCardHeader>
          <img
            key={1}
            alt="logro"
            className={
              miLogro?.TieneLogro ? "imgLogoActivo" : "imgLogoInactivo"
            }
            src={"https://www.pekegames.app/img/logro/" + miLogro?.Image}
          ></img>
          <IonCardContent>
            {miLogro?.TieneLogro
              ? miLogro?.DescriptionGanoLogro
              : miLogro?.Description}
          </IonCardContent>

          <IonButton color="primary" shape="round" onClick={shareHander}>
            Compartir
          </IonButton>
        </IonCard>
      </IonContent>
    </IonModal>
  );
};

export default VerLogro;
