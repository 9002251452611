import { combineReducers } from "redux";

import { languageReducer } from "./language.reducers";
import { gameReducer } from "./game.reducers";
import { authReducer } from "./auth.reducers";
import { partidaReducer } from "./partida.reducers";
import { appReducer } from "./app.reducers";
import { logroReducer } from "./logro.reducers";
const rootReducer = combineReducers({
  language: languageReducer,
  game: gameReducer,
  auth: authReducer,
  partida: partidaReducer,
  app: appReducer,
  logro: logroReducer,
});

export default rootReducer;
