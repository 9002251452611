import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { IonApp, IonRouterOutlet, IonToast } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import { authActions, gameActions } from "./_redux/actions";
import NotificationService from "./_redux/services/notifications.service";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import { history } from "./_helpers/history";
/* Theme variables */
import "../src/app.scss";
import Test from "./pages/Test/Test";
import Login from "./pages/Login/Login";
import { AppStore } from "./_redux/models/app.model";
import Dashboard from "./pages/Dashboard/Dashboard";
import Profile from "./pages/Profile/Profile";
import Quiz from "./pages/Quiz/Quiz";
import Ranking from "./pages/Ranking/Ranking";
import City from "./pages/City/City";
import EndGame from "./pages/EndGame/EndGame";
import Register from "./pages/Register/Register";
import Correcto from "./pages/Quiz/Correcto";
import Estilo from "./pages/Test/Estilo";
import Photo from "./pages/Photo/Photo";
import Apps from "./pages/Apps/Apps";
import { gameService } from "./_redux/services";
import CityDetail from "./pages/CityDetail/CityDetail";
import { AuthState, MyUser } from "./_redux/models/auth.model";
import { GameState } from "./_redux/models/game.model";
import { appActions } from "./_redux/actions/app.actions";
import LoaderLottie from "./components/Lotties/LoaderLottie";
import Store from "./pages/Store/Store";
import About from "./pages/About/About";
import { AdMob } from "@capacitor-community/admob";
import { Capacitor } from "@capacitor/core";
import Ayuda from "./pages/Ayuda/Ayuda";
import { useTranslation } from "react-i18next";
import BannerLocal from "./components/Ads/BannerLocal";
import Deeplink from "./pages/Login/Deeplink";
import BannerModalLocal from "./components/Ads/BannerModalLocal";
import Media from "react-media";
import GanaLogro from "./components/Logro/GanaLogro";

const App: React.FC<any> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const notificationService: NotificationService = new NotificationService();

  const { theme, toast, bannerLocal, bannerModalLocal, isPrepareBanner, logro } =
    useSelector((state: AppStore) => state.app);
  const game: GameState = useSelector((state: any) => state.game);
  const authUser: AuthState = useSelector((state: any) => state.auth);

  const cerrarModaGanalLogro = () => {
    dispatch(appActions.ocultarLogro());
  };

  useEffect(() => {
    initservice();
    if (Capacitor.isNativePlatform()) {
      AdMob.trackingAuthorizationStatus();
      AdMob.initialize({
        //requestTrackingAuthorization: false
      });
    }
    initBanner();
  }, []);

  const initservice = async () => {
    try {
      dispatch(gameActions.IsBusy());
      dispatch(appActions.getAppSettings());
      if (localStorage.getItem("appTheme") === "dark") {
        dispatch(appActions.setAppTheme("dark"));
      }
      const data = gameService.getAccount();
      dispatch(gameActions.initialGame());
      dispatch(gameActions.cargarFotos());
      //dispatch(authActions.checkAuth());
      //ACTUALIZAR PROFILE TAMBIEN AQUI - TODO
      const user: MyUser = {
        Id: "-1",
        Avatar: "a1.jpg",
        Nick: t("guest"),
        NuevoUsuarioEnElJuego: false,
        PaisId: -1,
        Token: "",
        DatosExtendido: {
          Id: -1,
          UsuarioId: "-1",
          MiAppId: -1,
          Punto: 0,
          RachaEnDia: 0,
          Ayuda50: 0,
          AyudaPreguntaCorrecta: 0,
          AyudaContestar2Veces: 0,
          AyudaMasTiempo: 0,
          RecuperarPuntosPerdido: 0,
          RecuperarVida: 0,
          RecuperarRacha: 0,
          Vida: 0,
          NumeroPartida: 0,
          MejorPosicion: 0,
          MejorPuntuacion: 0,
          MejorNivel: 0,
          PerfilPrivado: false,
          Aciertos: 0,
          Errores: 0,
          TipoPlataforma: 3,
          FormatoApp: 3,
          Creado: null,
          FechaUltimoAcceso: null,
          MonedaGlobal: 0,
          PuntosGlobales: 0,
        },
        Username: "Invitado",
        Password: "",
        Auth: false,
        LogrosUsuario: undefined,
      };

      if (data !== null) {
        gameService.MiToken();


        if (data !== null && data.Username !== null) {
          if (!window.location.pathname.includes("/login")) {
            dispatch(authActions.loginPekeSinNavegacion(data));
          } else {
            dispatch(authActions.loginPeke(data));
          }
        } else {
          const cuenta_invitado = gameService.getAccountInvitado();
          if (cuenta_invitado !== null) {
            dispatch(authActions.cargarDatosSinNavegacion(cuenta_invitado));
          } else {
            dispatch(authActions.loginInvitationArrancarApp(user));
          }
        }
      } else {
        dispatch(authActions.loginInvitationArrancarApp(user));
      }
      dispatch(gameActions.QuitarIsBusy());
    } catch (error) {
      dispatch(gameActions.QuitarIsBusy());
    }
  };

  const initBanner = async () => {
    dispatch(appActions.setAppHabilitarBanner());
    dispatch(appActions.refrescarBanner());
  };

  return (
    <IonApp className={theme === "dark" ? "dark-theme" : ""}>
      <IonReactRouter history={history}>
        <IonRouterOutlet>
          <Route exact path="/profile">
            <Profile />
          </Route>

          <Route exact path="/estilo">
            <Estilo />
          </Route>

          <Route exact path="/ranking">
            <Ranking />
          </Route>

          <Route exact path="/cities">
            <City />
          </Route>

          <Route exact path="/gameOver">
            <EndGame />
          </Route>

          <Route exact path="/apps">
            <Apps />
          </Route>

          <Route exact path="/dashboard">
            <Dashboard />
          </Route>

          <Route exact path="/quiz">
            <Quiz />
          </Route>

          <Route exact path="/deeplink">
            <Deeplink />
          </Route>

          <Route exact path="/cityDetail/*">
            <CityDetail />
          </Route>

          <Route exact path="/ayuda">
            <Ayuda />
          </Route>

          <Route exact path="/photo">
            <Photo />
          </Route>
          <Route exact path="/login">
            <Login notificationService={notificationService} />
          </Route>
          <Route exact path="/test">
            <Test />
          </Route>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>

          <Route exact path="/register">
            <Register />
          </Route>

          <Route exact path="/store">
            <Store />
          </Route>

          <Route exact path="/about">
            <About />
          </Route>

          <Route exact path="/ok">
            <Correcto />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
      <IonToast
        isOpen={!!game.error}
        color="warning"
        message={game.error}
        duration={2000}
        position="top"
      />

      {/* <IonToast
        isOpen={!!myUser.error}
        color="warning"
        message={myUser.error}
        duration={2000}
        onDidDismiss={() => dispatch(authActions.errorSistema(null))}
        position="top"
      /> */}

      <IonToast
        isOpen={!!toast}
        onDidDismiss={() => dispatch(appActions.removeToast())}
        message={toast?.message}
        color={toast?.type}
        position={toast?.position === undefined ? "bottom" : toast?.position}
        duration={toast?.duration === undefined ? 3000 : toast?.duration}
      />
      {bannerModalLocal && isPrepareBanner ? (
        <BannerModalLocal></BannerModalLocal>
      ) : (
        ""
      )}

      <Media
        query="(max-width: 599px)"
        render={() =>
          bannerLocal && isPrepareBanner ? <BannerLocal></BannerLocal> : ""
        }
      />
      {
        authUser !== null &&
        authUser.isAuth &&
        logro !== -1 ? (
          <GanaLogro
            estadoModal={logro===-1?false:true}
            eventoCerrar={cerrarModaGanalLogro}
            logroId={logro}
          ></GanaLogro>
        ) : (
          ""
        )}

      {game.isLoading ? (
        <div className="cargando">
          <LoaderLottie></LoaderLottie>
        </div>
      ) : (
        ""
      )}
    </IonApp>
  );
};

export default App;
