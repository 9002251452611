import { gameTypesEnum } from "../types";
import { gameService, jsonService } from "../services";
import {
  GameState,
  GameAction,
  DataGame,
  RankingOnlineResponse,
  RankingOnlineRequest,
} from "../models/game.model";
import { questionTypesEnum } from "../types/question.enum";
import { env } from "process";
import { history } from "../../_helpers/history";
import { AuthState } from "../models/auth.model";
import jsonGPT from '../../data/ciudad.json'
import { isArray } from "util";
import Enumerable from "linq";

//Game se usa para cargas las preguntas
//GameCurso en curso, para guardar los datos de la partida
const gameRequest = (): GameAction => {
  return {
    type: gameTypesEnum.REQUEST,
  };
};

const QuitarIsBusy = (): GameAction => {
  return {
    type: gameTypesEnum.QUITAR_ISBusy,
  };
};
const IsBusy = (): GameAction => {
  return {
    type: gameTypesEnum.IS_BUSY,
  };
};

const errorRequest = (error: string): GameAction => {
  return {
    type: gameTypesEnum.ERROR,
    error: error,
  };
};

const provinceLoader = (province: DataGame): GameAction => {
  return {
    type: gameTypesEnum.PROVINCE,
    province: province,
  };
};

const questionLoader = (cities: DataGame[]): GameAction => {
  return {
    type: gameTypesEnum.LOAD_QUESTION,
    provinceCities: cities,
    numberOfQuestions: cities.length - 1,
  };
};

const questionPhoto = (cities: DataGame[]): GameAction => {
  return {
    type: gameTypesEnum.LOAD_IMAGE,
    provinceCities: cities,
    numberOfQuestions: cities.length,
  };
};

const citiesLoader = (cities: DataGame[]): GameAction => {
  return {
    type: gameTypesEnum.CITIES_OTHER,
    cities: cities,
  };
};

const timeRun = (time: number): GameAction => {
  return {
    type: gameTypesEnum.TIME_GAME_RUN,
  };
};

const newQuestion = (
  question: DataGame,
  answered: string[],
  currentQuestion: number,
  quiz: DataGame[]
): GameAction => {
  return {
    type: gameTypesEnum.NEXT_QUESTION,
    response: question,
  };
};

const firtQuestion = (
  question: DataGame,
  answered: string[],
  currentQuestion: number,
  quiz: DataGame[]
): GameAction => {
  return {
    type: gameTypesEnum.START_GAME,
    response: question,
  };
};

const PARAR_JUEGO = (): GameAction => {
  return {
    type: gameTypesEnum.PAUSAR_JUEGO
  };
};

const RESET = (): GameAction => {
  return {
    type: gameTypesEnum.RESET
  };
};

const initialGame = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(gameRequest());
    try {

      const province: DataGame = await jsonService.ProvinceGameService();
      dispatch(provinceLoader(province));

      const question: DataGame[] = await jsonService.QuestionGameService();

      if (question !== null || question !== undefined) {
        question.forEach((element) => {
          element.T = questionTypesEnum.Classic;
        });
      }

    /*
      if (jsonGPT != null || jsonGPT !== undefined) {
        jsonGPT.forEach((element) => {
          var questionGPT = Enumerable.from(question).where(p => p.N === element.nome).firstOrDefault();
          if (questionGPT !== null || questionGPT !== undefined) {
           // console.log('questionGPT',questionGPT);
            let gpt: DataGame = {
              game: undefined,
              I: questionGPT?.I,
              N: element?.nome,
              W: questionGPT?.W,
              F: element?.nome,
              M: questionGPT?.M,
              A: questionGPT?.A,
              D: questionGPT?.D,
              T: questionTypesEnum.GPT,
              E: questionGPT?.E,
              V: questionGPT?.V,
              Z: element?.descricao,
              U: questionGPT?.U,
              O: questionGPT?.O
            };
            question.push(gpt);
          }
        });

      }
      */
      //dispatch(questionLoader(question));

      const otherCities: DataGame[] = await jsonService.OtherCities(question);
      dispatch(citiesLoader(otherCities));

      if (question !== null || question !== undefined) {
        dispatch(questionPhoto(question));
      }



    } catch (e) {
      dispatch(errorRequest(e.message));
    }
  };
};

const cargarFotos = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(gameRequest());
    try {
      let question: DataGame[] = await jsonService.QuestionGameService();


      //const question: DataGame[] = [] ;
      question.forEach((element) => {
        element.T = questionTypesEnum.Classic;
      });

/*
      let listadoGPT: DataGame[]  = [];
      if (jsonGPT != null || jsonGPT !== undefined) {
        jsonGPT.forEach((element) => {
          var questionGPT = Enumerable.from(question).where(p => p.N === element.nome).firstOrDefault();
          if (questionGPT !== null || questionGPT !== undefined) {
            console.log('questionGPT',questionGPT);
            let gpt: DataGame = {
              game: undefined,
              I: Number(process.env.REACT_APP_PROVINCE),
              N: element?.descricao,
              W: questionGPT?.W,
              F: element.nome,
              M: questionGPT?.M,
              A: questionGPT?.A,
              D: questionGPT?.N,
              T: questionTypesEnum.GPT,
              E: questionGPT?.E,
              V: questionGPT?.V,
              Z: element?.descricao,
              U: questionGPT?.U,
              O: questionGPT?.O
            };
            listadoGPT.push(gpt);
            
          }
        });

      }

      question.push(...listadoGPT);
       await jsonService.GPTCiudadAdd(listadoGPT);
*/

const gpt: DataGame[] = await gameService.getGPTEstado();
if (gpt === null || gpt?.length === 0) {
  throw new TypeError("No se puede cargar la informacion de la ciudad");
}

      //SEPARAR LA PARTE DE FOTOS
      const photoProvince: DataGame[] = await gameService.getPhotoProvince();
      if (photoProvince === null || photoProvince?.length === 0) {
        throw new TypeError("No se puede cargar las fotos");
      }

      photoProvince.forEach((element) => {
        element.T = questionTypesEnum.Photo;
        element.N =
          process.env.REACT_APP_URL_FOTO + "/" + element.I + "/" + element.N;
      });

      photoProvince.push( ...gpt);
      //TENPO
       //question = [];



      await jsonService.PhotoCiudadAdd(photoProvince);
     
    
      question.push(...photoProvince);
      dispatch(questionPhoto(question));
    } catch (e) {
      dispatch(errorRequest(e.message));
    }
  };
};

const startGame = (
  answered: string[],
  currentQuestion: number,
  todasCiudades: DataGame[],
  preguntasCiudad: DataGame[]
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(gameRequest());
    try {
      const question: DataGame = await jsonService.NextQuestionGameService(
        answered,
        currentQuestion,
        todasCiudades,
        preguntasCiudad
      );
      const quiz: DataGame[] = await jsonService.QuizService(
        answered,
        todasCiudades,
        preguntasCiudad,
        question
      );
      answered.push(question.N);
      currentQuestion++;
      //dispatch(newQuestion(question, answered, currentQuestion, quiz));
    } catch (e) {
      history.push("/gameOver")
      dispatch(errorRequest(e.message));
    }
  };
};

const playGame = (
  answered: string[],
  currentQuestion: number,
  todasCiudades: DataGame[],
  preguntasCiudad: DataGame[]
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(gameRequest());
    try {
      const question: DataGame = await jsonService.NextQuestionGameService(
        answered,
        currentQuestion,
        todasCiudades,
        preguntasCiudad
      );
      const quiz: DataGame[] = await jsonService.QuizService(
        answered,
        todasCiudades,
        preguntasCiudad,
        question
      );
      answered.push(question.N);
      currentQuestion++;
      dispatch(newQuestion(question, answered, currentQuestion, quiz));
    } catch (e) {
      dispatch(PARAR_JUEGO())
      dispatch(errorRequest(e.message));
      history.push("/gameOver");
    }
  };
};
const sucessRanking = (ranking: RankingOnlineResponse[]): GameAction => {
  return {
    type: gameTypesEnum.SET_LOCAL_RANKING,
    ranking: ranking,
  };
};

const setLocalRanking = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(gameRequest());
    try {
      const ranking: any = await gameService.GetRanking();

      dispatch(sucessRanking(ranking));
    } catch (e) {
      dispatch(errorRequest(e.message));
    }
  };
};

const ListCity = () => {
  return async (dispatch: any): Promise<any> => {
    try {
      const question: DataGame[] = await jsonService.QuestionGameService();


      //const question: DataGame[] = [] ;
      question.forEach((element) => {
        element.T = questionTypesEnum.Classic;
      });

      //SEPARAR LA PARTE DE FOTOS
      const photoProvince: DataGame[] = await gameService.getPhotoProvince();
      if (photoProvince == null || photoProvince.length === 0) {
        throw new TypeError("No se puede cargar las fotos");
      }
      photoProvince.forEach((element) => {
        element.T = questionTypesEnum.Photo;
        element.N =
          process.env.REACT_APP_URL_FOTO + "/" + element.I + "/" + element.N;
      });
      question.push(...photoProvince);
      dispatch(questionLoader(question));
    } catch (e) {
      dispatch(errorRequest(e.message));
    }
  };
};

const setRankingOnline = (data: RankingOnlineRequest) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(gameRequest());
    try {
      await gameService.SetRanking(data);
      history.replace("ranking");
    } catch (e) {
      dispatch(errorRequest(e.message));
    }
  };
};

export const gameActions = {
  initialGame,
  playGame,
  startGame,
  timeRun,
  setLocalRanking,
  setRankingOnline,
  ListCity,
  cargarFotos,
  PARAR_JUEGO,
  QuitarIsBusy,
  IsBusy,
  RESET
};
