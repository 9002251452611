import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../_helpers/history";
import { authActions, gameActions } from "../../_redux/actions";
import { useEffect, useRef, useState } from "react";
import styles from "./login.module.scss";
import { useTranslation } from "react-i18next";
import { AuthState, AuthStore, MyUser } from "../../_redux/models/auth.model";
import {
  closeCircle,
  closeCircleOutline,
  closeOutline,
  logoFacebook,
} from "ionicons/icons";
import { App } from "@capacitor/app";
import { appActions } from "../../_redux/actions/app.actions";
import Turtorial from "../../components/Turtorial/Turtorial";
import {
  type AuthenticateOptions,
  BiometricAuth,
  BiometryErrorType,
  BiometryType,
  type CheckBiometryResult,
  getBiometryName,
  type ResultError,
} from "@aparajita/capacitor-biometric-auth";
import { PluginListenerHandle } from "@capacitor/core";
import { gameService } from "../../_redux/services";
import { delay, validarCorreoElectronico } from "../../utils/functions";
import { Keyboard } from "@capacitor/keyboard";

const Login: React.FC<any> = (props) => {
  const notificationService = props.notificationService;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authUser: AuthState = useSelector((state: any) => state.auth);
  const answered = useSelector((state: any) => state.game.answered);
  const currentQuestion = useSelector(
    (state: any) => state.game.currentQuestion
  );

  App.addListener("appStateChange", ({ isActive }) => {
    if (isActive) {
      dispatch(appActions.setAppHabilitarBanner());
    }
  });

  const onKeyPressCode = (e) => {
    e.preventDefault();
    if (loginActive) {
      if (e.key === "Enter") {
        Keyboard.hide();
        acceder();
      }
    }
  };
  const onKeyPressCodeCrearCuenta = (e) => {
    e.preventDefault();
    if (!loginActive) {
      if(e.key === "Enter"){
         Keyboard.hide();
         crearCuenta();
      }
    }
  };

  const onKeyPressCodeNewPassword = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      const inputElement = document.querySelector(
        "input[name='pass1']"
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
    }
  };

  const onKeyPressCodeNewPassword2 = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      const inputElement = document.querySelector(
        "input[name='pass2']"
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
    }
  };

  const loading = useSelector((store: AuthStore) => store.auth.isLoading);

  const myUser = useSelector((store: AuthStore) => store.auth);

  const [loginModal, setLoginModal] = useState(false);
  const [text, setText] = useState<string>();
  const [contrasena, setContrasena] = useState<string>("");
  const [contrasenaConfirmar, setContrasenaConfirmar] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [loginActive, setLoginActive] = useState<boolean>(true);
  const [ayudaPantalla, setAyudaPantalla] = useState<boolean>(false);

  const userData: MyUser = {
    Id: "-1",
    Avatar: "a0.svg",
    Nick: t("guest"),
    NuevoUsuarioEnElJuego: false,
    PaisId: 0,
    Token: "",
    DatosExtendido: null,
    Username: "a0.svg",
    Password: "",
    Auth: false,
    LogrosUsuario: null,
  };

  const handleInvitationLogin = () => {
    dispatch(authActions.loginInvitation(userData));
  };

  const quitarAyuda = () => {
    try {
      setAyudaPantalla(false);
      localStorage.setItem("quitarpantallaAyuda", "true");
    } catch (error) {
      console.log("Al intentar usar localstore en quitarpantallaAyuda ", error);
    }
  };

  useEffect(() => {
    const cargarPantallaAyuda = async () => {
      try {
        const mostrarPantallaAyuda = localStorage.getItem(
          "quitarpantallaAyuda"
        );
        if (mostrarPantallaAyuda === "true") {
          setAyudaPantalla(false);
        } else {
          setAyudaPantalla(true);
        }
        setContrasena("");
      } catch (error) {
        setAyudaPantalla(true);
      }
    };

    cargarPantallaAyuda();

    return () => {
      // Limpieza al desmontar el componente, por ejemplo, remover el listener
      //BiometricAuth.removeAllListeners();
    };
  }, []);

  const updateBiometryInfo = (info: CheckBiometryResult) => {
    console.log("info c", info);
  };
  const acceder = () => {
    dispatch(authActions.biometricoEstado(true));
    if (
      contrasena === null ||
      contrasena?.length <= 4 ||
      contrasena === undefined
    ) {
      dispatch(
        authActions.errorSistema(t("error_you_must_enter_your_password"))
      );
      return;
    }
    dispatch(gameActions.IsBusy());
    userData.Username = text;
    userData.Password = contrasena;
    console.log(userData);
    dispatch(authActions.loginPeke(userData));
    dispatch(gameActions.QuitarIsBusy());
    setLoginModal(false);
    //AdMob.resumeBanner();
  };

  const handleModalLogin = () => {
    setLoginModal(true);
  };

  const crearCuenta = () => {
    if(!validarCorreoElectronico(text)){
      dispatch(
        appActions.showToast({
          message: t("confirm_email_error"),
          type: "danger",
          position: "top",
          icon: "globe",
          duration: 5000,
        })
      );
      return;
    }
    if(contrasena !== contrasenaConfirmar){
      dispatch(
        appActions.showToast({
          message: t("confirm_password_error"),
          type: "danger",
          position: "top",
          icon: "globe",
          duration: 5000,
        })
      );
      return;
    }
    userData.Username = text;
    userData.Password = contrasena;
    dispatch(authActions.crearCuentaPeke(userData));
    setLoginModal(false);
    //AdMob.resumeBanner();
  };

  useEffect(() => {
    const authenticateWithBiometry = async () => {
      await delay(2500);
      const data = gameService.getAccount();
      if (data === null) {
        return;
      }
      if (authUser.biometrico) {
        return;
      }
      dispatch(authActions.biometricoEstado(true));

      if (
        myUser.isAuth &&
        (window.location.pathname.includes("/login") ||
          window.location.pathname.includes("/"))
      ) {
        try {
          const authResult = await BiometricAuth.checkBiometry();
          if (authResult.isAvailable) {
            const options = {
              reason: t("login_reason"),
              cancelTitle: t("login_cancel"),
              iosFallbackTitle: t("login_ios_fallbacktitle"),
              androidTitle: t("login_android_title"),
              androidSubtitle: "",
              allowDeviceCredential: false,
            };

            await BiometricAuth.authenticate(options);
            dispatch(
              appActions.showToast({
                message: t("login_sucess"),
                type: "success",
                position: "top",
                icon: "globe",
                duration: 1500,
              })
            );

            history.replace("/dashboard");
          }
        } catch (error) {
          dispatch(
            appActions.showToast({
              message: t("timet_out"),
              type: "danger",
              position: "top",
              icon: "globe",
              duration: 600,
            })
          );
        }
      }
    };

    authenticateWithBiometry();
  }, [dispatch, myUser, authUser, t]);

  return (
    <IonPage id="login-page">
      <IonContent className="bg_app_content_login">
        {/* {ayudaPantalla && <Turtorial evento={quitarAyuda}></Turtorial>} */}
        <div className="divNombreApp">
          <h1 className="texto_titulo_efecto">{t("name_app")}</h1>
          <h2>{t("description_app")}</h2>
        </div>

        <div className={styles.login_buttons_group}>
          <IonButton
            shape="round"
            className="btn_sty"
            color="primary"
            onClick={handleModalLogin}
          >
            {t("login_as_user")}
          </IonButton>

          <IonButton
            shape="round"
            className="btn_sty"
            color="tertiary"
            onClick={handleInvitationLogin}
          >
            {t("guest_access")}
          </IonButton>
        </div>
        <IonLoading
          isOpen={loading}
          message={t("msg_loading")}
          duration={1000}
        />

        <IonModal
          isOpen={loginModal}
          backdropDismiss={true}
          onDidDismiss={() => setLoginModal(false)}
          cssClass={styles.mini}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setLoginModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle>{t("login")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <img
              alt="Logo Peke Games"
              className={styles.login_logo}
              src="/assets/icon/logo_pk.png"
            ></img>
            <IonSegment
              value={loginActive ? "Login" : "Register"}
              onIonChange={(e) => {
                e.detail.value === "Login"
                  ? setLoginActive(true)
                  : setLoginActive(false);
              }}
            >
              <IonSegmentButton value="Login">
                <IonLabel>{t("login")}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="Register">
                <IonLabel>{t("register")}</IonLabel>
              </IonSegmentButton>
            </IonSegment>

            <IonItem>
              <IonLabel color="secondary" position="floating">
                {t("enter_your_email_here")}
              </IonLabel>
              <IonInput
                value={text}
                onIonChange={(e) => setText(e.detail.value!)}
                clearInput
                name="campologin"
                enterkeyhint={"next"}
                onKeyUp={onKeyPressCodeNewPassword}
                autofocus
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel color="secondary" position="floating">
                {t("enter_your_password_here")}
              </IonLabel>
              <IonInput
                type="password"
                id="pass1"
                name="pass1"
                value={contrasena}
                placeholder="********"
                onIonChange={(e) => setContrasena(e.detail.value!)}
                //onClick={(i) => setContrasena(i.detail.toString()!)}
                clearInput
                enterkeyhint={loginActive ? "done" : "next"}
                onKeyUp={
                  loginActive ? onKeyPressCode : onKeyPressCodeNewPassword2
                }
              ></IonInput>
            </IonItem>

            {loginActive === true ? (
              ""
            ) : (
              <IonItem>
                <IonLabel color="secondary" position="floating">
                  {t("confirm_password")}
                </IonLabel>
                <IonInput
                  type="password"
                  id="pass2"
                  name="pass2"
                  value={contrasenaConfirmar}
                  placeholder="********"
                  onIonChange={(e) => setContrasenaConfirmar(e.detail.value!)}
                  clearInput
                  enterkeyhint="done"
                  onKeyUp={onKeyPressCodeCrearCuenta}
                ></IonInput>
              </IonItem>
            )}

            {loginActive === true ? (
              <a
                href="https://www.pekegames.app/en/Account/ForgotPassword"
                target="_black"
                className={styles.recuperar_contrasenia}
              >
                {t("recover_password")}
              </a>
            ) : (
              <div className={styles.salto_linea}></div>
            )}

            {loginActive === true ? (
              <IonButton
                shape="round"
                className="btn_sty"
                color="primary"
                onClick={acceder}
              >
                {t("enter")}
              </IonButton>
            ) : (
              <IonButton
                shape="round"
                className="btn_sty"
                color="primary"
                onClick={crearCuenta}
              >
                {t("create_account_button")}
              </IonButton>
            )}
          </IonContent>
        </IonModal>
        {ayudaPantalla && <Turtorial evento={quitarAyuda}></Turtorial>}
      </IonContent>
    </IonPage>
  );
};

export default Login;
