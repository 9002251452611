import { IonIcon } from "@ionic/react";
import {
  logoTwitter,
  logoYoutube,
  logoFacebook,
  logoInstagram,
  globe,
} from "ionicons/icons";
import React from "react";
export const SocialRed: React.FC<any> = () => {
  const socialAbrir = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <div className="social_rede_pk">
      <IonIcon
        icon={logoTwitter}
        onClick={(p) => socialAbrir("https://twitter.com/PekeGames")}
        className="icon_twiter"
      />
      <IonIcon
        icon={logoYoutube}
        onClick={(p) =>
          socialAbrir(
            "https://www.youtube.com/channel/UCGUe2P_-tURFmAtEHvpUGoQ"
          )
        }
        className="icon_youtube"
      />
      <IonIcon
        icon={logoFacebook}
        onClick={(p) =>
          socialAbrir("https://www.facebook.com/Peke-Games-458366370906329/")
        }
        className="icon_facebook"
      />
      <IonIcon
        icon={logoInstagram}
        onClick={(p) => socialAbrir("")}
        className="icon_instagram hide"
      />
      <IonIcon
        icon={globe}
        onClick={(p) => socialAbrir("https://www.pekegames.app/")}
        className="icon_instagram hide"
      />
    </div>
  );
};

export default SocialRed;
