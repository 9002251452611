import { Share } from "@capacitor/share";
import { IonButton, IonCard, IonCardContent, IonCardTitle } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../_redux/actions/app.actions";
import { LogroState } from "../../_redux/models/logro.models";

export const CardApp: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const logros: LogroState = useSelector((state: any) => state.logro);
  const shareHander = async (item: any) => {
    try {
      if (!logros?.logro?.find((x) => x.LogroId === 22)?.TieneLogro) {
        dispatch(appActions.showLogro(22));
      }

      let titleApp = item.name;
      titleApp += " : " + t("share_title");
      await Share.share({
        title: titleApp,
        text: item.DescripcionCorta,
        url: "https://www.pekegames.app/home/App/" + item.Slug,
        dialogTitle: t("share_title"),
      });
    } catch (error) {}
  };

  return (
    <IonCard className="ionCard" key={props.index}>
      <IonCardTitle>{props.data.Nombre}</IonCardTitle>
      <img
        src={`https://www.pekegames.app/Img/App/${props.data.Id}/logo512.png`}
        alt={props.data.name}
      ></img>
      <IonCardContent>{props.data.DescripcionCorta}</IonCardContent>

      <IonButton
        fill="outline"
        color="primary"
        target="_blank"
        size="large"
        href={`https://www.pekegames.app/home/App/${props.data.Slug}`}
      >
        {t("botton_open_app_web")}
      </IonButton>
      <IonButton
        fill="outline"
        size="large"
        color="success"
        onClick={() => shareHander(props.data)}
      >
        {t("share")}
      </IonButton>
    </IonCard>
  );
};

export default CardApp;
