import React, { useState, useEffect } from "react";
import Media from 'react-media';
import { AppState, AppStore } from "../../_redux/models/app.model";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../_redux/actions/app.actions";

const BannerLocal: React.FC = () => {
  const [currentAd, setCurrentAd] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const appConfig: AppState = useSelector((store: AppStore) => store.app);
  const dispatch = useDispatch();
  const inicioBanner = [
    {
      title: "",
      text: "",
      image: "https://www.pekegames.app/Img/App/Banner/3/468_60_cafe.jpg",
      url: "https://www.buymeacoffee.com/pekegames",
    },
    {
      title: "A little coffee",
      text: "It is a way of collaborating with us. Your help is very important.",
      image: "",
      url: "https://www.buymeacoffee.com/pekegames",
    },
    {
      title: "",
      text: "",
      image: "https://www.pekegames.app/Img/App/Banner/468_60_brasi.png",
      url: "https://brasi.eu",
    },
    {
      title: "Tienda de Lencería",
      text: "¡Descubre las mejores ofertas!",
      image: "",
      url: "https://brasi.eu",
    }
  ];
  const [ads, setAds] = useState(inicioBanner);

  useEffect(() => {
    setIsMounted(true);
    const fetchData = async () => {
      try {
        // Llamada al servicio para obtener los datos de ads
        console.log("banner", appConfig);
        // Llamada al servicio para obtener los datos de ads
        if (appConfig.banner1.length > 0) {
          console.log("banner1 entro",appConfig.banner1);
          setAds(appConfig.banner1);
          return;
        }
        const response = await fetch(process.env.REACT_APP_SERVICE + "/api/pekegames/banner?formatoId=3&appId="+process.env.REACT_APP_MIAPPID+"&language="+localStorage.getItem('i18nextLng'));

        const data = await response.json();
        if(data.length>0) {
          setAds(data);
          dispatch(appActions.setBanner1(data));
        }
      } catch (error) {
        console.error("Error al obtener los datos de ads:", error);
      }
    };

    fetchData();
    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isMounted) {
      setCurrentAd((prevAd) => (prevAd + 1) % ads.length);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [ads,isMounted]);

  const abrirBannerWeb = async (item: any) => {
    if (isMounted && item.url.length > 1) {
      window.open(item.url, "_blank");
    }
  };

  if (!isMounted) {
    return null;
  }

  return (
    <div className="bannerlocal" onClick={() => abrirBannerWeb(ads[currentAd])}>
      <div className="centrarBanner">
      {ads[currentAd].image.length > 1 ? (
        <img src={ads[currentAd].image} alt={ads[currentAd].title} />
      ) : (
        <>
          {ads[currentAd].title.length > 1 && <h1>{ads[currentAd].title}</h1>}

          {ads[currentAd].text}
        </>
      )}
      </div>
    </div>
  );
};

export default BannerLocal;
