import axios from 'axios';
const https = require('https');

const httpClientPk = axios.create({
  
  baseURL: 'https://www.pekegames.app',
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

const httpClientYelp = axios.create({
  
  baseURL: 'https://api.yelp.com/',
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

export  { httpClientPk , httpClientYelp}