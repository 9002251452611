import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { AuthStore } from "../../_redux/models/auth.model";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { gameActions } from "../../_redux/actions";
import { useEffect, useState } from "react";
import styles from "./photo.module.scss";
import InfiniteScroll from "react-infinite-scroller";

import {
  apps,
  close,
  closeCircle,
  closeOutline,
  ellipsisHorizontal,
  ellipsisVertical,
  fileTray,
  images,
  mail,
  map,
  refreshCircleSharp,
  settings,
} from "ionicons/icons";
import { history } from "../../_helpers/history";
import MenuLateral from "../../components/Menu/MenuLateral";
import { DataGame, GameState } from "../../_redux/models/game.model";
import Enumerable from "linq";
import { addSeconds, delay, MenuApp } from "../../utils/functions";
import { Icon } from "@material-ui/core";
import Pagination from "../../components/Base/Pagination";
import { take } from "rxjs";
import AdsLateralPropio from "../../components/Ads/AdsLateralPropio";
import BannerLocalLateralGrande from "../../components/Ads/BannerLocalLateralGrande";
import Media from "react-media";
import { appActions } from "../../_redux/actions/app.actions";
import { LogroState } from "../../_redux/models/logro.models";

const Photo: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const myUser = useSelector((store: AuthStore) => store.auth.user);
  const logros: LogroState = useSelector((state: any) => state.logro);
  const [showModal, setShowModal] = useState(false);
  const [urlWiki, setUrlWiki] = useState("");
  const [present, dismiss] = useIonActionSheet();
  const [searchText, setSearchText] = useState("");
  const [listcity, setListcity] = useState<DataGame[]>(null);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const registroPorPagina = 5;
  let ms = Date.now();


  const handlePrevPage = (prevPage: number) => {
    try {
      setPage((prevPage) => prevPage - 1);
    let saltoPagina = page * registroPorPagina;
    const response = Enumerable.from(gameGlobal.provinceCities)
      .where((p) => p.T === "photo")
      .skip(saltoPagina)
      .take(registroPorPagina)
      .toArray();
      setListcity(prevItems => [...prevItems, ...response]);
    } catch (error) {
      alert(error);
    }
    
  };

  const handleNextPage = async (nextPage: number) => {
    try {
      await delay(1000);
      let auxDate = new Date(Date.now());
      if(ms > addSeconds(auxDate,-1)){
        return null;
      }
      console.log(nextPage, "cambio de pagina");
      setPage(nextPage+1);
      let saltoPagina = page * registroPorPagina;
      const response = Enumerable.from(gameGlobal.provinceCities)
        .where((p) => p.T === "photo")
        .skip(saltoPagina)
        .take(registroPorPagina)
        .toArray();
      //setListcity(response);
      

      console.log('previos',listcity);
      //setListcity(prevItems => [...prevItems, ...response]);
      console.log('depois',response);
      setListcity(listcity.concat(response));
     
      ms = Date.now();
    } catch (error) {
      alert(error);
    }

  };

  useEffect(() => {
    const fetchData = async () => {
      let saltoPagina = page * registroPorPagina - registroPorPagina;
      const response = Enumerable.from(gameGlobal.provinceCities)
        .where((p) => p.T === "photo")
        .skip(saltoPagina)
        .take(registroPorPagina)
        .orderBy(p=>p.D)
        .toArray();
      setListcity(response);

      let totalpagina =
        Enumerable.from(gameGlobal.provinceCities)
          .where((p) => p.T === "photo")
          .count() / registroPorPagina;
      if (totalpagina % 1 !== 0 && totalpagina > 1) {
        totalpagina++;
      }
      setTotalPages(parseInt(totalpagina.toFixed(0))-1);
    };

    fetchData();
  }, []);

  const abrirModal = async (data: DataGame, triadvisor: boolean) => {

    
    setUrlWiki(triadvisor === true ? data.V : data.W);
    setShowModal(true);

    if (!logros?.logro?.find((x) => x.LogroId === 73)?.TieneLogro)
{
    dispatch(appActions.showLogro(73));
}
  };

  useEffect(() => {
    //dispatch(gameActions.ListCity);
    // setListcity(gameGlobal.provinceCities);
  }, []);

  const buscador = async (data: string) => {
    setSearchText(data);
    setListcity(
      Enumerable.from(gameGlobal.provinceCities)
        .where(
          (p) =>
            p.T === "photo" &&
            (p.D.toLowerCase().includes(data.toLowerCase()) ||
              p.F.toLowerCase().includes(data.toLowerCase()))
        )
        .toArray()
    );
  };
  const gameGlobal: GameState = useSelector((state: any) => state.game);
  return (
    <IonPage id="page-photo">
      <IonHeader>
        <IonToolbar className="menu_movil_2">
          {MenuApp(present, t)}
          <IonTitle>{t("tourist_points")}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="bg_app_content_photo">
        <IonRow>
          <IonCol size="12" size-md="3" className="lateral hide_pc">
            <MenuLateral className="hide_pc"></MenuLateral>
          </IonCol>
          <IonCol size="12" size-md="7" className="centro_pagina_photo">
            <IonSearchbar
              placeholder={t("search")}
              value={searchText}
              onIonChange={(e) => buscador(e.detail.value!)}
            ></IonSearchbar>
            <IonContent>

            <div className={styles.caja_scroll}>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={handleNextPage}
                  hasMore={page < totalPages ? true : false}
                  useWindow={false}
                  loader={
                    <div className={styles.loader} key={0}>
                     <IonIcon icon={refreshCircleSharp} color='warning' size="medium" ></IonIcon> {t("msg_loading")}
                    </div>
                  }
                >
                  {" "}
                  {Array.isArray(listcity) &&
                    listcity.map((item, index) => {
                      if (!item.N.includes("https")) {
                        return;
                      }
                      return (
                        <IonCard className="ionCard" key={index}>
                          <div className="skeleton">
                            <img src={item?.N} alt={item.A} />
                          </div>
                          <div className="card_autor">
                            {item.A} -{" "}
                            <a
                              href="https://creativecommons.org/licenses/by-sa/3.0/"
                              target="_blank"
                            >
                              CC BY-SA 3.0
                            </a>
                          </div>
                          <IonCardHeader>
                            <IonCardTitle>{item.D}</IonCardTitle>
                            <IonCardSubtitle>{item.F}</IonCardSubtitle>
                          </IonCardHeader>

                          <IonCardContent>{item?.Z}</IonCardContent>

                          <div className="botones">
                            {item.W !== "" && item.W !== null && (
                              <IonButton
                                color="success"
                                fill="outline"
                                onClick={() => abrirModal(item, false)}
                              >
                                {t("information_wikipedia")}
                              </IonButton>
                            )}

                            {item.V !== "" && item.V !== null && (
                              <IonButton
                                fill="outline"
                                color="secondary"
                                onClick={() => abrirModal(item, true)}
                              >
                                {t("informcion_triadvisor")}
                              </IonButton>
                            )}

                            <p className="hidden"></p>
                          </div>
                        </IonCard>
                      );
                    })}
                </InfiniteScroll>
              </div>
              {/* <div className={styles.caja_scroll}>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={handleNextPage}
                  hasMore={page < totalPages ? true : false}
                  useWindow={false}
                  loader={
                    <div className="loader" key={0}>
                      Loading ...
                    </div>
                  }
                >
                  {" "}
                  {Array.isArray(listcity) &&
                    listcity.map((item, index) => {
                      if (!item.N.includes("https")) {
                        return;
                      }
                      return (
                        <IonCard className="ionCard" key={index}>
                          <div className="skeleton">
                            <img src={item?.N} alt={item.A} />
                          </div>
                          <div className="card_autor">
                            {item.A} -{" "}
                            <a
                              href="https://creativecommons.org/licenses/by-sa/3.0/"
                              target="_blank"
                            >
                              CC BY-SA 3.0
                            </a>
                          </div>
                          <IonCardHeader>
                            <IonCardTitle>{item.D}</IonCardTitle>
                            <IonCardSubtitle>{item.F}</IonCardSubtitle>
                          </IonCardHeader>

                          <IonCardContent>{item?.Z}</IonCardContent>

                          <div className="botones">
                            {item.W !== "" && item.W !== null && (
                              <IonButton
                                color="success"
                                fill="outline"
                                onClick={() => abrirModal(item, false)}
                              >
                                {t("information_wikipedia")}
                              </IonButton>
                            )}

                            {item.V !== "" && item.V !== null && (
                              <IonButton
                                fill="outline"
                                color="secondary"
                                onClick={() => abrirModal(item, true)}
                              >
                                {t("informcion_triadvisor")}
                              </IonButton>
                            )}

                            <p className="hidden"></p>
                          </div>
                        </IonCard>
                      );
                    })}
                </InfiniteScroll>
              </div> */}

              {/*       {Array.isArray(listcity) &&
                listcity.map((item, index) => {
                  if (!item.N.includes("https")) {
                    return;
                  }
                  return (
                    <IonCard className="ionCard" key={index}>
                      <div className="skeleton">
                        <img src={item?.N} alt={item.A} />
                      </div>
                      <div className="card_autor">
                        {item.A} -{" "}
                        <a
                          href="https://creativecommons.org/licenses/by-sa/3.0/"
                          target="_blank"
                        >
                          CC BY-SA 3.0
                        </a>
                      </div>
                      <IonCardHeader>
                        <IonCardTitle>{item.D}</IonCardTitle>
                        <IonCardSubtitle>{item.F}</IonCardSubtitle>
                      </IonCardHeader>

                      <IonCardContent>{item?.Z}</IonCardContent>

                      <div className="botones">
                        {item.W !== "" && item.W !== null && (
                          <IonButton
                            color="success"
                            fill="outline"
                            onClick={() => abrirModal(item, false)}
                          >
                            {t("information_wikipedia")}
                          </IonButton>
                        )}

                        {item.V !== "" && item.V !== null && (
                          <IonButton
                            fill="outline"
                            color="secondary"
                            onClick={() => abrirModal(item, true)}
                          >
                            {t("informcion_triadvisor")}
                          </IonButton>
                        )}

                        <p className="hidden"></p>
                      </div>
                    </IonCard>
                  );
                })} */}

              {(listcity?.length === 0 || listcity === null) && (
                <>
                  <div className="noDataContainer">
                    <a href="mailto:hello@pekegames.app?subject={t('tourist_points_no_data')}&amp;body={searchText}">
                      <IonIcon
                        className="iconGrande"
                        icon={fileTray}
                        color="primary"
                      ></IonIcon>
                    </a>
                    <h3>
                      <span className="color_error">{searchText}</span>.{" "}
                      {t("tourist_points_no_data_title")}
                    </h3>
                    <p>
                      <a href="mailto:hello@pekegames.app?subject={t('tourist_points_no_data')}&amp;body={searchText}">
                        {t("tourist_points_no_data")}
                      </a>
                    </p>
                  </div>
                </>
              )}

              {totalPages === 99999999  && (
                <Pagination
                  totalPages={totalPages}
                  currentPage={page}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                />
              )}

              <div className="margin_buttom_banner"></div>
            </IonContent>
          </IonCol>
          <IonCol size="12" size-md="2" className="hide_pc">
          <Media
              query="(min-width: 600px)"
              render={() => (
                <BannerLocalLateralGrande></BannerLocalLateralGrande>
              )}
            />
          </IonCol>
        </IonRow>

        <IonModal
          isOpen={showModal}
          onDidDismiss={() => setShowModal(false)}
          cssClass="fullscreen"
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle>Web</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <iframe id="mymodal" src={urlWiki} className="iframeStyle"></iframe>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Photo;
