import { authTypes } from "../types";
import { gameService } from "../services";
import { AuthAction, MyUser } from "../models/auth.model";
import { history } from "../../_helpers/history";
import { filtrarMeuDatos } from "../../utils/functions";
import { appActions } from "./app.actions";

const authRequest = (): AuthAction => {
  return {
    type: authTypes.REQUEST,
  };
};

const authSucess = (user: MyUser): AuthAction => {
  return {
    type: authTypes.AUTH_SUCCESS,
    user: user,
  };
};

const biometricoEstado = (estado: boolean): AuthAction => {
  return {
    type: authTypes.BIOMETRICO,
    biometrico: estado,
  };
};

const errorRequest = (error: string): AuthAction => {
  return {
    type: authTypes.FAILURE,
    error: error,
  };
};

const errorSistema = (error: string): AuthAction => {
  return {
    type: authTypes.ERROR,
    error: error,
  };
};

const authInvitation = (user: MyUser): AuthAction => {
  return {
    type: authTypes.AUTH_INVITATION,
    user: user,
  };
};


const loginInvitation = (userData: MyUser) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      const user: MyUser = {
        Id: "-1",
        Avatar: "a1.jpg",
        Nick: userData.Nick,
        NuevoUsuarioEnElJuego: false,
        PaisId: -1,
        Token: "",
        DatosExtendido: {
          Id: -1,
          UsuarioId: "-1",
          MiAppId: -1,
          Punto: 0,
          RachaEnDia: 0,
          Ayuda50: 0,
          AyudaPreguntaCorrecta: 0,
          AyudaContestar2Veces: 0,
          AyudaMasTiempo: 0,
          RecuperarPuntosPerdido: 0,
          RecuperarVida: 0,
          RecuperarRacha: 0,
          Vida: 0,
          NumeroPartida: 0,
          MejorPosicion: 0,
          MejorPuntuacion: 0,
          MejorNivel: 0,
          PerfilPrivado: false,
          Aciertos: 0,
          Errores: 0,
          TipoPlataforma: 3,
          FormatoApp: 3,
          Creado: null,
          FechaUltimoAcceso: null,
          MonedaGlobal: 0,
          PuntosGlobales: 0,
        },
        Username: "Invitado",
        Password: "",
        Auth: false,
        LogrosUsuario: null
      };

      dispatch(authInvitation(user));
      localStorage.setItem("ctpk", filtrarMeuDatos(JSON.stringify(user)));
      history.push("/dashboard");
    } catch (e) {
      dispatch(
        appActions.showToast({
          message: "We are very sorry, it seems that we are experiencing connection problems at the moment. Please try again later",
          type: "danger",
          position: "top",
        })
      );
      console.log(e.SecurityError);
      dispatch(errorRequest(e.message));
      if (e.name === "SecurityError") {
        history.push("/dashboard");
      }
    }
  };
};

const loginInvitationArrancarApp = (user: MyUser) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      dispatch(authInvitation(user));
      localStorage.setItem("ctpk", filtrarMeuDatos(JSON.stringify(user)));
    } catch (e) {
      console.log("error en loginInvitationArrancarApp ");
      dispatch(errorRequest("loginInvitationArrancarApp:" + e.message));
    }
  };
};

const loginPeke = (login: MyUser) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      const user = await gameService.Login(login);
      if (user === null || user?.Id === null) {
        throw new TypeError("We are very sorry, it seems that we are experiencing connection problems at the moment. Please try again later");
      }
      dispatch(authSucess(user));
      history.push("/dashboard");
    } catch (error) {
      console.log("error en loginPeke");
      dispatch(
        appActions.showToast({
          message: error.message,
          type: "danger",
          position: "top",
        })
      );
    }
  };
};

const loginPekeSinNavegacion = (login: MyUser) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      const user = await gameService.Login(login);
      if (user === null) {
        dispatch(
          appActions.showToast({
            message: "We are very sorry, it seems that we are experiencing connection problems at the moment. Please try again later",
            type: "danger",
            position: "top",
          })
        );
        throw new TypeError("No es posible se conectar al servidor");
      }
      dispatch(authSucess(user));
    } catch (error) {
      console.log("error en loginPekeSinNavegacion");
      dispatch(
        appActions.showToast({
          message: "No es posible se conectar" + error.message,
          type: "danger",
          position: "top",
          icon: "globe",
        })
      );

      dispatch(errorRequest(error.message));
    }
  };
};

const cargarDatosSinNavegacion = (user: MyUser) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      dispatch(authSucess(user));
    } catch (error) {
      console.log("error en cargarDatosSinNavegacion");
      dispatch(errorRequest("cargarDatosSinNavegacion:" + error.message));
      dispatch(
        appActions.showToast({
          message: "No es posible se conectar" + error.message,
          type: "danger",
          position: "top",
          icon: "globe",
        })
      );
    }
  };
};

const crearCuentaPeke = (login: MyUser) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      const user: MyUser = await gameService.CrearCuenta(login);

      dispatch(authSucess(user));
      history.push("/dashboard");
    } catch (e) {
      console.log("error en crearCuentaPeke");
      dispatch(errorRequest(e.message));
      dispatch(
        appActions.showToast({
          message: "No fue posible crear la cuenta" + e.message,
          type: "danger",
          position: "top",
          icon: "globe",
        })
      );
    }
  };
};

const crearCuentaPekeSinNavegar = (login: MyUser) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      const user: MyUser = await gameService.CrearCuenta(login);

      dispatch(authSucess(user));
    } catch (e) {
      console.log("error en crearCuentaPekeSinNavegar");
      dispatch(errorRequest(e.message));
      dispatch(
        appActions.showToast({
          message: "No fue posible crear la cuenta" + e.message,
          type: "danger",
          position: "top",
          icon: "globe",
        })
      );
    }
  };
};

const checkAuth = () => {
  return async (dispatch: any) => {
    dispatch(authRequest());
    try {
      const myuser: MyUser = await gameService.checkAuth();
      dispatch(authSucess(myuser));
      //history.replace("/dashboard");
    } catch (error) {
      dispatch(errorRequest(null));
    }
  };
};

const logout = (): AuthAction => {
  localStorage.removeItem("ctpk");
  localStorage.removeItem("jogo");
  history.replace("/");
  return {
    type: authTypes.LOGOUT,
  };
};

const ayuda_actualizar = (user: MyUser): AuthAction => {
  return {
    type: authTypes.AYUDA_ACTUALIZAR,
    user: user,
  };
};

const vida_inicial = (): AuthAction => {
  return {
    type: authTypes.VIDA_INICIAL
  };
};

export const authActions = {
  loginInvitation,
  loginPeke,
  crearCuentaPeke,
  checkAuth,
  logout,
  loginInvitationArrancarApp,
  authInvitation,
  cargarDatosSinNavegacion,
  loginPekeSinNavegacion,
  errorSistema,
  authSucess,
  crearCuentaPekeSinNavegar,
  ayuda_actualizar,
  vida_inicial,
  biometricoEstado
};
