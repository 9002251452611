import {
  IonButtons,
  IonCol,
  IonContent,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../_helpers/history";
import { authActions, logroActions } from "../../_redux/actions";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MenuApp } from "../../utils/functions";
import {
  settings,
  storefrontSharp,
  contrastOutline,
} from "ionicons/icons";
import { MenuLateral } from "../../components/Menu/MenuLateral";
import { configData } from "../../data/configData";
import { appActions } from "../../_redux/actions/app.actions";
import { AuthState } from "../../_redux/models/auth.model";
import styles from "./dashboard.module.scss";
import { AdMob } from "@capacitor-community/admob";
import { AppState, AppStore } from "../../_redux/models/app.model";
import listadoLogros from "../../data/logro.json";

const Dashboard: React.FC<any> = (props) => {

  const authUser: AuthState = useSelector((state: any) => state.auth);
  const appConfig: AppState = useSelector((store: AppStore) => store.app);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [present, dismiss] = useIonActionSheet();
  const handleProfile = () => {
    history.push("profile");
  };

  useIonViewDidEnter(() => {
    if (appConfig.isPrepareBanner) {
      AdMob.resumeBanner();
    }
  });
  const modoOscuro = () => {
    try {
      if (localStorage.getItem("appTheme") === "") {
        dispatch(appActions.setAppTheme("dark"));
      } else {
        dispatch(appActions.setAppTheme(""));
      }
    } catch (error) {
      dispatch(appActions.setAppTheme(""));
    }
  };

  useEffect(() => {
    listadoLogros.map((logro) => {
      logro.Description = t(logro.Description);
      logro.DescriptionGanoLogro = t(logro.DescriptionGanoLogro);
      logro.Name = t(logro.Name);
    });
    
    {authUser?.isAuth  && Array.isArray(authUser?.user?.LogrosUsuario) && authUser?.user?.LogrosUsuario.map((logroItem) => {
      listadoLogros.map((logro) => {
        if (logroItem.CodigoLogro === logro.LogroId) {
          logro.TieneLogro = true;
        }
      });
    })}
    dispatch(logroActions.setLogro(listadoLogros));
    if(authUser?.user?.DatosExtendido?.Vida<=0){
      dispatch(authActions.vida_inicial());
    }
  }, [authUser, dispatch, t]);

  const handleContinue = () => {};
  return (
    <IonPage id="page-dashboard" className="page-dashboard">
      <IonHeader>
        <IonToolbar className="menu_movil_home">
          <IonButtons slot="start">
            {authUser?.isAuth && (
              <img
                onClick={handleProfile}
                className={styles.icon_profile}
                alt="Avatar"
                src={`https://www.pekegames.app/img/avatar/${authUser?.user?.Avatar}`}
              ></img>
            )}

            {!authUser?.isAuth && (
              <img
                onClick={handleProfile}
                className={styles.icon_profile}
                alt="Avatar"
                src={`https://www.pekegames.app/img/avatar/a0.svg`}
              ></img>
            )}
          </IonButtons>
          {MenuApp(present, t)}
          <IonTitle>{configData.provincia_o_estado}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="">
        <IonRow>
          <IonCol size="12" size-md="3" className="lateral">
            <MenuLateral></MenuLateral>
          </IonCol>
          <IonCol size="12" size-md="9" className="centro_pagina hide_pc">
            <IonFabButton
              size="small"
              onClick={handleProfile}
              className="icon_settings"
              color="light"
            >
              <IonIcon color="medium" icon={settings} />
            </IonFabButton>
            <IonFabButton
              size="small"
              onClick={modoOscuro}
              className="icon_settings "
              color="dark"
            >
              <IonIcon icon={contrastOutline} />
            </IonFabButton>
            <IonFabButton
              size="small"
              routerLink="store"
              className="icon_settings"
              color="warning"
            >
              <IonIcon color="danger" icon={storefrontSharp} />
            </IonFabButton>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;

