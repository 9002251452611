import { languageTypesEnum } from "../types";
import {
  LanguageState,
  LanguageAction,
} from "../models/language.model";

const initialState = {
  error: null,
  isLoading: false,
  language: null,
  languages: [],
};

export const languageReducer = (
  state: LanguageState = initialState,
  action: LanguageAction
): any => {
  switch (action.type) {
    case languageTypesEnum.REQUEST:
      return {
        ...state,
        ...{ error: null, isLoading: true },
      };
    case languageTypesEnum.FAILURE:
      return {
        ...state,
        ...{ error: action.error , isLoading: true },
      };
      case languageTypesEnum.SUCCESS:
      return {
        ...state,
        ...{
          isLoading: false,
          error: null,
          language :'definir',
          languages: action.languages,
        },
      };
    default:
      return state;
  }
};
