import {
    IonButton,
    IonCol,
    IonContent,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonModal,
    IonPage,
    IonProgressBar,
    IonRow,
    IonText,
    IonThumbnail,
    IonTitle,
    IonToast,
    IonToolbar,
    useIonViewDidEnter,
    useIonViewDidLeave,
    useIonViewWillEnter,
    useIonViewWillLeave,
    withIonLifeCycle,
  } from "@ionic/react";
  import styles from "./correcto.module.scss";
  import {
    AdMob,
    BannerAdOptions,
    BannerAdSize,
    BannerAdPosition,
    BannerAdPluginEvents,
    AdMobBannerSize,
  } from "@capacitor-community/admob";
  import { useEffect, useRef } from "react";
  import { languageActions } from "../../_redux/actions";
  import { useDispatch, useSelector } from "react-redux";
  import { Line, Circle } from "rc-progress";
  import { stockData } from "../../data/stockData";
  import Enumerable from "linq";
  import { gameActions } from "../../_redux/actions/game.actions";
  import { partidaActions } from "../../_redux/actions/partida.actions";
  import {
    DataGame,
    GameState,
    RankingOnlineRequest,
  } from "../../_redux/models/game.model";
  import Botonera from "../../components/Botonera/Botonera";
  import { history } from "../../_helpers/history";
  import { CountdownCircleTimer } from "react-countdown-circle-timer";
  import { gameTypesEnum } from "../../_redux/types";
  import { questionTypesEnum } from "../../_redux/types/question.enum";
  import AdSense from "react-adsense";
  import { globe, help, image, notifications, person } from "ionicons/icons";
  import { Modal } from "../../components/Modal/Modal";
  import { useTranslation } from "react-i18next";
  import useState from "react-usestateref";
  import { AuthState } from "../../_redux/models/auth.model";
  import { gameService } from "../../_redux/services";
  import { delay, OperacionSaveR } from "../../utils/functions";
  import { PartidaState } from "../../_redux/models/partida.model";
import ConfettiExplosion from "@reonomy/react-confetti-explosion";
import { Button } from "@material-ui/core";
  const Correcto: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const authUser: AuthState = useSelector((state: any) => state.auth);
    const partida : PartidaState = useSelector((state: any) => state.partida);
    const [isExploding, setIsExploding] = useState(false);

    const handleClick = async () => {
      
        await delay(3000);
        setIsExploding(false);
        history.replace("quiz");
      };

    useEffect(() => {
        
        handleClick();
        setIsExploding(true);
    }, []);
  
    return (
      <IonPage>
        <IonContent>
          <IonRow>
            <IonCol size="12" size-md="9" className={styles.centro_pagina_quiz}>
             
                <div className={styles.container_center_item}>
                    
                         {isExploding ? <ConfettiExplosion  duration={4000} floorWidth={1000}  floorHeight={600} force={0.6} particleCount={150} /> :''}
                </div>
                   <h1>A Coruña es la respuesta correcta</h1>
                   <Button onClick={handleClick}>Eliminar efecto</Button>
                </IonCol>
  
            <IonCol size="12" size-md="3" className={styles.lateral_quiz}>
              <h2>Publicidad aqui, quiero mucha pasta</h2>
              
            </IonCol>
          </IonRow>
  
    
        </IonContent>
      </IonPage>
    );
  };
  
  export default Correcto;
  
  
  