import { gameTypesEnum } from "../types";
import { DataGame, GameState, GameAction } from "../models/game.model";
import { questionTypesEnum } from "../types/question.enum";

const initialState = {
  error: null,
  isLoading: false,
  provinceCities: [],
  cities: [],
  currentQuestion: 0,
  numberOfQuestions: 0,
  gameStart: false,
  point: 0,
  answered: [],
  quiz: [],
  time: 100,
  t: questionTypesEnum.Photo,
  errores: 0,
  aciertos: 0,
  tiempoControl: 0,
};

export const gameReducer = (
  state: GameState = initialState,
  action: GameAction
): any => {
  switch (action.type) {
    case gameTypesEnum.REQUEST:
      return {
        ...state,
        ...{ error: null, isLoading: true },
      };
    case gameTypesEnum.QUITAR_ISBusy:
      return {
        ...state,
        ...{ error: null, isLoading: false },
      };
    case gameTypesEnum.IS_BUSY:
      return {
        ...state,
        ...{ error: null, isLoading: true },
      };
    case gameTypesEnum.PROVINCE:
      return {
        ...state,
        ...{ error: null, province: action.province },
      };
    case gameTypesEnum.LOAD_QUESTION:
      return {
        ...state,
        ...{
          error: null,
          numberOfQuestions: action.numberOfQuestions,
          provinceCities: action.provinceCities,
        },
      };
    case gameTypesEnum.LOAD_IMAGE:
      return {
        ...state,
        ...{
          error: null,
          numberOfQuestions: action.numberOfQuestions,
          provinceCities: action.provinceCities,
        },
      };
    case gameTypesEnum.NEXT_QUESTION:
      return {
        ...state,
        ...{ error: null, response: action.response },
      };
    case gameTypesEnum.START_GAME:
      return {
        ...state,
        ...{
          error: null,
          gameStart: true,
          time: 100,
          quiz: action.quiz,
        },
      };
    case gameTypesEnum.CITIES_OTHER:
      return {
        ...state,
        ...{ cities: action.cities },
      };

    case gameTypesEnum.ERROR:
      return {
        ...state,
        ...{ error: action.error, isLoading: false },
      };

    case gameTypesEnum.SET_LOCAL_RANKING:
      return {
        ...state,
        ...{ error: action.error, ranking: action.ranking, isLoading: false },
      };
    case gameTypesEnum.PAUSAR_JUEGO:
      return {
        ...state,
        ...{ gameStart: false },
      };
    case gameTypesEnum.RESET:
      return state;
    default:
      return state;
  }
};
