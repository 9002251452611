import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { AuthStore } from "../../_redux/models/auth.model";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { gameActions } from "../../_redux/actions";
import { useEffect, useState } from "react";
import styles from "./photo.module.scss";
import {
  apps,
  close,
  closeCircle,
  closeOutline,
  ellipsisHorizontal,
  ellipsisVertical,
  fileTray,
  images,
  mail,
  map,
  settings,
} from "ionicons/icons";
import { history } from "../../_helpers/history";
import MenuLateral from "../../components/Menu/MenuLateral";
import { DataGame, GameState } from "../../_redux/models/game.model";
import Enumerable from "linq";
import { MenuApp } from "../../utils/functions";
import { Icon } from "@material-ui/core";
import { configData } from "../../data/configData";

const About: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const myUser = useSelector((store: AuthStore) => store.auth.user);
  const [showModal, setShowModal] = useState(false);
  const [urlWiki, setUrlWiki] = useState("");
  const [present, dismiss] = useIonActionSheet();
  const [searchText, setSearchText] = useState("");
  const [listcity, setListcity] = useState<DataGame[]>(null);

  return (
    <IonPage id="page-photo">
      <IonContent className="bg_app_content_photo">
        <IonToolbar className="menu_movil_2">
          {MenuApp(present, t)}
          <IonTitle>{t("about_the_app")}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
        </IonToolbar>

        <IonRow>
          <IonCol size="12" size-md="3" className="lateral hide_pc">
            <MenuLateral className="hide_pc"></MenuLateral>
          </IonCol>
          <IonCol size="12" size-md="9" className="centro_pagina_photo">
            <IonContent>
              <div className="contenido_scroll">
                <IonCard key={1}>
                  <IonCardHeader>
                    <IonCardTitle>{configData.provincia_o_estado}</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p>{t("help_text_1")}</p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>{t("help_text_2")}</strong>
                    </p>
                    <p>
                      <strong>&nbsp;</strong>
                    </p>
                    <p>{t("help_text_3")}</p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_4")}</p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_5")}</p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_6")}</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>{t("help_text_7")}</strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_8")}</p>
                    <p>&nbsp;</p>
                    <ul>
                      <li>{t("time")}</li>
                      <li>{t("history")}</li>
                      <li>{t("purchase_of_entry")}</li>
                      <li>{t("half_price_or_free")}</li>
                      <li>{t("user_opinions")}</li>
                      <li>{t("how_to_get_to_the_tourist_spot")}</li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_9")}</p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_10")}</p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>{t("help_text_11")}</strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_12")}</p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>{t("help_text_13")}</strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_14")}</p>
                    <ul>
                      <li>
                        Email:{" "}
                        <a href="mailto:hello@pekegames.app">
                          hello@pekegames.app
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/people/Peke-Games/100037288136176/">
                          Facebook
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/PekeGames" title="Twitter">
                          Twitter
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/pekegamess/"
                          title="Instagram"
                        >
                          Instagram
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCGUe2P_-tURFmAtEHvpUGoQ"
                          title="Youtube"
                        >
                          Youtube
                        </a>
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>{t("help_text_15")}</strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_16")}</p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_17")}</p>
                    <p>&nbsp;</p>
                    <ul>
                      <li>
                        {t("help_text_18")}
                        <ul>
                          <li>{t("help_text_19")}</li>
                          <li>{t("help_text_20")}</li>
                          <li>{t("help_text_21")}</li>
                        </ul>
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>
                      <strong>{t("help_text_22")}</strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_23")}</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <ul>
                      <li>
                        50%
                        <ul>
                          <li>{t("help_text_24")}</li>
                        </ul>
                      </li>
                      <li>
                        {t("store_2_oportunidad_title")}
                        <ul>
                          <li>{t("help_text_25")}</li>
                        </ul>
                      </li>
                      <li>
                        {t("store_respuesta_correcta_title")}
                        <ul>
                          <li>{t("help_text_26")}</li>
                        </ul>
                      </li>
                      <li>
                        {t("store_mas_recuperar_puntos_title")}
                        <ul>
                          <li>{t("help_text_27")}</li>
                        </ul>
                      </li>
                      <li>
                        {t("store_mas_tiempo_title")}
                        <ul>
                          <li>{t("help_text_28")}</li>
                        </ul>
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>
                      <strong>&nbsp;</strong>
                    </p>
                    <p>
                      <strong>{t("help_text_29")}</strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_30")}</p>

                    <p>&nbsp;</p>
                    <p>{t("help_text_31")}</p>
                    <p>{t("help_text_32")}</p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_33")}</p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_34")}</p>
                    <p>&nbsp;</p>
                    <p>{t("help_text_35")}</p>
                    <p>&nbsp;</p>
           
                  </IonCardContent>
                </IonCard>
              </div>
            </IonContent>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default About;
