import { stockData } from "../../data/stockData";
import Enumerable from "linq";
import { DataGame } from "../models/game.model";
import { randomArray } from "../utils/helper";
import { questionTypesEnum } from "../types/question.enum";
import { history } from "../../_helpers/history";
import { options } from "ionicons/icons";
let cidadData = stockData.Ciudad;
let estadoData = stockData.Estado;
let ciudadDelEstado: DataGame[] = null;
let photociudad: DataGame[] = null;
let gptCiudad: DataGame[] = null;

const ResetData = async (): Promise<any> => {
  cidadData = stockData.Ciudad;
  estadoData = stockData.Estado;
  ciudadDelEstado = await QuestionGameService();
  if (photociudad !== null && photociudad !== undefined && photociudad.length > 0) {
    //photociudad.map((p) => (p.O = 40)); si queremos validar que la foto sea de otro estado, las otras opciones
    ciudadDelEstado.push(...photociudad);
  }
  if (gptCiudad !== null && gptCiudad !== undefined && gptCiudad.length > 0) {
    ciudadDelEstado.push(...gptCiudad);
  }

  console.log("reset");
  //console.log("Reset numero de preguntas",ciudadDelEstado.length, ciudadDelEstado);
};
const PhotoCiudadAdd = async (fotociudadaux: DataGame[]): Promise<any> => {
  photociudad = fotociudadaux;
};

const GPTCiudadAdd = async (gptciudadaux: DataGame[]): Promise<any> => {
  gptCiudad = gptciudadaux;
};

const ProvinceGameService = async (): Promise<any> => {
  try {
    if (estadoData === null) {
      estadoData = stockData.Estado;
    }
    return Enumerable.from(estadoData)
      .where((p) => p.I === Number(process.env.REACT_APP_PROVINCE))
      .distinct((p) => p.N)
      .first();
  } catch (error) {
    throw new TypeError(error.message);
  }
};

const NextQuestionGameService = async (
  answered: string[],
  currentQuestion: number,
  todasCiudades: DataGame[],
  preguntasCiudad: DataGame[],
): Promise<any> => {
  try {
    const question = Enumerable.from(preguntasCiudad)
      .where((p) => !answered.includes(p.N))
      .orderBy((o) => o.N)
      .toArray();
    if (question.length <= 0) {
      history.push("/gameOver");
      return null;
    }
    const random = randomArray(question);
    return Enumerable.from(random).first();
  } catch (error) {
    throw new TypeError(error.message);
  }
};

const QuestionLocalGameService = async (answered: string[]): Promise<any> => {
  try {
    if (cidadData === null) {
      cidadData = stockData.Ciudad;
    }
    const question = Enumerable.from(cidadData)
      .where(
        (p) =>
          p.I === Number(process.env.REACT_APP_PROVINCE) &&
          !answered.includes(p.N)
      )
      .orderBy((o) => o.N)
      .toArray();
    const random = randomArray(question);
    return Enumerable.from(random).first();
  } catch (error) {
    throw new TypeError(error.message);
  }
};

const OtherCities = async (questionProvince: DataGame[]): Promise<any> => {
  try {
    if (cidadData === null) {
      cidadData = stockData.Ciudad;
    }
    //selecionamos las ciudades distita de la provincia que estamos jugando
    const cities = Enumerable.from(cidadData)
      .where((p) => p.I !== Number(process.env.REACT_APP_PROVINCE))
      .toArray();

    const citiesFilter = Enumerable.from(cities)
      .distinct((p) => p.N)
      .toArray();

    return citiesFilter;
    /*
    const filtroDuplicado = Enumerable.from(cities)
      .groupBy((p) => p.N)
      .where((p) => p.count() > 1)
      .select((p) => p.key())
      .toArray();

    return Enumerable.from(citiesFilter).except(filtroDuplicado).toArray();
    */
  } catch (error) {
    throw new TypeError(error.message);
  }
};

const QuestionGameService = async (): Promise<any> => {
  try {
    if (cidadData === null) {
      cidadData = stockData.Ciudad;
    }
    const question = Enumerable.from(cidadData)
      .where((p) => p.I === Number(process.env.REACT_APP_PROVINCE))
      .orderBy((o) => o.N)
      //.take(6) //comentar par PRO
      .toArray();
    return question;
  } catch (error) {
    throw new TypeError(error.message);
  }
};

const generateRandomizedOptions = (
  options: DataGame[],
  sourceList: DataGame[],
  typeFilter: questionTypesEnum,
  numberOfOptions: number
): DataGame[] => {
  const filteredOptions = sourceList
    .filter(p => !p.N.startsWith("https") && p.T !== typeFilter)
    .slice(); // Slice here to ensure we're working with a new array

  const randomizedOptions = randomArray(options.concat(filteredOptions).slice(0, numberOfOptions));

  return randomizedOptions;
};

const QuizService = async (
  answered: string[],
  todasCiudades: DataGame[],
  preguntasCiudad: DataGame[],
  question: DataGame
): Promise<any> => {

  try {
    // Hacer copias locales de las listas para trabajar con ellas
    const localTodasCiudades = [...todasCiudades];
    const localPreguntasCiudad = [...preguntasCiudad];
    if (question.T === questionTypesEnum.Classic) {
      let opcionesDeRespuestaRandom: DataGame[] = randomArray(localTodasCiudades);

      let opcionesRespuestaQuiz = Enumerable.from(opcionesDeRespuestaRandom)
        .where((p) => !p.N.startsWith("https") && p.T !== questionTypesEnum.GPT)
        .take(3)
        .toArray();
      opcionesRespuestaQuiz.push(question);
      return randomArray(opcionesRespuestaQuiz);
    } else if (question.T === questionTypesEnum.GPT) {
      const preguntasGPT: DataGame[] = randomArray(localPreguntasCiudad.filter(p => p.N !== question.N));
      let opcionesDeRespuestaRandom = Enumerable.from(preguntasGPT)
        .where(
          (p) =>
            !p.N.startsWith("http") && p.T === questionTypesEnum.Classic
        )
        .take(3)
        .toArray();

      opcionesDeRespuestaRandom.push(question);
      return randomArray(opcionesDeRespuestaRandom);
    } else if (question.T === questionTypesEnum.Photo) {
      //SI EL PUNTO TURISTICO HACE PARTE DE MUCHAS CIUDADES ,FILTRA LAS POSIBLES RESPUESTA DE OTRO ESTADO/PROVINCIA
      if (question.O !== 0 && question.O !== undefined) {
        const preguntasPuntoTuristicoEspeciales: DataGame[] = randomArray(localTodasCiudades.filter(p => p.N !== question.F));
        let opcionesDeRespuestaRandom = Enumerable.from(preguntasPuntoTuristicoEspeciales)
          .where(
            (p) =>
              p.I === question.O
          )
          .take(3)
          .toArray();

        // Hacer una copia de la pregunta antes de modificarla
        const modifiedQuestion = { ...question };

        // Crear una nueva pregunta con la propiedad N modificada
        const modifiedQuestionWithNewN = { ...modifiedQuestion, N: modifiedQuestion.F };

        opcionesDeRespuestaRandom.push(modifiedQuestionWithNewN);
        return randomArray(opcionesDeRespuestaRandom);
      } else {
        //EL PUNTO TURISTICO HACE PARTE DE UNA ÜNICA CIUDAD
        const preguntasPuntoTuristicoUnaCiudad: DataGame[] = randomArray(localPreguntasCiudad.filter(p => p.N !== question.F));
        let opcionesDeRespuestaRandom = Enumerable.from(preguntasPuntoTuristicoUnaCiudad)
          .where(
            (p) =>
              !p.N.startsWith("http") && p.T === questionTypesEnum.Classic
          )
          .take(3)
          .toArray();

        // Hacer una copia de la pregunta antes de modificarla
        const modifiedQuestion = { ...question };

        // Crear una nueva pregunta con la propiedad N modificada
        const modifiedQuestionWithNewN = { ...modifiedQuestion, N: modifiedQuestion.F };

        opcionesDeRespuestaRandom.push(modifiedQuestionWithNewN);
        return randomArray(opcionesDeRespuestaRandom);
      }

    } else {
      throw new TypeError('El tipo de pregunta no es valido.¡');
    }
  } catch (error) {
    throw new TypeError(error);
  }
  /*
  try {
    
    const filtroQuitarDuplicados = Enumerable.from(todasCiudades)
      .groupBy((p) => p.N)
      .where((p) => p.count() > 1)
      .select((p) => p.key())
      .toArray();
      preguntasCiudad = preguntasCiudad.filter(
      (p) => !filtroQuitarDuplicados.includes(p.N)
    );

    let questions = Enumerable.from(cities).where(p => p.N !== question.N && p.N !== question.F).take(4).toArray();
    let preguntasYRespuestaOK = false;
    let count = 0;
    while (!preguntasYRespuestaOK) {
      count++;
      if (count > 2) {
        preguntasYRespuestaOK = true;
        console.log("error - preguntas iguales");
        //TODO, si intenta 10 veces y no logro, algo va mal. Entra algunas veces y muestra respuesta similares
      }

      if (question.T === questionTypesEnum.GPT) {
        randomArray(ciudadDelEstado);
        questions = Enumerable.from(ciudadDelEstado)
          .where(
            (p) =>
              !p.N.startsWith("http") && p.T === questionTypesEnum.Classic &&
              p.N !== question.N
          )
          .take(4)
          .toArray();
      }
      else if (question.T === questionTypesEnum.Photo) {
        randomArray(ciudadDelEstado);
        //TODO: si la pregunta es una foto y el código de la respuesta es diferente a 0, entonces busco las ciuddes en otra provincia
        if (question.O !== 0 && question.O !== undefined) {
          randomArray(cities);
          questions = Enumerable.from(cities)
            .where(
              (p) =>
                !p.N.startsWith("http") &&
                p.T !== questionTypesEnum.GPT &&
                p.N !== question.N &&
                p.I === question.O && p.N !== question.F
            )
            .take(4)
            .toArray();
        }
        else {
          questions = Enumerable.from(ciudadDelEstado)
            .where((p) => !p.N.startsWith("https") && p.N !== question.N && p.T !== questionTypesEnum.GPT && p.N !== question.F)
            .take(4)
            .toArray();
        }

        if (
          questions[0]?.N !== questions[1]?.N &&
          questions[0]?.N !== questions[2]?.N &&
          questions[0]?.N !== questions[3]?.N &&
          questions[1]?.N !== questions[2]?.N &&
          questions[1]?.N !== questions[3]?.N &&
          questions[2]?.N !== questions[3]?.N
        ) {
          preguntasYRespuestaOK = true;
        } else {
        }
      } else {
        randomArray(cities);
        questions = Enumerable.from(cities).take(4).toArray();
        preguntasYRespuestaOK = true;

        if (
          questions[0].N !== questions[1].N &&
          questions[0].N !== questions[2].N &&
          questions[0].N !== questions[3].N &&
          questions[1].N !== questions[2].N &&
          questions[1].N !== questions[3].N &&
          questions[2].N !== questions[3].N
        ) {
          preguntasYRespuestaOK = true;
        } else {
          preguntasYRespuestaOK = true; //para que no pete
        }
      }
    }

    const quizOption =
      question.T === questionTypesEnum.Classic ? questions : questions;

    if (question.T === questionTypesEnum.Photo) {
      quizOption[0].N = question.F;
      quizOption[0].V = question.V;
    }
    else if (question.T === questionTypesEnum.GPT) {
      quizOption[0].N = question.F;
    }
    else {
      quizOption[0] = question;
    }

    return randomArray(quizOption);
  } catch (error) {
    throw new TypeError(error);
  }

  */
};


export const jsonService = {
  ProvinceGameService,
  NextQuestionGameService,
  QuestionGameService,
  OtherCities,
  QuizService,
  ResetData,
  PhotoCiudadAdd,
  GPTCiudadAdd
};
