import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { AuthStore } from "../../_redux/models/auth.model";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { gameActions } from "../../_redux/actions";
import { useEffect, useState } from "react";
import styles from "./photo.module.scss";
import {
  apps,
  close,
  closeCircle,
  closeOutline,
  ellipsisHorizontal,
  ellipsisVertical,
  fileTray,
  images,
  mail,
  map,
  settings,
} from "ionicons/icons";
import { history } from "../../_helpers/history";
import MenuLateral from "../../components/Menu/MenuLateral";
import { DataGame, GameState } from "../../_redux/models/game.model";
import Enumerable from "linq";
import { MenuApp } from "../../utils/functions";
import { Icon } from "@material-ui/core";
import { configData } from "../../data/configData";
import Turtorial from "../../components/Turtorial/Turtorial";
import { AdMob } from '@capacitor-community/admob';

const Ayuda: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const myUser = useSelector((store: AuthStore) => store.auth.user);
  const [showModal, setShowModal] = useState(false);
  const [urlWiki, setUrlWiki] = useState("");
  const [present, dismiss] = useIonActionSheet();
  const [searchText, setSearchText] = useState("");
  const [listcity, setListcity] = useState<DataGame[]>(null);
  const [ayudaPantalla, setAyudaPantalla] = useState<boolean>(true);

  const quitarAyuda = () => {
    AdMob.resumeBanner();
    history.goBack();
  };

  return (
    <IonPage id="page-photo">
      <IonContent className="bg_app_content_photo">
        {ayudaPantalla && <Turtorial evento={quitarAyuda}></Turtorial>}
      </IonContent>
    </IonPage>
  );
};

export default Ayuda;
