import { IonButton, IonCol, IonIcon, IonRow } from "@ionic/react";
import { addCircle, globe, personAdd, play } from "ionicons/icons";
import React, { Component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { partidaActions } from "../../_redux/actions";
import { GameState } from "../../_redux/models/game.model";
import { history } from "../../_helpers/history";
import { AuthState } from "../../_redux/models/auth.model";
import { PartidaState } from "../../_redux/models/partida.model";
import { delay } from "../../utils/functions";
export const PersonajeYTextoGameOver: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const game: GameState = useSelector((state: any) => state.game);
  const authUser: AuthState = useSelector((state: any) => state.auth);
  const partida: PartidaState = useSelector((state: any) => state.partida);
  const [imagenPersonaje, setImagenPersonaje] = useState<string>(
    "assets/personaje/camino.svg"
  );

  const [titulo, setTituto] = useState<string>("");
  const [descripcion, setDescripcion] = useState<string>("");

  const jugarDeNuevo = async () => {
    dispatch(partidaActions.resetPatida());
    await delay(500);
    dispatch(
      partidaActions.proximaPregunta(
        partida.answered,
        partida.currentQuestion,
        game.cities,
        game.provinceCities
      )
    );
    partida.gameStart = true;
    history.replace("quiz");
  };
  useEffect(() => {
    if (!authUser || !partida) {
      console.log("authUser o partida es null");
      return;
    }
    console.log("authUser", authUser);
    console.log("partida", partida);
    console.log("posicion",partida.posicion);
    if (authUser !== null && partida !== null) {
      let posicion: number = partida.posicion;
      const puntos: string = partida.point.toFixed(0);
      let nick : string = authUser?.user?.Nick;
      nick = nick?.toLocaleUpperCase();
      if(posicion===-1  && partida.point === 0){
        setImagenPersonaje("assets/personaje/pensativo.svg");
        setTituto(
          `${t("position_cero_puntos_titulo", {
            name: nick,
          })}`
        );
        setDescripcion(`${t("position_cero_puntos_descripcion")}`);
        return;
      }
      if (partida.point === 0) {
        setImagenPersonaje("assets/personaje/pensativo.svg");
        setTituto(
          `${t("position_cero_puntos_titulo", {
            name: nick,
          })}`
        );
        setDescripcion(`${t("position_cero_puntos_descripcion")}`);
        return;
      }else if(!authUser.isAuth && partida.point > 0){
        setImagenPersonaje("assets/personaje/pensativo.svg");
        setTituto(
          `${t("invitado_titulo", {
            name: nick,
          })}`
        );
        setDescripcion(
          `${t("invitado_descripcion", {
            name: nick,
            puntos: puntos,
            posicion: posicion,
          })}`
        );
      }else {
        switch (posicion) {
          
          case -1:
            setImagenPersonaje("assets/personaje/triste.svg");
            setTituto(
              `${t("position_last_50_titulo", {
                name: nick,
              })}`
            );
            setDescripcion(
              `${t("position_cero_con_puntos_descripcion", {
                name: nick,
                puntos: puntos,
              })}`
            );
            break;
            
          case 1:
            setImagenPersonaje("assets/personaje/campeonTrofeo.svg");
            setTituto(
              `${t("position_1_titulo", {
                name: nick,
              })}`
            );
            setDescripcion(
              `${t("position_1_descripcion", {
                name: nick,
                puntos: puntos,
              })}`
            );
            break;
          case 2:
          case 3:
          case 4:
          case 5:
            setImagenPersonaje("assets/personaje/campeonParaArriba.svg");
            setTituto(
              `${t("position_5_titulo", {
                name: nick,
              })}`
            );
            setDescripcion(
              `${t("position_5_descripcion", {
                name: nick,
                puntos: puntos,
                posicion: posicion,
              })}`
            );
            break;
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
            setImagenPersonaje("assets/personaje/bienvenido.svg");
            setTituto(
              `${t("position_10_titulo", {
                name: nick,
              })}`
            );
            setDescripcion(
              `${t("position_10_descripcion", {
                name: nick,
                puntos: puntos,
                posicion: posicion,
              })}`
            );
            break;
          case 11:
          case 12:
          case 13:
          case 14:
          case 15:
          case 16:
          case 17:
          case 18:
          case 19:
          case 20:
          case 21:
          case 22:
          case 23:
          case 24:
          case 25:
          case 26:
          case 27:
          case 28:
          case 29:
          case 30:
          case 31:
          case 32:
          case 33:
          case 34:
          case 35:
          case 36:
          case 37:
          case 38:
          case 39:
          case 40:
          case 41:
          case 42:
          case 43:
          case 44:
          case 45:
          case 46:
          case 47:
          case 48:
          case 49:
          case 50:
            setImagenPersonaje("assets/personaje/muyFuerte.svg");
            setTituto(
              `${t("position_50_titulo", {
                name: nick,
              })}`
            );
            setDescripcion(
              `${t("position_50_descripcion", {
                name: nick,
                puntos: puntos,
                posicion: posicion,
              })}`
            );
            break;
          default:
            setImagenPersonaje("assets/personaje/joia.svg");
            setDescripcion(
              `${t("position_last_50_descripcion", {
                name: nick,
                posicion: posicion,
                puntos: puntos,
              })}`
            );

            setTituto(
              `${t("position_last_50_titulo", {
                name: nick,
                puntos: puntos,
                posicion: posicion,
              })}`
            );
        }
      }
    }
  },[authUser, partida, props, t]);

  const renderCreateAccountButton = () => {
    if (!authUser.isAuth) {
      return (
        <div className="caja_centrado_button">
          <IonButton color="success" shape="round" size="large" onClick={props?.eventoAbrir}>
            <IonIcon slot="start" icon={personAdd} />
            {t("create_account_button")}
          </IonButton>
        </div>
      );
    }
    return null;
  };

  return (
    <IonRow>
      <IonCol size="12" sizeSm="12" size-md="6">
        <h3 className="titulo_principal">{titulo}</h3>
        {renderCreateAccountButton()}
        <img src={imagenPersonaje} alt="game over" className="img_fin_juego"></img>
      </IonCol>
      <IonCol>
        <p className="texto_centrado">{descripcion}</p>

        <div className="caja_centrado">
          <IonButton color="success" shape="round" size="large" onClick={jugarDeNuevo}>
            <IonIcon slot="start" icon={play} />
            {t("play_again")}
          </IonButton>
        </div>
      </IonCol>   
    </IonRow>
  );
};

export default PersonajeYTextoGameOver;

