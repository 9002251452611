import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Enumerable from "linq";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LogroState } from "../../_redux/models/logro.models";

export const ListadoLogro: React.FC<any> = (props) => {
  const logros: LogroState = useSelector((state: any) => state.logro.logro);
  //const logrosOrdenados= Enumerable.from(logros.logro).orderByDescending(p=> p.TieneLogro && p.LogroId).toArray();
  const { t } = useTranslation();
  return (
    <IonModal
      isOpen={props?.estadoModal}
      onDidDismiss={() => {
        props?.eventoCerrar();
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={() => props?.eventoCerrar()}>
              {" "}
              {t("close")}
            </IonButton>
          </IonButtons>
          <IonTitle>{t("all_logros")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {Array.isArray(logros) &&
          logros.map((item, index) => {
            return (
              <IonCard
                className="ionCard minimo_card_logro squares"
                key={index}
              >
                <IonCardHeader>
                  <IonCardTitle className="titulo_principal">
                    {item?.Name}
                  </IonCardTitle>
                </IonCardHeader>
                <img
                  key={index}
                  alt="logro"
                  className={
                    item?.TieneLogro ? "imgLogoActivo" : "imgLogoInactivo"
                  }
                  src={"https://www.pekegames.app/img/logro/" + item?.Image}
                ></img>
                <IonCardContent>
                  {item?.TieneLogro
                    ? item?.DescriptionGanoLogro
                    : item?.Description}
                </IonCardContent>
                <hr className="separador"></hr>
                <h2 className="titulo_premio_logro">
                  {item?.TieneLogro ? "Has ganado" : "Premio del logro"}
                </h2>
                <IonRow className={item.TieneLogro ? "" : "opacidad"}>
                  {item.ayuda_vida > 0 && (
                    <IonCol className="text_center">
                      <img
                        alt={t("life_50")}
                        src="/assets/icon/vida.png"
                        className="icon_ayuda_perfil"
                      ></img>
                    </IonCol>
                  )}

                  {item.ayuda_50 > 0 && (
                    <IonCol className="text_center">
                      <img
                        alt={t("help_50")}
                        src="/assets/icon/ayuda50.png"
                        className="icon_ayuda_perfil"
                      ></img>
                    </IonCol>
                  )}

                  {item.ayuda_reloj > 0 && (
                    <IonCol className="text_center">
                      <img
                        alt={t("help_longer")}
                        src="/assets/icon/ayudaMasTiempo.png"
                        className="icon_ayuda_perfil"
                      ></img>
                    </IonCol>
                  )}

                  {item.ayuda_recuperar_puntos > 0 && (
                    <IonCol className="text_center">
                      <img
                        alt={t("help_not_to_lose_points")}
                        src="/assets/icon/ayudaRecuperarPuntos.png"
                        className="icon_ayuda_perfil"
                      ></img>
                    </IonCol>
                  )}

                  {item.ayuda_respuesta_correcta > 0 && (
                    <IonCol className="text_center">
                      <img
                        alt={t("help_correct_answer")}
                        src="/assets/icon/ayudaRespuestaCorrecta.png"
                        className="icon_ayuda_perfil"
                      ></img>
                    </IonCol>
                  )}

                  {item.ayuda_doble_oportunidad > 0 && (
                    <IonCol className="text_center">
                      <img
                        alt={t("help_doble")}
                        src="/assets/icon/oportunidadDoble.png"
                        className="icon_ayuda_perfil"
                      ></img>
                    </IonCol>
                  )}

                  {item.ayuda_moneda > 0 && (
                    <IonCol className="text_center">
                      <img
                        alt={t("coin")}
                        src="/assets/icon/moneda.png"
                        className="icon_ayuda_perfil"
                      ></img>
                    </IonCol>
                  )}
                </IonRow>
                <IonRow className={item.TieneLogro ? "" : "opacidad"}>
                  {item.ayuda_vida > 0 && (
                    <IonCol className="color_premio">{item.ayuda_vida}</IonCol>
                  )}
                  {item.ayuda_50 > 0 && (
                    <IonCol className="color_premio">{item.ayuda_50}</IonCol>
                  )}
                  {item.ayuda_reloj > 0 && (
                    <IonCol className="color_premio">{item.ayuda_reloj}</IonCol>
                  )}
                  {item.ayuda_recuperar_puntos > 0 && (
                    <IonCol className="color_premio">
                      {item.ayuda_recuperar_puntos}
                    </IonCol>
                  )}
                  {item.ayuda_respuesta_correcta > 0 && (
                    <IonCol className="color_premio">
                      {item.ayuda_respuesta_correcta}
                    </IonCol>
                  )}
                  {item.ayuda_doble_oportunidad > 0 && (
                    <IonCol className="color_premio">
                      {item.ayuda_doble_oportunidad}
                    </IonCol>
                  )}
                  {item.ayuda_moneda > 0 && (
                    <IonCol className="color_premio">
                      {item.ayuda_moneda}
                    </IonCol>
                  )}
                </IonRow>
              </IonCard>
            );
          })}
      </IonContent>
    </IonModal>
  );
};

export default ListadoLogro;
