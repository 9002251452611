import { partidaTypes, questionTypesEnum } from "../types";
import { PartidaState, PartidaAction } from "../models/partida.model";

const initialState = {
  currentQuestion: 0,
  numberOfQuestions: 0,
  point: 0,
  errores: 0,
  aciertos: 0,
  tiempoControl: 0,
  gameStart: false,
  answered: [],
  quiz: [],
  t: questionTypesEnum.Classic,
  level: 1,
  posicion:-1
};

export const partidaReducer = (
  state: PartidaState = initialState,
  action: PartidaAction
): any => {
  switch (action.type) {
    case partidaTypes.EMPEZAR_PARTIDA:
      return {
        ...state,
        ...{ error: null, isLoading: false, gameStart: true, time: 100 },
      };
    case partidaTypes.NEXT_QUESTION:
      return {
        ...state,
        ...{
          error: null,
          isLoading: false,
          answered: action.answered,
          response: action.response,
          currentQuestion: action.currentQuestion,
          quiz: action.quiz,
          tipo: action.tipo,
          gameStart: true,
        },
      };
    case partidaTypes.RECUPERAR_PARTIDA:
      return {
        ...state,
        ...{
          error: null,
          isLoading: false,
          answered: action.answered,
          response: action.response,
          currentQuestion: action.currentQuestion,
          quiz: action.quiz,
          tipo: action.tipo,
          gameStart: true,
          aciertos: action.aciertos,
          point: action.point,
          errores: action.errores,
          tiempoControl: action.tiempoControl,
          level: action.level,
          id: action.id,
        },
      };

    case partidaTypes.CAMBIO_NIVEL:
      return {
        ...state,
        ...{ level: action.level },
      };

    case partidaTypes.FINALIZAR_JUEGO_FIN_PREGUNTA:
      return {
        ...state,
        ...{ finalizarJuego: action.finalizarJuego },
      };

    case partidaTypes.RESET:
      return {
        ...state,
        ...{
          error: null,
          isLoading: false,
          level: 0,
          answered: [],
          response: [],
          currentQuestion: 0,
          quiz: 0,
          tipo: action.tipo,
          gameStart: false,
          point: 0,
          errores: 0,
          aciertos: 0,
          tiempoControl: 0,
          posicion:-1
        },
      };

    case partidaTypes.FINE_QUESTION:
      return {
        ...state,
        ...{
          point: action.point,
          aciertos: action.aciertos,
          tiempoControl: action.tiempoControl,
        },
      };
    case partidaTypes.BAD_QUESTION:
      return {
        ...state,
        ...{
          point: action.point,
          errores: action.errores,
          tiempoControl: action.tiempoControl,
        },
      };
      case partidaTypes.POSICION:
      return {
        ...state,
        ...{
          posicion: action.posicion,
        },
      };

    default:
      return state;
  }
};
