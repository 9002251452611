import { IonIcon } from "@ionic/react";
import { Icon } from "@material-ui/core";
import { star, starHalf } from "ionicons/icons";
import React, { Component, useEffect, useState } from "react";
export const Star: React.FC<any> = (props) => {
  let [lista,setLista] = useState<number[]>([]);

    let entero = parseInt(props.rating, 10);
    lista = [];
    for (let index = 0; index < entero; index++) {
        lista.push(1);
    }
    let decimal = props.rating - entero;
    if (decimal >= 0.48) {
        lista.push(0);
    } else if (decimal > 0.9) {
        lista.push(1);
    } else {
      //console.log("ninguna estrella");
    }
 

  return (
    <>
    
      {lista.map((item,index)=> (
          item === 1  ? <IonIcon icon={star} key={index}/> : <IonIcon key={index} icon={starHalf}/>
      ))} {props.cantidad>0 ? '('+props.cantidad+')': ''}
    </>
  );
};

export default Star;
