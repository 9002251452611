import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { partidaActions } from "../../_redux/actions/partida.actions";
import {
  GameState,
  RankingOnlineRequest,
} from "../../_redux/models/game.model";
import { history } from "../../_helpers/history";
import {
  apps,
  ellipsisHorizontal,
  ellipsisVertical,
  images,
  map,
  settings,
} from "ionicons/icons";
import useState from "react-usestateref";
import { AuthState } from "../../_redux/models/auth.model";
import { gameService } from "../../_redux/services";
import { delay, OrdenarLoja } from "../../utils/functions";
import { PartidaState } from "../../_redux/models/partida.model";
import ConfettiExplosion from "@reonomy/react-confetti-explosion";
import PersonajeYTextoGameOver from "../../components/FinDeJuego/PersonajeYTextoGameOver";
import CrearCuenta from "../../components/Modal/CrearCuenta";
import { MenuApp } from "../../utils/functions";
import { useTranslation } from "react-i18next";
import { authActions, gameActions } from "../../_redux/actions";
import { Capacitor } from "@capacitor/core";
import useSound from "use-sound";
import { jsonService } from "../../_redux/services/json.services";
const EndGame: React.FC<any> = (props) => {
  const { t } = useTranslation();

  const state = history.location.state as any;
  const dispatch = useDispatch();
  const authUser: AuthState = useSelector((state: any) => state.auth);
  const partida: PartidaState = useSelector((state: any) => state.partida);
  const [isExploding, setIsExploding] = useState(false);
  const game: GameState = useSelector((state: any) => state.game);
  const [present, dismiss] = useIonActionSheet();
  const [loginModal, setLoginModal] = useState(false);
  const abrirModal = () => {
    setLoginModal(true);
  };

  const cerrarModal = () => {
    setLoginModal(false);
  };

  const handleClick = async () => {
    await delay(5000);
    setIsExploding(false);
    //history.replace("quiz");
  };

  const performCleanup = async () => {
    // Acciones de limpieza al salir del componente
    localStorage.removeItem("jogo");

    await delay(500);
    if (history.location.pathname==='/dashboard') {
      dispatch(partidaActions.resetPatida());
      partida.gameStart = false;
      console.log("Saliendo del componente. Realizando limpieza...");
    }
  };
  useEffect(() => {
    // Se ejecuta al montar el componente
    return () => {
      // Se ejecuta al desmontar el componente (al salir)
      performCleanup();
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (partida?.posicion < 100 && partida?.point > 100) {
        setIsExploding(true);
        await delay(5000);
        setIsExploding(false);
        handleClick();
      }
      if (Capacitor.isNativePlatform()) {
        // RateApp.requestReview();
      }

      if (authUser?.user?.DatosExtendido?.Vida <= 0) {
        dispatch(authActions.vida_inicial());
      }
    };

    fetchData();
    return () => {
      //handleBeforeUnload();
    };
  }, [partida, authUser, dispatch, game.cities, game.provinceCities]);

  return (
    <IonPage id="page-dashboard" className="page-dashboard">
      <IonHeader>
        <IonToolbar className="menu_movil_home_2">
          {MenuApp(present, t)}
          <IonTitle>{t("game_over")}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="bg_app_content">
        <IonRow>
          <IonCol size="12" size-md="12">
            <div className="padre">
              <div className="hijo">
                {isExploding ? (
                  <ConfettiExplosion
                    duration={5000}
                    floorWidth={1900}
                    floorHeight={900}
                    force={0.1}
                    particleCount={200}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <PersonajeYTextoGameOver
              eventoAbrir={abrirModal}
            ></PersonajeYTextoGameOver>
          </IonCol>
        </IonRow>
        <div className="margin_buttom_banner"></div>
        <CrearCuenta
          estadoModal={loginModal}
          eventoCerrar={cerrarModal}
          navegar={true}
        ></CrearCuenta>
      </IonContent>
    </IonPage>
  );
};

export default EndGame;
