import { LogroAction, LogroState } from "../models/logro.models";
import { logroTypesEnum } from "../types";

const initialState = {
  logro: [],
};

export const logroReducer = (
  state: LogroState = initialState,
  action: LogroAction
): any => {
  switch (action.type) {
    case logroTypesEnum.RELLENAR_LOGRO:
      return {
        ...state,
        ...{
          logro: action.logro,
        },
      };
    default:
      return state;
  }
};
