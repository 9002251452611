import { logroTypesEnum } from "../types";

const setLogro = (logro: any): any => {
    return {
      type: logroTypesEnum.RELLENAR_LOGRO,
      logro: logro,
    };
  };


  export const logroActions = {
    setLogro
  };