import React, { Component, useEffect, useState } from "react";

export const Vaciotiempo: React.FC<any> = (props) => {


  return (
    <h1>{props.ciudad}</h1>
  );
};

export default Vaciotiempo;
