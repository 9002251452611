import { IonCol, IonRow } from "@ionic/react";
import React from "react";
import { useState, useEffect, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useSound from "use-sound";
import { gestionarGuardarPartida } from "../../utils/guardar";
import { authActions, partidaActions } from "../../_redux/actions";
import { appActions } from "../../_redux/actions/app.actions";
import { AuthState } from "../../_redux/models/auth.model";
import { GameState } from "../../_redux/models/game.model";
import { PartidaState } from "../../_redux/models/partida.model";
import { Producto } from "../../_redux/models/producto.model";
import { gameService } from "../../_redux/services";
import styles from "./timer.module.scss";
function Timer(props, ref) {
  const [count, setCount] = useState(100);
  const [isRunning, setIsRunning] = useState(true);
  const partida: PartidaState = useSelector((state: any) => state.partida);
  const authUser: AuthState = useSelector((state: any) => state.auth);
  const game: GameState = useSelector((state: any) => state.game);
  const [isMounted, setIsMounted] = useState(false);
  const dispatch = useDispatch();
  const [sonidoAyuda] = useSound("/assets/sound/tienda.mp3", { volume: 1 });
  const { t } = useTranslation();

  useEffect(() => {
    setIsMounted(true); // Componente montado

    return () => {
      setIsMounted(false); // Componente desmontado
    };
  }, []);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (isMounted && isRunning && count > 0) {
      id = setInterval(() => {
        setCount((c) => c - 0.2);
      }, props.actualizacion);
    }

    if (count <= 0) {
      //Validamos si el usuario tiene la ayuda de tiempo. Infinito
      if (authUser?.user?.DatosExtendido?.AyudaMasTiempo > 0) {
        authUser.user.DatosExtendido.AyudaMasTiempo--;
        sonidoAyuda();
        dispatch(authActions.ayuda_actualizar(authUser.user));
        handleStart();
        dispatch(
          appActions.showToast({
            message: t("help_longer"),
            type: "warning",
            position: "top",
            icon: "globe",
          })
        );
        if (authUser.user.Auth) {
          gameService.QuitarAyuda(Producto.MAS_TIEMPO);
        }
        return;
      }
      if (
        authUser?.user?.DatosExtendido !== null &&
        authUser?.user?.DatosExtendido?.Vida > 0
      ) {
        authUser.user.DatosExtendido.Vida--;
        sonidoAyuda();
        handleStart();
        dispatch(authActions.ayuda_actualizar(authUser.user));
        if (authUser.user.Auth) {
          gameService.QuitarAyuda(Producto.COMPRAR_VIDA);
        }
        dispatch(
          appActions.showToast({
            message: t("lost_life_time_point", {
              name: authUser.user.Nick,
            }),
            type: "danger",
            position: "top",
            icon: "globe",
          })
        );
        if(authUser.user.DatosExtendido.Vida===0){
          setIsRunning(false);
          handleStop();
          setCount(0);
          gestionarGuardarPartida(partida, game, authUser, true, dispatch);
          return;
        }
        dispatch(
          partidaActions.proximaPregunta(
            partida.answered,
            partida.currentQuestion,
            game.cities,
            game.provinceCities
          )
        );
        return;
      }
      setIsRunning(false);
      handleStop();
      setCount(0);
      gestionarGuardarPartida(partida, game, authUser, true, dispatch);
    }

    return () => {
      if (id) clearInterval(id);
    };
  }, [isRunning, count, props.actualizacion, authUser, partida, game, dispatch, sonidoAyuda, t,isMounted]);

  const handleStop = () => {
    setIsRunning(false);
  };

  const handleStart = () => {
    setIsRunning(true);
    setCount(100);
  };

  const handleQuitarAyuda = () => {
    //Quitamos 30 puntos, para que no sea tantos
    setCount(count - 30);
  };

  useImperativeHandle(ref, () => ({
    handleStop: handleStop,
    handleStart: handleStart,
    handleQuitarAyuda: handleQuitarAyuda,
    count: count,
    isRunning: isRunning,
  }));

  return (
    <div>
      <div className={styles.box_question}>
        <IonRow className={styles.puntuacion_quiz}>
          <IonCol size="3" sizeMd="4">
            <div className={styles.unir}>
              <img
                src="/assets/icon/level.png"
                alt="level"
                className={styles.iconQuizCabecera}
              ></img>
              <span className={styles.quiz_puntuacion}> {partida?.level}</span>
            </div>
          </IonCol>
          <IonCol size="4" sizeMd="4" className={styles.text_quiz_center}>
            <div className={styles.unir}>
              <img
                src="/assets/icon/tiempo.png"
                className={styles.image}
                alt="level"
                id="tiempo"
                height={20}
                width={20}
              ></img>
              <span className={styles.puntosTiempo}>
                {parseInt(count.toString())}
              </span>
            </div>
          </IonCol>
          <IonCol size="5" sizeMd="4" className="text_end">
            <div className={styles.unir}>
              <img
                src="/assets/icon/puntos.png"
                alt="level"
                className={styles.iconQuizCabecera}
              ></img>
              <span className={styles.quiz_puntuacion}>
                {parseInt(partida?.point.toString())}
              </span>
            </div>
          </IonCol>
        </IonRow>
      </div>

      <progress value={count * 0.01} className={styles.w100}></progress>
    </div>
  );
}

export default React.forwardRef(Timer);
