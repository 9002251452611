export enum appTypes {
    SET_THEME = 'SET_THEME',
    GET_THEME = 'GET_THEME',
    REQUEST ="REQUEST_APP",
    FAILURE_REQUEST = "FAILURE_REQUEST",
    SET_COOKIE_PREFERENCES = 'SET_COOKIE_PREFERENCES',
    SET_GLOBAL_SETTINGS = 'SET_GLOBAL_SETTINGS',
    SET_LOCAL_SETTINGS = 'SET_LOCAL_SETTINGS',
    BANNER_MOSTRAR = 'BANNER_MOSTRAR',
    BANNER_OCULTAR = 'OCULTAR_BANNER',
    BANNER_MOSTRAR_INTERTINAL_PUBLICIDAD = 'BANNER_INTERTINAL_PUBLICIDAD',
    BANNER_OCULTAR_INTERTINAL_PUBLICIDAD = 'BANNER_INTERTINAL_OCULTAR_PUBLICIDAD',
    BANNER_LOCAL = 'BANNER_LOCAL',
    MOSTRAR_EVALUAR_APP = 'MOSTRAR_EVALUAR_APP',
    TOAST = 'TOAST',
    REMOVE_TOAST = 'REMOVE_TOAST',
    BANNER_LOCAL_MOSTRAR = 'BANNER_LOCAL_MOSTRAR',
    BANNER_LOCAL_OCULTAR = 'BANNER_LOCAL_OCULTAR',
    LOGRO_MOSTRAR = 'LOGRO_MOSTRAR',
    LOGRO_OCULTAR = 'LOGRO_OCULTAR',
    BANNER1_SAVE = 'BANNER1_SAVE',
    BANNER2_SAVE = 'BANNER2_SAVE',
    BANNER3_SAVE = 'BANNER3_SAVE',
    BANNER4_SAVE = 'BANNER4_SAVE',
  }