import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { AuthStore } from "../../_redux/models/auth.model";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { authActions, gameActions } from "../../_redux/actions";
import { useEffect, useState } from "react";
import { getVersion, MenuApp } from "../../utils/functions";
import { Icon } from "@material-ui/core";
import {
  apps,
  chevronBack,
  chevronForward,
  earth,
  ellipsisHorizontal,
  ellipsisVertical,
  gameController,
  images,
  logoFacebook,
  logoInstagram,
  logoTwitter,
  logoYoutube,
  map,
  notifications,
  person,
  settings,
  share,
  shareSocial,
} from "ionicons/icons";
import { history } from "../../_helpers/history";
import MenuLateral from "../../components/Menu/MenuLateral";
import { GameState } from "../../_redux/models/game.model";

const BotonVolver = () => {
  history.goBack();
};
const Ranking: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const myUser = useSelector((store: AuthStore) => store.auth.user);
  const gameGlobal: GameState = useSelector((state: any) => state.game);
  const [present, dismiss] = useIonActionSheet();
  useEffect(() => {
    dispatch(gameActions.setLocalRanking());
  }, []);

  return (
    <IonPage id="page-dashboard" className="page-dashboard">
      <IonHeader>
                <IonToolbar className="menu_movil_home_2">
          {MenuApp(present, t)}
          <IonTitle>{t("ranking")}</IonTitle>

          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonRow>
          <IonCol size="12" size-md="3" className="lateral hide_pc">
            <MenuLateral></MenuLateral>
          </IonCol>
          <IonCol size="12" size-md="9" className="centro_pagina">
            <IonList className="scroll_lista">
              {Array.isArray(gameGlobal.ranking) &&
                gameGlobal.ranking.map((item, index) => {
                  return (
                    <IonRow
                      className={
                        myUser?.Id == item.UserId ? "IonRowMi" : "IonRow"
                      }
                      key={index}
                    >
                      <IonCol size-md="1" size="2" className="point">
                        <span className={index <= 2 ? "point_1" : "nada"}>
                          {index + 1}{" "}
                        </span>
                      </IonCol>
                      <IonCol size-md="1" size="0">
                        <img
                          alt="Avatar"
                          className="avatar1"
                          src={`https://www.pekegames.app/img/avatar/${item.Avatar}`}
                        ></img>
                      </IonCol>

                      <IonCol size-md="5" size="4">
                        <h3 className="nick">{item?.Nick}</h3>
                        <div className="acierto_errores_ranking">
                          {t("level")}: {item.Nivel} {t("correct")}:
                          <span className="bien">{item?.Correcta}</span>{" "}
                          {t("incorrect")}:{" "}
                          <span className="mal">{item?.Incorrecta}</span>
                        </div>
                      </IonCol>
                      <IonCol size-md="5" size="5" className="end">
                        <span className="point">{item?.Punto} </span>
                        <span className="finalizado">
                          {" "}
                          {item.Finalizado ? (
                            <span className="bien"> {t("finalized")}</span>
                          ) : (
                            <span className="en_curso">
                              {t("game_in_progress")}
                            </span>
                          )}
                        </span>
                      </IonCol>
                    </IonRow>
                  );
                })}
                <div className="margin_buttom_banner"></div>
            </IonList>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Ranking;
