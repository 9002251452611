import React, { useEffect } from "react";
import { IonContent, IonPage, IonRow, IonIcon, IonButton, useIonViewWillLeave } from "@ionic/react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.scss";
import "@ionic/react/css/ionic-swiper.css";
import { Grid } from "@material-ui/core";
import { Navigation, Pagination } from "swiper";
import { arrowForward, exitOutline, locate } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { AdMob, BannerAdPluginEvents } from '@capacitor-community/admob';
import { appActions } from "../../_redux/actions/app.actions";
import { useDispatch } from "react-redux";

const Turtorial: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(appActions.setAppOcultarBanner());
    AdMob.hideBanner();
  }, [dispatch]);
  
  useIonViewWillLeave(async () => {
    dispatch(appActions.setAppHabilitarBanner());
    AdMob.resumeBanner();
  });

  return (
    <IonPage>
      <IonContent>
        <Swiper
          pagination={{
            type: "progressbar",
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="slide">
              <img src="assets/images/bannerayuda0.png" />
              <h2>{t("screen_help_0_title")}</h2>
              <p>{t("screen_help_0_description")}</p>

              <IonButton color="danger" onClick={props?.evento} fill="clear">
                <IonIcon icon={exitOutline}></IonIcon> {t("skip")}
              </IonButton>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide">
              <img src="assets/images/bannerayuda1.png" />
              <h2>{t("screen_help_1_title")}</h2>
              <p>{t("screen_help_1_description")}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide">
              <img src="assets/images/bannerayuda2.png" />
              <h2>{t("screen_help_2_title")}</h2>
              <p>{t("screen_help_2_description")}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide">
              <img src="assets/images/bannerayuda3.png" />
              <h2>{t("screen_help_3_title")}</h2>
              <p>{t("screen_help_3_description")}</p>
              <p>{t("screen_help_3_description_p2")}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide">
              <img src="assets/images/bannerayuda4.png" />
              <h2>{t("screen_help_4_title")}</h2>
              <p>{t("screen_help_4_description")}</p>
              <ul className="milista">
                <li>{t("time")}</li>
                <li>{t("history")}</li>
                <li>{t("purchase_of_entry")}</li>
                <li>{t("half_price_or_free")}</li>
                <li>{t("user_opinions")}</li>
                <li>{t("how_to_get_to_the_tourist_spot")}</li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide">
              <img src="assets/images/bannerayuda5.png" />
              <h2>{t("screen_help_5_title")}</h2>
              <IonButton onClick={props?.evento} fill="clear">
                {t("continue")} <IonIcon icon={arrowForward}></IonIcon>
              </IonButton>
            </div>
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonPage>
  );
};
export default Turtorial;


