import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { AuthStore } from "../../_redux/models/auth.model";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { authActions, gameActions } from "../../_redux/actions";
import styles from "./apps.module.scss";
import { useEffect, useState } from "react";
import { getVersion, MenuApp, shareHander } from "../../utils/functions";
import { gameService } from "../../_redux/services";
import { Icon } from "@material-ui/core";
import { history } from "../../_helpers/history";
import MenuLateral from "../../components/Menu/MenuLateral";
import { GameState } from "../../_redux/models/game.model";
import { Share } from "@capacitor/share";
import CardApp from "../../components/CardImage/CardApp";
import { appActions } from "../../_redux/actions/app.actions";
import { LogroState } from "../../_redux/models/logro.models";

const Apps: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const logros: LogroState = useSelector((state: any) => state.logro);
  const [listApps, setListApps] = useState(null);
  const [present, dismiss] = useIonActionSheet();
  useEffect(() => {
    async function fetchVersion() {
      try {
        dispatch(gameActions.IsBusy());
        const apps = await gameService.AppsPekeGames();
        setListApps(apps);
        dispatch(gameActions.QuitarIsBusy());
        if (!logros?.logro?.find((x) => x.LogroId === 95)?.TieneLogro)
        {
            dispatch(appActions.showLogro(95));
        }
      } catch (error) {
        console.log(error);
        dispatch(gameActions.QuitarIsBusy());
      }
    }
    fetchVersion();
  }, []);

 
  return (
    <IonPage id="page-dashboard" className="page-dashboard">
      <IonHeader>
        <IonToolbar className="menu_movil_home_2">
          {MenuApp(present, t)}
          <IonTitle>{t("cities")}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="bg_app_content">
        <IonRow>
          <IonCol size="12" size-md="3" className="lateral hide_pc">
            <MenuLateral></MenuLateral>
          </IonCol>
          <IonCol size="12" size-md="9" className="centro_pagina">
            <div>
              <IonRow>
                {Array.isArray(listApps) &&
                  listApps.map((item, index) => {
                    return (
                      <IonCol size="12" size-md="6" key={index}>
                        <CardApp data={item} index={index}></CardApp>
                      </IonCol>
                    );
                  })}
              </IonRow>
              <div className="margin_buttom_banner"></div>
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Apps;
