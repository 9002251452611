import {
  AdMob,
  BannerAdPluginEvents
} from "@capacitor-community/admob";
import { optionsBanner } from "../../utils/functions";
import {
  AppAction,
  CookiePreferences,
  localSettings,
  Toast,
} from "../models/app.model";
import { cookiesServices } from "../services/cookies.services";
import { appTypes } from "../types";
import { Capacitor } from '@capacitor/core';
import { Ads } from "../models/ads.model";

let bannerEventsSubscribed = false;
let bannerEventsSubscribedRefresh = false;


const setAppTheme = (appTheme: string): any => {
  localStorage.setItem("appTheme", appTheme);
  return { type: appTypes.SET_THEME, theme: appTheme };
};

const appRequest = (): AppAction => {
  return {
    type: appTypes.REQUEST,
  };
};

const bannerLocalOcultar = (): AppAction => {
  return {
    type: appTypes.BANNER_LOCAL_OCULTAR,
  };
};

const bannerLocalMostrar = (): AppAction => {
  return {
    type: appTypes.BANNER_LOCAL_MOSTRAR,
  };
};

const bannerLocalModalOcultar = (): AppAction => {
  return {
    type: appTypes.BANNER_OCULTAR_INTERTINAL_PUBLICIDAD,
  };
};

const bannerLocalModalMostrar = (): AppAction => {
  return {
    type: appTypes.BANNER_MOSTRAR_INTERTINAL_PUBLICIDAD,
  };
};

const getAppSettings = () => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(appRequest());

      const theme = localStorage.getItem("appTheme");
      if (theme) dispatch(setAppTheme(theme));
    } catch (e) {
     
    }
  };
};

const setLocalSettings = (localSettings: localSettings): any => {
  return {
    type: appTypes.SET_LOCAL_SETTINGS,
    localSettings: localSettings,
  };
};

const setCookiePreferences = (cookiePreferences: CookiePreferences): any => {
  return {
    type: appTypes.SET_COOKIE_PREFERENCES,
    cookiePreferences: cookiePreferences,
  };
};

const loadCookiePreferences = (cookiesAuth: boolean) => {
  return async (dispatch: any): Promise<any> => {
    try {
      cookiesServices.saveCookiePreference(cookiesAuth);
      dispatch(setCookiePreferences({ ga_: cookiesAuth }));
    } catch (e) {
      //dispatch(fetchAppFailure(e.message));
    }
  };
};

const setAppHabilitarBanner = (): any => {
  try {
    if (!bannerEventsSubscribed) {
      AdMob.addListener(BannerAdPluginEvents.FailedToLoad, (info) => {
        
      });

      AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
        
      });

      bannerEventsSubscribed = true;
    }

    const result = AdMob.showBanner(optionsBanner).then((info) => {
      // ... código adicional si es necesario ...
    }).catch((error) => {
      
    });

 
    if (result === undefined) {
      return;
    }
  } catch (error) {
  
  }

  return { type: appTypes.BANNER_MOSTRAR, isPrepareBanner: true };
};


const refrescarBanner = (): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      if (!Capacitor.isNativePlatform()) {
        dispatch(bannerLocalMostrar());
        return;
      }

      if (!bannerEventsSubscribedRefresh) {
        AdMob.addListener(BannerAdPluginEvents.FailedToLoad, (info) => {
          dispatch(bannerLocalMostrar());
        });

        AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
          dispatch(bannerLocalOcultar());
        });

        bannerEventsSubscribedRefresh = true;
      }

       AdMob.showBanner(optionsBanner).then((info) => {
      }).catch((error) => {
      
      });

    } catch (error) {
  
    }
  };
};

const setAppOcultarBanner = (): any => {
  return {
    type: appTypes.BANNER_OCULTAR,
    isPrepareBanner: false,
  };
};

const setAppHabilitarINTERTINALBanner = (): any => {
  return {
    type: appTypes.BANNER_MOSTRAR_INTERTINAL_PUBLICIDAD,
    isPrepareInterstitialPublicidad: true,
  };
};

const setAppOcultarINTERTINALBanner = (): any => {
  return {
    type: appTypes.BANNER_OCULTAR_INTERTINAL_PUBLICIDAD,
    isPrepareInterstitialPublicidad: false,
  };
};

const setYAMostreEvaluarApp = (): any => {
  return {
    type: appTypes.MOSTRAR_EVALUAR_APP
  };
};

const setBannerLocal = (bannerLocalValor: boolean): any => {
  return { type: appTypes.BANNER_LOCAL, bannerLocal: bannerLocalValor, };
};

const showToast = (myToast: Toast): any => {
  return {
    type: appTypes.TOAST,
    toast: myToast,
  };
};

const removeToast = (): any => {
  return {
    type: appTypes.REMOVE_TOAST,
    toast: undefined,
  };
};

const showLogro = (idLogro: number): any => {
  return {
    type: appTypes.LOGRO_MOSTRAR,
    logro: idLogro,
  };
};

const ocultarLogro = (): any => {
  return {
    type: appTypes.LOGRO_OCULTAR,
    logro: -1,
  };
};

const setBanner1 = (banner: Ads[]): any => {
  return {
    type: appTypes.BANNER1_SAVE,
    banner1: banner,
  };
};

const setBanner2 = (banner: Ads[]): any => {
  return {
    type: appTypes.BANNER2_SAVE,
    banner2: banner,
  };
};

const setBanner3 = (banner: Ads[]): any => {
  return {
    type: appTypes.BANNER3_SAVE,
    banner3: banner,
  };
};

const setBanner4 = (banner: Ads[]): any => {
  return {
    type: appTypes.BANNER4_SAVE,
    banner4: banner,
  };
};
export const appActions = {
  setAppTheme,
  getAppSettings,
  setAppHabilitarBanner,
  setAppOcultarBanner,
  setAppHabilitarINTERTINALBanner,
  setAppOcultarINTERTINALBanner,
  setBannerLocal,
  setYAMostreEvaluarApp,
  showToast,
  removeToast,
  refrescarBanner,
  bannerLocalModalOcultar,
  bannerLocalModalMostrar,
  showLogro,
  ocultarLogro,
  setBanner1,
  setBanner2,
  setBanner3,
  setBanner4
};
