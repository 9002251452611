import { getPlatforms } from "@ionic/react";
import { Base64 } from "js-base64";
import { platform } from "os";
import { useSelector } from "react-redux";
import {
  decodeBase64,
  filtrarMeuDatos,
  GuardarPartida,
  RecuperarPartida,
} from "../../utils/functions";
import { AuthState, LogroAdd, MyUser } from "../models/auth.model";
import {
  RankingOnlineRequest,
  RankingOnlineResponse,
} from "../models/game.model";
import type { Language } from "../models/language.model";
import { httpClientPk, httpClientYelp } from "../utils/httpCommon";
import { configData } from "../../data/configData";
import { CapacitorHttp, HttpResponse } from "@capacitor/core";
import { history } from "../../_helpers/history";
import { PartidaState } from "../models/partida.model";
import { WikiResponse } from "../models/wikipedia.model";
import { TiempoResponse } from "../models/tiempo.model";
import { text } from "ionicons/icons";
import Enumerable from "linq";
import { PlaceResponse } from "../models/place.model";
import { PlaceReviewResponse } from "../models/reviewplace.model";
import { WikiDetalleResponse } from "../models/wikipedia.detail.model";
import { delay } from "rxjs";
import { useTranslation } from "react-i18next";
import { Tienda } from "../models/tienda.model";
const getLanguageForApp = async (): Promise<any> => {
  try {
    const url = "/api/configpekegames/idioma?miappid=2";
    //const url = '/api/configpekegames/estadofoto?estadoId=39';
    const param = { EstadoId: process.env.REACT_APP_PROVINCE };
    const resp = await httpClientPk.post(url, param);
    return resp;
  } catch (error) {
    // Handle Error Here
    throw new TypeError(error.message);
  }
};

const getPhotoProvince = async (): Promise<any> => {
  try {
    const url =
      process.env.REACT_APP_SERVICE +
      "/api/pekegames/estadofoto?estadoId=" +
      process.env.REACT_APP_PROVINCE;

    //const param = {EstadoId : process.env.REACT_APP_PROVINCE}
    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    const resp: HttpResponse = await CapacitorHttp.post(options);
    if (resp.status == 200) {
      return resp.data;
    }
    return [];
  } catch (error) {
    return [];
    // console.error("--- ERROR: ", error);
    throw new TypeError(error.message);
  }
};

const getGPTEstado = async (): Promise<any> => {
  try {
    const url =
      process.env.REACT_APP_SERVICE +
      "/api/pekegames/infoCiudades?estadoId=" +
      process.env.REACT_APP_PROVINCE;

    //const param = {EstadoId : process.env.REACT_APP_PROVINCE}
    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    const resp: HttpResponse = await CapacitorHttp.post(options);
    if (resp.status === 200) {
      return resp.data;
    }
    return [];
  } catch (error) {
    return [];
    // console.error("--- ERROR: ", error);
    throw new TypeError(error.message);
  }
};

const GetRanking = async (): Promise<RankingOnlineResponse> => {
  try {
    const url = process.env.REACT_APP_SERVICE + "/api/ranking/listar";

    const ranking: RankingOnlineRequest = {
      MiAppId: parseInt(process.env.REACT_APP_MIAPPID),
      TipoRanking: 0,
    };

    const options = {
      url: url,
      data: ranking,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const resp: HttpResponse = await CapacitorHttp.post(options);

    return resp.data;
  } catch (error) {
    // Handle Error Here
    throw new TypeError(error.message);
  }
};

const SetRanking = async (
  dataRanking: RankingOnlineRequest,
  contadorError?: number
): Promise<number> => {
  try {
    if (dataRanking === null || dataRanking.Punto === 0) {
      return null;
    }
    const data = getAccount();
    if (data == null || data === undefined || data.Token == null) {
      return;
      //history.replace("login");
    }
    if (contadorError == null) {
      contadorError = 1;
    }

    const token = data.Token;
    const url =
      dataRanking?.Id === -1 || dataRanking?.Id == null
        ? process.env.REACT_APP_SERVICE + "/api/ranking/agregar"
        : process.env.REACT_APP_SERVICE + "/api/ranking/actualizar";

    dataRanking.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
    const options = {
      url: url,
      data: dataRanking,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    console.log("dataRanking", dataRanking);
    const resp: HttpResponse = await CapacitorHttp.post(options);
    console.log("resp", resp);
    if (resp.status === 200) {
      const miJogo: PartidaState = RecuperarPartida();
      console.log("miJogo", miJogo);
      if (miJogo !== null) {
        miJogo.id = resp.data.IdRanking;

        if (dataRanking.Finalizado) {
          localStorage.removeItem("jogo");
        } else {
          GuardarPartida(miJogo);
        }
      }

      return resp.data.IdRanking;
    } else if (resp.status === 401) {
      contadorError++;
      if (contadorError < 3) {
        const resultToken = await MiToken();
        if (resultToken === false) {
          return;
        }
        await delay(500);
        SetRanking(dataRanking, contadorError);
      } else {
        history.push("login");
      }
    }

    return resp.data.IdRanking;
  } catch (error) {
    console.error("--- ERROR: ", error);
    //throw new TypeError(error);
  }
};

//Eliminar
const SetUpdateRanking = async (
  dataRanking: RankingOnlineRequest,
  contadorError?: number
): Promise<RankingOnlineResponse> => {
  try {
    const data = getAccount();
    if (data == null || data.Token == null) {
      alert("no esta logado");
      return;
    }
    if (contadorError == null) {
      contadorError = 1;
    }
    const token = data.Token;
    const url = process.env.REACT_APP_SERVICE + "/api/ranking/actualizar";

    dataRanking.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
    const options = {
      url: url,
      data: dataRanking,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    const resp: HttpResponse = await CapacitorHttp.post(options);

    if (resp.status === 200) {
      if (!dataRanking.Finalizado) {
        //recuperar juego y guardar id
      }
      return resp.data;
    } else if (resp.status === 401) {
      contadorError++;
      if (contadorError < 2) {
        const resultToken = await MiToken();
        if (resultToken == false) {
          return;
        }
        SetUpdateRanking(dataRanking, contadorError);
      }
    }

    return null;
  } catch (error) {
    // Handle Error Here
    throw new TypeError(error.response.data.Message);
  }
};

const PosicionRanking = async (data: RankingOnlineRequest): Promise<number> => {
  try {
    const url = "/api/ranking/posicion";
    data.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
    console.log("data", data);
    const resp: any = await httpClientPk.post(url, data);
    console.log("resp", resp);
    return resp.data.Posicion;
  } catch (error) {
    // Handle Error Here
    console.error("--- ERROR: ", error);
    throw new TypeError(error.message);
  }
};

const MiToken = async (): Promise<boolean> => {
  try {
    const data = getAccount();
    if (
      data === null ||
      data?.Id === null ||
      data.Id === "-1" ||
      data.Password === ""
    ) {
      return false;
    }

    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/token";

    const options = {
      url: url,
      data: {
        MiAppId: parseInt(process.env.REACT_APP_MIAPPID),
        Username: data.Username,
        Password: data.Password,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response: HttpResponse = await CapacitorHttp.post(options);
    if (response.status === 200) {
      data.Token = response.data.Token;
      localStorage.setItem("ctpk", filtrarMeuDatos(JSON.stringify(data)));
      return true;
    } else if (response.status === 400) {
      history.replace("login");
    } else {
    }

    return false;
  } catch (error) {
    console.log(error);
  }
};

const Login = async (login: MyUser): Promise<any> => {
  try {
    login.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);

    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/Authenticate";

    const options = {
      url: url,
      data: login,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const resp: HttpResponse = await CapacitorHttp.post(options);
    //const resp = await httpClientPk.post(url, login);
    if (resp.status === 200) {
      resp.data.Username = login.Username;
      resp.data.Auth = true;
      resp.data.Password = login.Password;
      localStorage.setItem("ctpk", filtrarMeuDatos(JSON.stringify(resp.data)));
      resp.data.Password = null;
      resp.data.Token = null;
      return resp.data;
    }
    if (resp.status === 401) {
      throw new Error("Contraseña o usuarios incorrectos");
    }
    return null;
  } catch (error) {
    throw new Error(error);
  }
};

const CrearCuenta = async (login: MyUser): Promise<MyUser> => {
  try {
    const url = "/api/cuenta/registro";

    login.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
    login.NuevoUsuarioEnElJuego = true;
    //login.DatosExtendido = new DatosExtendido();

    const resp = await httpClientPk.post(url, login);
    resp.data.Username = login.Username;
    resp.data.Password = login.Password;
    resp.data.Auth = true;
    localStorage.setItem("ctpk", filtrarMeuDatos(JSON.stringify(resp.data)));
    return resp.data;
  } catch (error) {
    // Handle Error Here
    throw new TypeError(error.response.data.Message);
  }
};

const getAccount = () => {
  if (localStorage.getItem("ctpk") === null) {
    return null;
  }
  const auxValue = Base64.decode(localStorage.getItem("ctpk"));
  const result: MyUser = JSON.parse(auxValue);
  if (result.Id === "-1") {
    return null;
  }
  return result;
};

const getAccountInvitado = () => {
  if (localStorage.getItem("ctpk") === null) {
    return null;
  }
  const auxValue = Base64.decode(localStorage.getItem("ctpk"));
  const result: MyUser = JSON.parse(auxValue);
  if (result.Id === "-1") {
    return result;
  }
  return null;
};

const checkAuth = async (): Promise<any> => {
  try {
    const myUser = getAccount();
    if (myUser !== null) {
      return myUser;
    } else {
      throw new Error("User not fount");
    }
  } catch (err) {
    throw new Error(err);
  }
};

const GetRestaurante = async (ciudad: string): Promise<any> => {
  try {
    const url =
      "https://www.pekegames.app/api/year/restaurante?ciudad=" +
      ciudad +
      "&amp;provincia=" +
      configData.provincia_o_estado +
      "&amp;idioma=" +
      configData.idioma;

    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else {
      alert(response.status);
    }

    return null;
  } catch (error) {
    // Handle Error Here
    throw new TypeError(error);
  }
};

const GetPuntosTuristicos = async (ciudad: string): Promise<any> => {
  try {
    const url =
      "https://www.pekegames.app/api/year/turismo?ciudad=" +
      ciudad +
      "&amp;provincia=" +
      configData.provincia_o_estado +
      "&amp;idioma=" +
      configData.idioma;

    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else {
      alert(response.status);
    }

    return null;
  } catch (error) {
    // Handle Error Here
    throw new TypeError(error.response.data.Message);
  }
};

const GetHotel = async (ciudad: string): Promise<any> => {
  try {
    const url =
      "https://www.pekegames.app/api/year/hotel?ciudad=" +
      ciudad +
      "amp;provincia=" +
      configData.provincia_o_estado +
      "amp;idioma=" +
      configData.idioma;

    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    // Handle Error Here
    throw new TypeError(error.response.data.Message);
  }
};

const GetWikiBusca = async (ciudad: string): Promise<WikiResponse> => {
  try {
    const url =
      "https://" +
      configData.idioma +
      ".wikipedia.org/w/api.php?action=query&list=search&srprop=snippet&format=json&origin=*&utf8=&srsearch=" +
      configData.ciudad +
      "%20" +
      encodeURI(ciudad + " ," + configData.provincia_o_estado);
    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    }

    return null;
  } catch (error) {
    // Handle Error Here
    throw new TypeError(error.response.data.Message);
  }
};

const GetWikiDetalle = async (pageId: number): Promise<WikiDetalleResponse> => {
  try {
    //TODO, cambiar url de idioma
    let url =
      "https://" +
      configData.idioma +
      ".wikipedia.org/w/api.php?action=query&prop=extracts&exintro&explaintext&pageids=" +
      pageId +
      "&format=json&origin=*&utf8";
    url = encodeURI(url);
    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else {
    }

    return null;
  } catch (error) {
    throw new TypeError(error.response.data.Message);
  }
};

const GetElTiempo = async (ciudad: string): Promise<TiempoResponse> => {
  try {
    const myCodigo = Enumerable.from(configData.eltiempo)
      .where((p) => p["#text"] === ciudad)
      .firstOrDefault();

    if (myCodigo === null || myCodigo === undefined) {
      return null;
    }

    //TODO, cambiar url de idioma
    const url =
      process.env.REACT_APP_SERVICE +
      "/api/tutiempo/buscar?ciudad=" +
      myCodigo["@value"] +
      "&idioma=" +
      localStorage.getItem("i18nextLng");

    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    }

    return null;
  } catch (error) {
    return null;
    // throw new TypeError(error);
  }
};

const Perfil = async (contadorError?: number): Promise<MyUser> => {
  try {
    const data = getAccount();
    if (data == null) {
      return null;
    }
    if (contadorError == null) {
      contadorError = 1;
    }
    const token = data.Token;
    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/perfil";

    const options = {
      url: url,
      data: { MiAppId: parseInt(process.env.REACT_APP_MIAPPID) },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response: HttpResponse = await CapacitorHttp.post(options);
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      contadorError++;
      if (contadorError < 3) {
        const resultToken = await MiToken();
        if (resultToken === false) {
          return null;
        }
        Perfil(contadorError);
      }
    }

    return null;
  } catch (error) {
    // Handle Error Here
    if (contadorError < 2) {
      const resultToken = await MiToken();
      if (resultToken === false) {
        return;
      }
      //Perfil(contadorError);
    }
    console.table(error);
    //throw new TypeError(error);
  }
};

const UsuarioSalioDeLaPantalla = async (
  dataUser: MyUser,
  contadorError?: number
): Promise<MyUser> => {
  try {
    const data = getAccount();
    if (data == null) {
      return null;
    }
    if (contadorError == null) {
      contadorError = 1;
    }
    const token = data.Token;
    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/saliodatela";

    dataUser.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
    const options = {
      url: url,
      data: dataUser,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      contadorError++;
      if (contadorError < 3) {
        console.log("error contador" + contadorError);
        const resultToken = await MiToken();
        if (resultToken === false) {
          return;
        }
        UsuarioSalioDeLaPantalla(dataUser, contadorError);
      }
    }

    return null;
  } catch (error) {
    // Handle Error Here
    if (contadorError < 2) {
      const resultToken = await MiToken();
      if (resultToken === false) {
        return;
      }
    }
    throw new TypeError(error);
  }
};

const ComprarAyuda = async (
  dataUser: MyUser,
  contadorError?: number
): Promise<MyUser> => {
  try {
    const data = getAccount();
    if (data == null) {
      //no tiene login
      //history.push("/login");
      console.log("No hay token");
      return null;
    }
    if (contadorError == null) {
      contadorError = 1;
    }
    const token = data.Token;
    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/tienda";

    dataUser.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
    const options = {
      url: url,
      data: dataUser,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response: HttpResponse = await CapacitorHttp.post(options);
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      console.log("error 401 token");
      contadorError++;
      if (contadorError < 3) {
        console.log("error contador" + contadorError);
        const resultToken = await MiToken();
        if (resultToken === false) {
          return;
        }
        ComprarAyuda(dataUser, contadorError);
      }
    }

    return null;
  } catch (error) {
    // Handle Error Here
    console.log("contador", contadorError);
    if (contadorError < 2) {
      const resultToken = await MiToken();
      if (resultToken === false) {
        return;
      }
      //ComprarAyuda(dataUser, contadorError);
    }
    console.table(error);
    //throw new TypeError(error);
  }
};

const QuitarAyuda = async (
  productoId: number,
  contadorError?: number
): Promise<MyUser> => {
  try {
    const data = getAccount();
    if (data == null) {
      // history.push("/login");
      return null;
    }
    if (contadorError == null) {
      contadorError = 1;
    }
    const token = data.Token;
    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/quitarayuda";

    const dataPost = {
      AppId: parseInt(process.env.REACT_APP_MIAPPID),
      id: productoId,
    };

    const options = {
      url: url,
      data: dataPost,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      console.log("error 401 token");
      contadorError++;
      if (contadorError < 3) {
        console.log("error contador" + contadorError);
        const resultToken = await MiToken();
        if (resultToken === false) {
          return null;
        }
        QuitarAyuda(productoId, contadorError);
      }
    }

    return null;
  } catch (error) {
    // Handle Error Here
    console.log("contador", contadorError);
    if (contadorError < 2) {
      const resultToken = await MiToken();
      if (resultToken === false) {
        return;
      }
      //QuitarAyuda(productoId, contadorError);
    }
    console.table(error);
    //throw new TypeError(error);
  }
};

const AppsPekeGames = async (): Promise<any> => {
  try {
    const data = getAccount();
    if (data == null) {
      //no tiene login
    }
    const url =
      process.env.REACT_APP_SERVICE + "/api/pekegames/apps?idioma=pt-BR";

    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else {
    }

    return null;
  } catch (error) {
    // Handle Error Here
    console.error("--- ERROR: ", error);
    throw new TypeError(error);
  }
};

const CambiarNick = async (dataUser: MyUser): Promise<any> => {
  try {
    const data = getAccount();
    if (data == null) {
      //no tiene login
    }
    const token = data.Token;
    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/cambiarnick";

    dataUser.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
    const options = {
      url: url,
      data: dataUser,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else {
    }

    return null;
  } catch (error) {
    // Handle Error Here
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.response.data.Message);
  }
};

const Cambiarsenha = async (dataUser: MyUser): Promise<any> => {
  try {
    const data = getAccount();
    if (data == null) {
      //no tiene login
    }
    const token = data.Token;
    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/cambiarsenha";

    dataUser.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
    const options = {
      url: url,
      data: dataUser,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else {
    }

    return null;
  } catch (error) {
    // Handle Error Here
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.response.data.Message);
  }
};

const ResetSenha = async (dataUser: MyUser): Promise<any> => {
  try {
    const data = getAccount();
    if (data == null) {
      //no tiene login
    }
    const token = data.Token;
    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/resetsenha";

    dataUser.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
    const options = {
      url: url,
      data: dataUser,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else {
    }

    return null;
  } catch (error) {
    // Handle Error Here
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.response.data.Message);
  }
};

const CambiarPais = async (dataUser: MyUser): Promise<any> => {
  try {
    const data = getAccount();
    if (data == null) {
      //no tiene login
    }
    const token = data.Token;
    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/cambiarpais";

    dataUser.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
    const options = {
      url: url,
      data: dataUser,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else {
    }

    return null;
  } catch (error) {
    // Handle Error Here
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.response.data.Message);
  }
};

const CambiarAvatar = async (dataUser: MyUser): Promise<any> => {
  try {
    const data = getAccount();
    if (data == null) {
      //no tiene login
    }
    const token = data.Token;
    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/cambiaravatar";

    dataUser.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
    const options = {
      url: url,
      data: dataUser,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else {
    }

    return null;
  } catch (error) {
    // Handle Error Here
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.response.data.Message);
  }
};

const EliminarCuentaDeFormaDefinitiva = async (
  dataUser: MyUser
): Promise<any> => {
  try {
    const data = getAccount();
    if (data == null) {
      //no tiene login
    }
    const token = data.Token;
    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/eliminarCuenta";

    dataUser.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
    const options = {
      url: url,
      data: dataUser,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status == 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    // Handle Error Here
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.response.data.Message);
  }
};

const PerfilPrivado = async (dataUser: MyUser): Promise<any> => {
  try {
    const data = getAccount();
    if (data == null) {
      //no tiene login
    }
    const token = data.Token;
    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/perfilPrivado";

    dataUser.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
    const options = {
      url: url,
      data: dataUser,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    }

    return null;
  } catch (error) {
    // Handle Error Here
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.response.data.Message);
  }
};

const GetLugares = async (
  ciudad: string,
  buscar: string
): Promise<PlaceResponse> => {
  try {
    buscar = buscar + " " + ciudad + "," + configData.provincia_o_estado;
    const url =
      process.env.REACT_APP_SERVICE +
      "/api/lugares/buscar?ciudad=" +
      encodeURI(buscar);

    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    }

    throw new TypeError(response.status.toString());
  } catch (error) {
    throw new TypeError(error.response.data.Message);
  }
};

const GetDetalleCiudad = async (idCiudad: number): Promise<WikiResponse> => {
  try {
    const url =
      process.env.REACT_APP_SERVICE +
      "/api/pekegames/detalleciudad?id=" +
      idCiudad;

    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    // Handle Error Here
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.response.data.Message);
  }
};

const GetLugaresImage = async (codigo: string): Promise<TiempoResponse> => {
  try {
    const url =
      process.env.REACT_APP_SERVICE + "/api/lugares/img?codigo=" + codigo;

    const options = {
      url: url,
      headers: {
        "Content-Type": "image/jpeg",
      },
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    }

    throw new TypeError(response.status.toString());
  } catch (error) {
    throw new TypeError(error.response.data.Message);
  }
};

const GetLugaresReview = async (
  placeid: string
): Promise<PlaceReviewResponse> => {
  try {
    const url =
      process.env.REACT_APP_SERVICE +
      "/api/lugares/review?placeid=" +
      placeid +
      "&idioma=" +
      localStorage.getItem("i18nextLng");

    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    }

    throw new TypeError(response.status.toString());
  } catch (error) {
    // Handle Error Here
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.response.data.Message);
  }
};

const Logro = async (
  logro: LogroAdd,
  contadorError?: number
): Promise<MyUser> => {
  try {
    const url = process.env.REACT_APP_SERVICE + "/api/cuenta/logro";

    const data = getAccount();
    if (data == null) {
      history.replace("login");
      return;
    }
    if (contadorError == null) {
      contadorError = 1;
    }

    const token = data?.Token;
    logro.AppId = parseInt(process.env.REACT_APP_MIAPPID);

    const options = {
      url: url,
      data: logro,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    const resp: HttpResponse = await CapacitorHttp.post(options);
    if (resp.status === 401) {
      contadorError++;
      const resultToken = await MiToken();
      if (resultToken === false) {
        return null;
      }
      await delay(500);
      Logro(logro, contadorError);
    } else {
      return resp.data;
    }
  } catch (error) {
    throw new TypeError(error);
  }
};

export const gameService = {
  getLanguageForApp,
  getPhotoProvince,
  Login,
  getAccount,
  GetRanking,
  SetRanking,
  SetUpdateRanking,
  CrearCuenta,
  checkAuth,
  PosicionRanking,
  GetRestaurante,
  GetPuntosTuristicos,
  GetHotel,
  Perfil,
  AppsPekeGames,
  CambiarNick,
  Cambiarsenha,
  ResetSenha,
  CambiarPais,
  CambiarAvatar,
  EliminarCuentaDeFormaDefinitiva,
  PerfilPrivado,
  MiToken,
  GetWikiBusca,
  GetElTiempo,
  GetLugares,
  GetLugaresImage,
  GetLugaresReview,
  GetDetalleCiudad,
  GetWikiDetalle,
  ComprarAyuda,
  getAccountInvitado,
  QuitarAyuda,
  Logro,
  getGPTEstado,
  UsuarioSalioDeLaPantalla,
};
