import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import {  AdMob, BannerAdOptions, BannerAdSize, BannerAdPosition, BannerAdPluginEvents, AdMobBannerSize } from '@capacitor-community/admob';
import { useEffect } from 'react';
import {languageActions } from '../../_redux/actions';
import { useDispatch } from 'react-redux';
import { Line, Circle } from 'rc-progress';
import linq from 'linq';
import {stockData} from '../../data/stockData'



const Estilo: React.FC = ()  => {
  const dispatch = useDispatch();
  useEffect(() => { 
      dispatch(languageActions.getLanguage());
  },[]);

  





  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Mis estilos, TEST</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Estilo</IonTitle>
          </IonToolbar>
        </IonHeader>
        
   
  <button className="custom-btn btn-1">Read More</button>
  <button className="custom-btn btn-2">Read More</button>
  <button className="custom-btn btn-3"><span>Read More</span></button>
  <button className="custom-btn btn-4"><span>Read More</span></button>
  <button className="custom-btn btn-5"><span>Botón rojo</span></button>
  <button className="custom-btn btn-6"><span>Read More</span></button>
  <button className="custom-btn btn-7"><span>Read More</span></button>
  <button className="custom-btn btn-8"><span>Read More</span></button>
  <button className="custom-btn btn-9">Read More</button>
  <button className="custom-btn btn-10">Read More</button>
  <button className="custom-btn btn-11">Read More<div className="dot"></div></button>
  <button className="custom-btn btn-12"><span>Click!</span><span>Read More</span></button>
  <button className="custom-btn btn-13">Read More</button>
  <button className="custom-btn btn-14">Read More</button>
  <button className="custom-btn btn-15">Read More</button>
  <button className="custom-btn btn-16">Read More</button>
    
  <IonButton
            shape="round"
            className="styles.btn_sty"
            color="primary"
          >
          Botón primary IONIC
          </IonButton>
          <IonButton
            shape="round"
            className="styles.btn_sty"
            color="medium"
          >
            Botón medium IONIC
            </IonButton>


      </IonContent>
    </IonPage>
  );
};

export default Estilo;
