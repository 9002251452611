import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import YouTube, { YouTubeProps } from "react-youtube";
import { timeInterval } from "rxjs";
import { TimeInterval } from "rxjs/internal/operators/timeInterval";
import { appActions } from "../../_redux/actions/app.actions";
import { AppState, AppStore } from "../../_redux/models/app.model";

const BannerModalLocal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentAd, setCurrentAd] = useState(0);
  const [showModal, setShowModal] = useState(true);
  const [countdown, setCountdown] = useState(5);
  const { bannerModalLocal } = useSelector((state: AppStore) => state.app);
  const appConfig: AppState = useSelector((store: AppStore) => store.app);
  const opts: YouTubeProps["opts"] = {
    height: "390",
    width: "320",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    //event.target.pauseVideo();
    event.target.playVideo();

    // event.target.playVideo();
  };

  const inicioBanner = [
    {
      title: "",
      text: "",
      image: "https://www.pekegames.app/Img/App/Banner/3/320_480_cafe.gif",
      url: "https://www.buymeacoffee.com/pekegames",
    },
    {
      title: "Inviting me a cafe",
      text: "Help me boost my project! Buying me a coffee on the ground will keep me awake, but it will also give an invaluable boost to my ideas. Each tip represents your support and directly contributes to making this project a reality. Together we can achieve great things!",
      image: "",
      url: "https://www.buymeacoffee.com/pekegames",
    },
    {
      title: "",
      text: "",
      image: "https://www.pekegames.app/Img/App/Banner/320_480_brasi.png",
      url: "https://brasi.eu",
    },
    {
      title: "Lencería Brasileña",
      text: "Moderna y sexy al mejor precio",
      image: "",
      url: "https://brasi.eu",
    }
  ];
  const [ads, setAds] = useState(inicioBanner);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Llamada al servicio para obtener los datos de ads
        if (appConfig.banner4.length > 0) {
          console.log("banner4 entro",appConfig.banner4);
          setAds(appConfig.banner4);
          
          return;
        }
        const response = await fetch(
          process.env.REACT_APP_SERVICE +
            "/api/pekegames/banner?formatoId=6&appId=" +
            process.env.REACT_APP_MIAPPID +
            "&language=" +
            localStorage.getItem("i18nextLng")
        );

        const data = await response.json();
        if (data.length > 0) {
          setAds(data);
          dispatch(appActions.setBanner4(data));
        }
      } catch (error) {
        console.error("Error al obtener los datos de ads:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      //setShowModal(false);
    } else {
      const timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [countdown, bannerModalLocal]);

  useEffect(() => {
    if (bannerModalLocal) {
      setCountdown(5);
    }
  }, [bannerModalLocal]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAd((prevAd) => (prevAd + 1) % ads.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [ads]);

  const abrirBannerWeb = async (item: any) => {
    if (item.url.length > 1) {
      window.open(item.url, "_blank");
    }
  };

  return (
    <IonModal
      isOpen={bannerModalLocal}
      onDidDismiss={() => dispatch(appActions.bannerLocalModalOcultar())}
      cssClass="fullscreen"
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton
              onClick={() => dispatch(appActions.bannerLocalModalOcultar())}
            >
              {countdown === 0 ? (
                <IonButton
                  onClick={() => dispatch(appActions.bannerLocalModalOcultar())}
                >
                  {t("close")}
                </IonButton>
              ) : (
                <IonButton disabled>{countdown}</IonButton>
              )}
            </IonButton>
          </IonButtons>
          <IonTitle>Ads</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          className="bannerlocalIntersticial"
          onClick={() => abrirBannerWeb(ads[currentAd])}
        >
          <div className="centrarBanner">
            {ads[currentAd].image.length > 1 ? (
              <img src={ads[currentAd].image} alt={ads[currentAd].title} />
            ) : (
              <>
                {ads[currentAd].title.length > 1 && (
                  <h1>{ads[currentAd].title}</h1>
                )}

                {ads[currentAd].text}
              </>
            )}
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default BannerModalLocal;
